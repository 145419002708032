import Grid, {ListaMultiplaFilter} from "../../../../components/grid";
import {numberDecimalFormat} from "../../../../components/numberBRLFormatForGrid";
import {AgGridColumn} from "ag-grid-react";
import React from "react";
import {
    SituacaoFaturaDocumento,
    situacaoFaturaListFormater,
    TipoFaturaDocumento,
    SituacaoFaturaDocumentoList,
    SituacaoFaturaList,
    TipoFaturaDocumentoList
} from "../../index";
import {useLocation} from "react-router-dom";
import enumDescription from "../../../../services/enumDescription";


const FaturaDocumentoList = () => {

    let location = useLocation();
    let idLocation = location.pathname.split('/');
    let idFatura = idLocation[idLocation.length - 1];

    return (
        <Grid rota={"faturaDocumento/list/"+ idFatura}>
            <AgGridColumn width={100} headerName="#" checkboxSelection field="id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn flex={1} headerName="Documento" field="doc/nome" filter={true}  sortable={ true } ></AgGridColumn>
            <AgGridColumn flex={1} headerName="Situção da Fatura" field="fatura/situacao"  sortable={ true } filter={ FiltroSituacao }  valueFormatter={situacaoFaturaListFormater} ></AgGridColumn>
            <AgGridColumn flex={1} headerName="Tipo" field="tipo"  sortable={ true }  filter={ FiltroTipoDocumento }  valueFormatter={TipoFaturaDocumento}></AgGridColumn>
            <AgGridColumn flex={1} headerName="Situação do Documento" field="situacao"  sortable={ true } filter={ FiltroSituacaoDocumento}  valueFormatter={SituacaoFaturaDocumento}></AgGridColumn>
            <AgGridColumn flex={1} headerName="Valor" field="valor" filter={ 'agNumberColumnFilter'} valueFormatter={numberDecimalFormat} sortable={ true } ></AgGridColumn>
        </Grid>
    );
}

class FiltroSituacao extends ListaMultiplaFilter{
    getList(): enumDescription {
        return SituacaoFaturaList;
    }
}
class FiltroSituacaoDocumento extends ListaMultiplaFilter{
    getList(): enumDescription {
        return SituacaoFaturaDocumentoList;
    }
}
class FiltroTipoDocumento extends ListaMultiplaFilter{
    getList(): enumDescription {
        return TipoFaturaDocumentoList;
    }
}

export default FaturaDocumentoList;