import {ContentRoute} from "../../../models/Route";
import ResultadoList from "./resultadoList";
import ResumeDashboard from "./resumoDashboard";
import FechamentoForm from "./fechamento/fechamentoForm";
import FaturaList from "./fechamento/faturaList";
import FaturaForm from "./fechamento/FaturaForm";
import RemeterFaturaForm from "./fechamento/remeterFaturaForm";
import FaturaDocumentoList from "./fechamento/faturaDocumentoList";
import LiquidaBoletosForm from "./fechamento/liquidaBoletosForm";
import {ChartOptions} from 'chart.js';
import {numberDecimalFormatForm} from "../../../components/numberBRLFormatForForm";


export  interface FilterDate {
    value: number;
    description: string;
}

export enum SituacaoTypes {
    SITUACAO_EM_ABERTO = 1,
    SITUACAO_FECHADO = 2,
    SITUACAO_FATURADO = 3,
    SITUACAO_PAGO = 4,
    SITUACAO_ENVIADO = 5
}

export enum ValueFilter {
    MAIOR = 1,
    MENOR = 2,
    ENTRE = 3,
}

// Definição dos filtros
export const filtersDate: FilterDate[] = [
    { value: ValueFilter.MAIOR, description: 'Maior Igual' },
    { value: ValueFilter.MENOR, description: 'Menor Igual' },
    { value: ValueFilter.ENTRE, description: 'Entre' },
];

export const financeiro_content_routes: Array<ContentRoute> = [
    {
        'path': '/financeiro/fatura',
        'description': 'Faturas de Clientes',
        'exact': true,
        'component': FaturaList,
        'menu': true
    },
    {
        'path': '/financeiro/resultado',
        'description': 'Itens de Fatura',
        'exact': true,
        'component': ResultadoList,
        'menu': true
    },
    {
        'path': '/financeiro/resumo',
        'description': 'Dashboard',
        'exact': true,
        'component': ResumeDashboard,
        'menu': true
    },
    {
        'path': '/financeiro/fechamento',
        'description': 'Resumo',
        'exact': true,
        'component': FechamentoForm,
        'menu': false
    },
    {
        'path': '/financeiro/fatura/visualizar/:id',
        'description': 'Detalhes',
        'exact': true,
        'component': FaturaForm,
        'menu': false
    },
    {
        'path': '/financeiro/fatura/alterar/:id',
        'description': 'Alterar',
        'exact': true,
        'component': FaturaForm,
        'menu': false
    },
    {
        'path': '/financeiro/fatura/enviar',
        'description': 'Enviar',
        'exact': true,
        'component': RemeterFaturaForm,
        'menu': false
    },
    {
        'path': '/faturaDocumento/list/:id',
        'description': 'Detalhes',
        'exact': true,
        'component': FaturaDocumentoList,
        'menu': false
    },
    {
        'path': '/faturaDocumento/alterar/:id',
        'description': 'Liquidar Boletos',
        'exact': true,
        'component': LiquidaBoletosForm,
        'menu': false
    },

]

export const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,

        },
        title: {
            display: false,
        },
        tooltip: {
            mode: 'index' as 'index',
            intersect: false,
            callbacks: {
                label: function (context: any) {
                    let label = context.dataset.label || '';
                    if (label) {
                        label += ':     ';
                    }
                    if (context.parsed.y !== null) {
                        label += new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(context.parsed.y);
                    }
                    return label;
                }
            }
        },
    },
};

export const ResponseDataResume = (data: any) => {
    const naoPago = data['nao-pago'] || [];
    const pago = data['pago'] || [];
    const mergeData = (naoPagos: any[], pagos: any[]) => {
        const mergedData: { [key: string]: any } = {};
        naoPagos.forEach((item: any) => {
            const { competencia_label, valortotal } = item;
            if (!mergedData[competencia_label]) {
                mergedData[competencia_label] = { competencia_label, naoPago: 0, pago: 0 };
            }
            mergedData[competencia_label].naoPago += parseFloat(valortotal);
        });
        pagos.forEach((item: any) => {
            const { competencia_label, valortotal } = item;
            if (!mergedData[competencia_label]) {
                mergedData[competencia_label] = { competencia_label, naoPago: 0, pago: 0 };
            }
            mergedData[competencia_label].pago += parseFloat(valortotal);
        });

        return Object.values(mergedData);
    };

    let mergedData = mergeData(naoPago, pago);
    mergedData = mergedData.sort((a: any, b: any) => {
        const [aYear, aMonth] = a.competencia_label.split('/').map(Number);
        const [bYear, bMonth] = b.competencia_label.split('/').map(Number);
        if (aYear !== bYear) {
            return aYear - bYear;
        }
        return aMonth - bMonth;
    });

    const labels = mergedData.map((item: any) => item.competencia_label);
    const naoPagoData = mergedData.map((item: any) => item.naoPago);
    const pagoData = mergedData.map((item: any) => item.pago);

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Pendentes',
                data: naoPagoData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Pago',
                data: pagoData,
                borderColor: 'rgba(8,255,0,0.38)',
                backgroundColor: 'rgba(0,255,35,0.5)',
            },
        ],
    };

    return chartData;
};


export const dataPie = (data: any) => {
    const clientes: any[] = data.clientes.map((cliente: any) => {return cliente.cliente})
    const valorTotal: any[] = data.clientes.map((valor: any) => {return valor.valorTotal})
    const percentual: any[] = data.clientes.map((valor: any) => {return valor.percentual})
    return {
        labels: clientes,
        datasets: [
            {
                label: '# of Votes',
                data: valorTotal,
                backgroundColor: [
                    'rgba(0,3,255,0.84)',
                    'rgba(0, 128, 255, 0.85)',
                    'rgba(0, 255, 255, 0.75)',
                    'rgba(0, 255, 128, 0.65)',
                    'rgba(255,255,0,0.58)',
                    'rgba(255,204,0,0.59)',
                    'rgba(255,153,0,0.6)',
                    'rgba(255,102,0,0.63)',
                    'rgba(255,51,0,0.41)',
                    'rgba(255,0,0,0.6)'
                ],
                borderColor: [
                    'rgba(0, 0, 255, 1)',
                    'rgba(0, 128, 255, 1)',
                    'rgba(0, 255, 255, 1)',
                    'rgba(0, 255, 128, 1)',
                    'rgba(255, 255, 0, 1)',
                    'rgba(255, 204, 0, 1)',
                    'rgba(255, 153, 0, 1)',
                    'rgba(255, 102, 0, 1)',
                    'rgba(255, 51, 0, 1)',
                    'rgba(255, 0, 0, 1)'
                ],
                borderWidth: 1,
                percentual: percentual
            },
        ],
    }
};

export const optionsClienteFaturas: ChartOptions<'pie'> = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            fullSize: false,
        },
        tooltip: {
            position: 'nearest',
            enabled: false,
            callbacks: {
                label: function(tooltipItem) {
                    const label = tooltipItem.label || ''; // Obtém o nome do cliente
                    const value: any = tooltipItem.raw; // Obtém o valor numérico
                    return `${label}: ${numberDecimalFormatForm(value)}`;
                }
            },
            external: function(context) {
                let tooltipEl = document.getElementById('chartjs-tooltip');
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
                    tooltipEl.style.color = 'white';
                    tooltipEl.style.borderRadius = '3px';
                    tooltipEl.style.pointerEvents = 'none';
                    tooltipEl.style.zIndex = '9999';
                    document.body.appendChild(tooltipEl);
                }
                const tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = '0';
                    return;
                }

                if (tooltipModel.body) {
                    const titleLines = tooltipModel.title || [];
                    const bodyLines = tooltipModel.body.map(b => b.lines);

                    let innerHtml = '<thead>';

                    titleLines.forEach(title => {
                        innerHtml += '<tr><th>' + title + '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';

                    bodyLines.forEach((body, i) => {
                        const colors = tooltipModel.labelColors[i];
                        const style = 'background:' + colors.backgroundColor;
                        innerHtml += '<tr><td style="' + style + '">' + body + '</td></tr>';
                    });

                    innerHtml += '</tbody>';
                    tooltipEl.innerHTML = '<table>' + innerHtml + '</table>';
                }
                const position = context.chart.canvas.getBoundingClientRect();
                tooltipEl.style.opacity = '1';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                // @ts-ignore
                tooltipEl.style.font = tooltipModel.options.bodyFont.string
                ;
            },
        },
    },
};

interface DataItem {
    cli_id: number;
    competencia: string;
    pes_nome: string;
    somatotal: string | null;
}

interface Dataset {
    label: string;
    data: (number | null)[];
    backgroundColor: string;
    borderColor: string;
    fill: boolean;
}

const generateColor = (index: number) => {
    const colors = [
        '#FF6384'
       ,'#36A2EB'
       ,'#FFCE56'
       ,'#4BC0C0'
       ,'#9966FF'
       ,'#FF9F40'
       ,'#E7E9ED'
       ,'#56FF56'
       ,'#5656FF'
       ,'#FF56FF'
       ,'#083aef'
       ,'#5856d6'
       ,'#1b9e3e'
    ];
    return colors[index % colors.length];
};

export const ResponseDataFaturamento = (data: DataItem[]) => {
    let year = new Date().getFullYear().toString()
    let month = (new Date().getMonth()+1).toString()
    const labels: string[] = data.length > 0 ? [] : [year+'/'+month] ;
    const datasets: Dataset[] = data.length > 0 ? [] : [{label: "Nenhum Resultado Encontrado", data: [], backgroundColor: '', borderColor:'', fill: false}];

    const groupedData: { [key: number]: { label: string, data: { [key: string]: number | null } } } = {};

    data.forEach(item => {
        if (!groupedData[item.cli_id]) {
            groupedData[item.cli_id] = { label: item.pes_nome, data: {} };
        }
        groupedData[item.cli_id].data[item.competencia] = item.somatotal ? parseFloat(item.somatotal) : null;

        if (!labels.includes(item.competencia)) {
            labels.push(item.competencia);
        }
    });

    labels.sort();

    Object.values(groupedData).forEach((group, index) => {
        const dataPoints = labels.map(label => group.data[label] || null);
        const color = generateColor(index);
        datasets.push({
            label: group.label,
            data: dataPoints,
            backgroundColor: color,
            borderColor: color,
            fill: false
        });
    });

    return {
        labels: labels,
        datasets: datasets
    };
};
