import { BaseConst } from '../models/constants';
import cookie from 'js-cookie';

export function retrieveToken() : string {
    const retriviedToken = cookie.get(BaseConst.TOKEN_ID);

    if(!retriviedToken){
        return '';
    }
    return retriviedToken;
}

export function saveToken(token:string) : void {
    cookie.set(BaseConst.TOKEN_ID, token);
}

export function clearToken(){
    cookie.remove(BaseConst.TOKEN_ID);
}