import React, {useEffect, useState} from 'react';
import {CButton, CCard, CCardBody, CForm, CFormGroup, CInput, CInputCheckbox, CLabel, CSelect} from "@coreui/react";
import Form, {FormApi} from "../../../../components/form";
import useCurrentAction from "../../../../services/useCurrentAction";
import {useHistory, useParams} from "react-router-dom";
import useCustomForm from "../../../../services/useCustomForm";
import {getApi} from "../../../../apis/backendApis";
import Select from "react-select";
import CIcon from "@coreui/icons-react";
import {TypeOperationList} from "../../../tester/operation";
import useAlert from "../../../../services/useAlert";


const FechamentoForm = () => {

    let history = useHistory();
    const [listClientes, setListCLientes] = useState([]);
    const [showAlert] = useAlert();
    const getYears = () => {
        const years = [];
        for (let i = 0; i < 7; i++) {
            years.push(new Date().getFullYear() - i);
        }
        let anos = [];
        for (let valor of years) {
            anos.push({
                value: valor,
                label: valor
            })
        }
        return anos;
    }

    const getMonths = () => {
        const months = [];
        for (let i = 0; i < 12; i++) {
            const date = new Date(0, i);
            const monthName = date.toLocaleString('default', {month: 'long'});
            months.push({
                value: i + 1,
                label: monthName.charAt(0).toUpperCase() + monthName.slice(1)
            });
        }
        return months;
    };

    const yearOptions = getYears();
    const monthOptions = getMonths();
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const [selectedYear, setSelectedYear] = useState({value: currentYear, label: currentYear});
    const [selectedMonth, setSelectedMonth] = useState(monthOptions.find(month => month.value === currentMonth));
    const [selectedClient, setSelectedClient] = useState([]);

    const initialValues = {
        cliente: 0,
        mes: currentMonth,
        ano: selectedYear.value,
        fecharFatura: false
    };

    const {
        setValues,
        values,
        handleSubmit,
        handleSubmitValues,
        handleChange,
    } = useCustomForm(
        {
            initialValues,
            route: 'fechamentoFatura',
            onSubmit: values => {
                let newValues = {...values.values};
                handleSubmitValues(newValues, values.filesUpload)
            },
        }
    );

    useEffect(() => {
        let api = getApi();
        api.get('cliente/suggest').then((response) => {
            setListCLientes(getList(response.data['clientes']));
        })
    }, []);

    const getList = (list: any) => {
        let data: any = [];
        Object.keys(list).forEach((key) => {
            const cliente = list[key];
            data.push({
                label: Object.values(cliente)[0],
                value: Object.keys(cliente)[0]
            });
        });
        return data;
    }

    const handleYearChange = (selectedOption: any) => {
        setSelectedYear(selectedOption);
        values.ano = selectedOption.value
        setValues({...values})
    };

    const handleMonthChange = (selectedOption: any) => {
        setSelectedMonth(selectedOption);
        values.mes = selectedOption.value
        setValues({...values})
    };
    const handleClient = (selectedOption: any) => {
        setSelectedClient(selectedOption);
        values.cliente = parseInt(selectedOption.value)
        setValues({...values})
    };

    const carregarObjetosDeContrato = () => {
        if (values.cliente) {
            let valuesString = JSON.stringify(values);
            let formData = new FormData();
            formData.append('data', valuesString);
            let api = getApi();
            api.request({
                url: '/financeiro/registrarFaturamento',
                method: "post",
                data: formData
            }).then((response) => {
                showAlert({
                    msg: 'Valor de faturamente registrado',
                    toaster: true
                });
                history.goBack();
            }).catch((error) => {
                if (error.response) {
                    const responseData = error.response.data;
                    if (responseData.error) {
                        const errorMessage = responseData.error;
                        showAlert({
                            title: 'Algo deu errado!',
                            type: 'danger',
                            msg: errorMessage,
                        });
                    } else {
                        showAlert({
                            title: 'Algo deu errado!',
                            type: 'danger',
                            msg: error.toString(),
                        });
                    }
                }else{
                    showAlert({
                        title: 'Algo deu errado!',
                        type: 'danger',
                        msg: error.toString(),
                   });
                }
            });
        } else {
            alert("Selecione o cliente");
        }
    }

    const checkedItem = () => {
        setValues({...values, fecharFatura: !values.fecharFatura})
    }

    return (
        <CForm>
            <CFormGroup>
                <CLabel> Selecione o Cliente
                </CLabel>
                <Select
                    options={listClientes}
                    onChange={handleClient}
                    value={selectedClient}
                    loadingMessage={() => "Carregando os clientes"}
                    noOptionsMessage={() => "Sem clientes disponíveis"}
                    required
                />
            </CFormGroup>
            <CFormGroup>
                <div className={'row'}>
                    <div className={'col-6'}>
                        <CLabel> Mês </CLabel>
                        <Select
                            options={monthOptions}
                            value={selectedMonth}
                            placeholder="Selecione o Mês"
                            onChange={handleMonthChange}
                            loadingMessage={() => "Carregando os meses"}
                            noOptionsMessage={() => "Não foi possível carregar os meses "}
                            required
                        />
                    </div>
                    <div className={'col-6'}>
                        <CLabel> Ano </CLabel>
                        <Select
                            options={yearOptions}
                            value={selectedYear}
                            placeholder="Selecione o Ano"
                            onChange={handleYearChange}
                            loadingMessage={() => "Carregando os anos"}
                            noOptionsMessage={() => "Não foi possível carregar os anos"}
                            required
                        />
                    </div>
                </div>
            </CFormGroup>
            <CFormGroup className={'form-check'}>
                <CInputCheckbox checked={values.fecharFatura} onChange={checkedItem} value={values.fecharFatura}/>
                <CLabel htmlFor="ativo">Fechar Fatura?</CLabel>
            </CFormGroup>
            <CFormGroup className={''}>
                <CButton className={'btn-info '} onClick={carregarObjetosDeContrato}> <CIcon
                    name={'cil-reload'}/> &nbsp; Gerar Cálculo </CButton>
            </CFormGroup>
        </CForm>
    );
}

export default FechamentoForm;