import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';
import enumDescription from "../../../services/enumDescription";
import {StatusExecuteTaskList, TypeExecuteTaskList} from "./index";

const ExecuteTaskList = (props:any) => {

    class FiltroType extends ListaMultiplaFilter{
        getList(): enumDescription {
            return TypeExecuteTaskList;
        }
    }

    const typeListFormater  = (params:any) =>{
        return TypeExecuteTaskList.getDescription(params.value);
    }

    class FiltroStatus extends ListaMultiplaFilter{
        getList(): enumDescription {
            return StatusExecuteTaskList;
        }
    }

    const statusListFormater  = (params:any) =>{
        return StatusExecuteTaskList.getDescription(params.value);
    }

    const actions: Array<ActionProps> = [
        {
            route:'execute-task/incluir',
            color:'success',
            type:'normal',
            title:'Incluir'
        },
        {
            route:'execute-task/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'execute-task/delete',
            color:'danger',
            type:'delete',
            title:'Excluir'
        },
        {
            route:'execute-task/execute',
            color:'primary',
            type:'grid',
            title:'Execução e Resultados'
        }
    ];

    return (
        <Grid actions={actions} rota="execute-task/list">
            <AgGridColumn sort="desc" width={100} headerName="#" checkboxSelection field="id"  sortable={ true } filter={'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn flex={1} minWidth={200} field="name" headerName={'Nome'}  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn width={250} headerName="Tipo"  field="type"  sortable={ true } filter={ FiltroType } valueFormatter={typeListFormater}></AgGridColumn>
            <AgGridColumn width={250} headerName="Situação"  field="status"  sortable={ true } filter={ FiltroStatus } valueFormatter={statusListFormater}></AgGridColumn>
        </Grid>
    );
};
export default ExecuteTaskList;