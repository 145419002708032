import React, { useState, useEffect, useRef } from "react";
import {useHistory, useParams} from "react-router-dom";
import {getApi} from "../apis/backendApis";
import {useAlertVisible} from "../components/alert";
import {Method} from "axios";
import {Option} from "react-select/src/filters";
import useCurrentAction from "./useCurrentAction";
import useLoading from "./useLoading";
import useAlert from "./useAlert";

interface UseCustomFormsParams {
    initialValues:any,
    onSubmit: (event:any)=>void,
    route:string,
    action?:string,
    onLoadDadosCompleted?: (data:any)=>void
    onSuccessCallBack?: (data:any)=>void
    onErrorCallBack?: (data:any)=>void
}

const useCustomForm = ({   initialValues,
                           route,
                           action,
                           onLoadDadosCompleted,
                           onSubmit,
                           onSuccessCallBack,
                       }:UseCustomFormsParams) => {
    const [values, setValues] = useState(initialValues || {});
    const [filesUpload, setFiles] = useState({});
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [onSubmitting, setOnSubmitting] = useState<boolean>(false);
    const [onBlur, setOnBlur] = useState<boolean>(false);
    const [setLoading] = useLoading();
    const [showAlert] = useAlert();

    const actionLocation = useCurrentAction();

    const formRendered = useRef(true);

    interface GetParam {
        id?: string
    }

    const { id } = useParams<GetParam>();

    let history = useHistory();

    useEffect(() => {
        if (formRendered.current) {
            setValues(initialValues);
            setErrors({});
            setTouched({});
            setOnSubmitting(false);
            setOnBlur(false);
        }
        formRendered.current = false;
    }, [initialValues]);

    useEffect(()=>{
        if(id && actionLocation && ['edit', 'view'].includes(actionLocation)){
            let api = getApi();
            setLoading(true);
            api.get(`${route}/get/${id}`).then((response)=>{
                setValues({...response.data});
                if(onLoadDadosCompleted){
                    onLoadDadosCompleted(response.data);
                }
            }).finally(()=>{
                setLoading(false);
            });
        }

    },[id, actionLocation]);

    const handleChangeSuggest = (value:Option, field:string, index?:number, propArray?: string)=>{
        if(index !== undefined && propArray){
            let newArrayValues = [...values[propArray]];
            newArrayValues[index] = {...newArrayValues[index], [field]:value}
            setValues({...values,[propArray]: newArrayValues});
        } else {
            setValues({ ...values, [field]: value });
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any >, index?:number, propArray?: string) => {
        const { target } = event;
        const { name, value, checked, type, files} = target;
        let valueToState: any = value;
        if(type == 'checkbox'){
            valueToState = checked;
        } else if(type == 'file'){
            let theFile:File = files[0];
            valueToState = theFile.name;

            setFiles({...filesUpload,[valueToState]:theFile})
        }
        event.persist();

        //Para grid dados
        if(index !== undefined && propArray){
            let newArrayValues = [...values[propArray]];
            newArrayValues[index] = {...newArrayValues[index], [name]:valueToState}
            setValues({...values,[propArray]: newArrayValues});
        } else if (index !== undefined){
            let actualValues = [...values];
            let newArrayValues = {...values[index], [name]:valueToState}
            actualValues[index] = newArrayValues;
            setValues(actualValues);
        } else {
            setValues({ ...values, [name]: valueToState });
        }
    };

    const handleClickMinus = (index:number, newValuesFunction: ()=>Object, property?:string)=>{
        if(property){
            let newArrayValues = [...values[property]];
            if(values[property].length == 1){
                newArrayValues[index] = newValuesFunction()
            } else {
                newArrayValues.splice(index, 1);
            }
            setValues({...values,[property]: newArrayValues});
        } else {
            let newArrayValues = [...values];
            if(values.length == 1){
                newArrayValues[index] = newValuesFunction()
            } else {
                newArrayValues.splice(index, 1);
            }
            setValues(newArrayValues);
        }
    }

    const handleClickPlus = (newValuesFunction: ()=>Object, property?:string, index?:number)=>{
        if(property){
            let newArrayValues = [...values[property]];
            if(index !== undefined){
                newArrayValues.splice(index+1, 0, newValuesFunction());
            } else {
                newArrayValues.push(newValuesFunction());
            }
            //newArrayValues.splice(index, 1);
            setValues({...values,[property]: newArrayValues});
        } else {
            let newArrayValues = [...values];
            if(index !== undefined){
                newArrayValues.splice(index+1, 0, newValuesFunction());
            } else {
                newArrayValues.push(newValuesFunction());
            }
            setValues(newArrayValues);
        }
    }

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { name } = target;
        setTouched({ ...touched, [name]: true });
        setErrors({ ...errors });
    };

    const handleSubmit = (event: any) => {
        if (event) event.preventDefault();
        setErrors({ ...errors });
        onSubmit({ values, errors , filesUpload});
    };

    const handleSubmitValues = (values:any, files?:File[]):void=>{
        setLoading(true);

        let api = getApi()
        let valuesString =  JSON.stringify(values);
        let formData = new FormData();

        formData.append('data', valuesString);
        if(files){
            let fileNames:string[] = Object.keys(files);
            fileNames.forEach((fileName:any)=>{
                formData.append(fileName, files[fileName]);
            })
        }

        let url = `${route}/`;
        let actionPost = '';
        if(action){
            actionPost = action;
        } else {
            actionPost = id && actionLocation == 'edit' ? 'edit' : 'add';
        }
        url += actionPost;
        url += id ? `/${id}`:'';

        let method:Method = 'post';

        api.request({
                url,
                method,
                data: formData,
                headers:{ 'Content-Type': 'multipart/form-data'}})
            .then((response)=>{
            showAlert({
                msg: response.data.msg,
                toaster: true,
            });
            history.goBack();
            onSuccessCallBack && onSuccessCallBack(response.data);
        }).catch((error)=>{
            showAlert({
                title:'Erro',
                msg: error.response.data.error,
                details:error.response.data.trace,
            });
        }).finally(()=>{
            setLoading(false);
        });
    }

    return {
        setValues,
        values,
        errors,
        touched,
        id,
        handleSubmitValues,
        handleChange,
        handleChangeSuggest,
        handleBlur,
        handleSubmit,
        handleClickMinus,
        handleClickPlus,
    };
};

export default useCustomForm;