import React, {useEffect, useState} from 'react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import  {ActionProps} from '../../../components/gridAction';
import {GridApi} from "ag-grid-community";
import {useLocation} from "react-router-dom";
import {AgGridColumn} from "ag-grid-react";
import enumDescription from "../../../services/enumDescription";
import {
    ContractObjetList,
    SituacaoClienteObjContratoList,
    SituacaoClienteObjContratoTypes,
} from "./index";
import api, {getApi} from "../../../apis/backendApis";
import useAlert from "../../../services/useAlert";

const ClienteObjetoContratoList = (props: any) => {

    let location = useLocation();
    let idLocation = location.pathname.split('/');
    let idCliente = idLocation[idLocation.length - 1];
    const [cliente, setCliente] = useState('')

    const handleGridReady = (gridApi: GridApi) => {
        const filterInstance = gridApi.getFilterInstance('situacao');
        filterInstance?.setModel({
            type: 'in',
            value: [SituacaoClienteObjContratoTypes.ATIVO, SituacaoClienteObjContratoTypes.ATIVO_HOMOLOGADO ]
        });
        gridApi.onFilterChanged();
    }

    useEffect(() => {
        let api = getApi();
        api.get(`cliente/get/` + idCliente).then((response) => {
            setCliente(response.data['pessoa']['nome']);
        });
    });
    const [showAlert] = useAlert();

    const actions: Array<ActionProps> = [
        {
            route: 'obj/incluir/' + idCliente,
            color: 'success',
            type: 'normal',
            title: 'Incluir'
        },
        {
            route: 'obj/alterar',
            color: 'warning',
            type: 'grid',
            title: 'Alterar'
        },
        {
            route: 'obj/visualizar',
            color: 'info',
            type: 'grid',
            title: 'Visualizar',
        },
        {
            route: 'cliente-objeto-contrato/delete',
            color: 'danger',
            type: 'delete',
            title: 'Excluir',
            callBackFunction: (response : any) => {
                let api = getApi();
                api.post('/obj/ativarDesativar/'+response?.data?.id).then((response) =>{
                    showAlert({
                        msg: response.data.msg,
                        toaster: true,
                    });
                })
                return true;
            }
        },
        {
            route: 'obj/ativarDesativar',
            color: 'primary',
            type: 'post',
            title: 'Ativar/Desativar',
        },
    ]

    return (
        <Grid actions={actions} rota={"cliente-objeto-contrato/list/" + idCliente}
              handleGridReady={handleGridReady} title={'Cliente: ' + cliente}
        >
            <AgGridColumn headerName="#" field={"id"} filter={'agNumberColumnFilter'} sortable={true} width={130}
                          checkboxSelection/>
            <AgGridColumn headerName="Objeto de Contrato" flex={1} field={"objetoContrato/nome"} filter={true}
                          sortable={true}/>
            <AgGridColumn headerName="Observação" flex={1} field={"observacao"} filter={true}
                          sortable={true}/>
            <AgGridColumn headerName="Valor de Setup" field={"objetoContrato/valorSetup"} filter={'number'}
                          sortable={true} width={150}/>
            <AgGridColumn sort="asc" headerName="Situação" field={"situacao"} filter={FiltroSituacao} sortable={true}
                          width={160}/>
            <AgGridColumn headerName="Tipo" field={"tipo"} filter={FiltroTipo} sortable={true} width={160}/>
            <AgGridColumn headerName="Data Início" width={200} field={"dataInicio"} sortable={true}
                          filter={'agDateColumnFilter'}></AgGridColumn>
            <AgGridColumn headerName="Data Fim" width={200} field={"dataFim"} sortable={true}
                          filter={'agDateColumnFilter'}></AgGridColumn>
        </Grid>
    );
};

class FiltroSituacao extends ListaMultiplaFilter {
    getList(): enumDescription {
        return SituacaoClienteObjContratoList;
    }
}

class FiltroTipo extends ListaMultiplaFilter {
    getList(): enumDescription {
        return ContractObjetList;
    }
}

export default ClienteObjetoContratoList;