import {ContentRoute} from "../../../models/Route";
import ClienteList from "./clienteList";
import ClienteProdutoList from "./clienteProdutoList";
import enumDescription from "../../../services/enumDescription";
import ClienteForm from "./clienteForm";
import ClienteObjetoContratoList from "./clienteObjetoContratoList";
import {TipoConfigTypes} from "../../contratos/objetocontrato";
import ClienteObjetoContratoForm from "../cliente/clienteComercial/clienteObjetoContratoForm";

export interface ClienteFormData {
    pessoa: object,
    clienteAnexo?: Array<ClienteAnexoForm>,
    clienteProduto?: Array<ClienteProdutoForm>,
    projetoSuporteRedmine: string
}

export interface ClienteAnexoForm {
    nome: string,
    tipo: number
    arquivo?: File,
    idDocumento?: number,
}

export interface ClienteProdutoForm {
    tipo: number,
    url?: string
}

export enum TipoAnexoTypes {
    CONTRATO            = 1,
    CERTIFICADO_DIGITAL = 2,
    MIDIA_KIT           = 3,
}

export enum TipoProdutoTypes {
    SITE            = 1,
    ADMIN           = 2,
}

export enum SituacaoTypes {
    EM_PROJETO = 1,
    ATIVO      = 2,
    INATIVO    = 3
}

export enum typesContractObjects {
    VALOR_PADRAO     = 1,
    AJUSTE_COMERCIAL = 2,

}

export enum ConfigTypes {
    TABELA_COMISSAO = 1,
    SOB_DEMANDA  = 2,
}

export enum SituacaoClienteObjContratoTypes {
    ATIVO    = 1,
    INATIVO  = 2,
    ATIVO_HOMOLOGADO  = 3,

}

export const SituacaoClienteObjContratoList = new enumDescription(  [
    {   value: SituacaoClienteObjContratoTypes.ATIVO, description: 'Ativo'},
    {   value: SituacaoClienteObjContratoTypes.INATIVO, description: 'Inativo'},
    {   value: SituacaoClienteObjContratoTypes.ATIVO_HOMOLOGADO, description: 'Ativo/Homologado'},
]);

export const ContractObjetList = new enumDescription(  [
    {   value: typesContractObjects.VALOR_PADRAO, description: 'Valores Padrão'},
    {   value: typesContractObjects.AJUSTE_COMERCIAL, description: 'Ajuste Comercial'},
]);

export const TipoAnexoList = new enumDescription(  [
    {   value: TipoAnexoTypes.CONTRATO, description: 'Contrato'    },
    {   value: TipoAnexoTypes.CERTIFICADO_DIGITAL, description: 'Certificado Digital'    },
    {   value: TipoAnexoTypes.MIDIA_KIT, description: 'Midia Kit'    },
]);

export const TipoProdutoList = new enumDescription(  [
    {   value: TipoProdutoTypes.SITE, description: 'Site'    },
    {   value: TipoProdutoTypes.ADMIN, description: 'Admin'    },
]);

export const SituacaoList = new enumDescription(  [
    {   value: SituacaoTypes.EM_PROJETO, description: 'Em Projeto/Implantação'},
    {   value: SituacaoTypes.ATIVO, description: 'Ativo/Homologado'},
    {   value: SituacaoTypes.INATIVO, description: 'Inativo'}
]);

export const TipoCobrancaList = new enumDescription(  [
    {   value: TipoConfigTypes.TABELA_COMISSAO, description: 'Tabela de Comissões'    },
    {   value: TipoConfigTypes.SOB_DEMANDA, description: 'Valor sob-demanda'    }
]);


export const cliente_content_routes: Array<ContentRoute> = [
    {
        'path':'/cliente',
        'description': 'Cliente',
        'exact':true,
        'component': ClienteList,
        'menu': true
    },
    {
        'path':'/cliente/produto/:id',
        'description': 'Cliente x Produto',
        'exact':true,
        'component': ClienteProdutoList,
        'menu': false
    },
    {
        'path':'/cliente/incluir',
        'description': 'Incluir Cliente',
        'exact':true,
        'component': ClienteForm,
        'menu': false
    },
    {
        'path':'/cliente/alterar/:id',
        'description': 'Alterar Cliente',
        'exact':true,
        'component': ClienteForm,
        'menu': false
    },
    {
        'path':'/cliente/visualizar/:id',
        'description': 'Visualizar Cliente',
        'exact':true,
        'component': ClienteForm,
        'menu': false
    },
    {
        'path':'/cliente/produto/',
        'description': 'Cliente x Produto',
        'exact':true,
        'component': ClienteProdutoList,
        'menu': true
    },
    {
        'path':'/cliente-objeto-contrato/:id',
        'description': 'Objetos de Contrato',
        'exact':true,
        'component': ClienteObjetoContratoList,
        'menu': false
    },
    {
        'path':'/cliente-objeto-contrato/obj/incluir/:id',
        'description': 'Objetos de Contrato',
        'exact':true,
        'component': ClienteObjetoContratoForm,
        'menu': false
    },
    {
        'path':'/cliente-objeto-contrato/obj/visualizar/:id',
        'description': 'Objetos de Contrato',
        'exact':true,
        'component': ClienteObjetoContratoForm,
        'menu': false
    },
    {
        'path':'/cliente-objeto-contrato/obj/alterar/:id',
        'description': 'Objetos fe Contrato',
        'exact':true,
        'component': ClienteObjetoContratoForm,
        'menu': false
    },

]