export enum LoadingStatusTypes{
    DISPLAY_ON = 1,
    DISPLAY_OFF = 2
}

export enum ActionsTypes{
    SHOW = 'SHOW',
    HIDE = 'HIDE'
}

export interface LoadingState {
    readonly state: number
}