import {ContentRoute} from "../../../models/Route";
import ChecklistList from "../checklist/checklistList";
import ChecklistForm from "../checklist/checklistForm";
import ChecklistItemForm from "../checklist/checklistItemForm";
import enumDescription from "../../../services/enumDescription";

export interface ChecklistFormData {
    nome: string,
    setor: number,
    ordem: number,
    ativo: boolean,
    versao: number,
}

export interface ChecklistItemFormData {
    tipo: number,
    titulo: string,
    obrigatorio:boolean
    draggId: string,
    ordem?: number,
    id?: string,
    descricao?: string,
    tipoMetrica?:number,
    opcoes: Array<OpcoesFormData>,
}

export interface OpcoesFormData {
    numero: number,
    descricao: string
}

export enum SetorTypes {
    COMERCIAL   = 1,
    FINANCEIRO  = 2,
    INFRA       = 3,
    PROJETOS    = 4,
    QUALIDADE   = 5,
}

export enum TipoPerguntaTypes {
    OBJETIVA         = 1,
    DISCURSIVA       = 2,
    MULTIPLA_ESCOLHA = 3,
    SIM_NAO          = 4,
}

export enum TipoMetrica {
    TEMPO         = 1,
}

export enum AtivoTypes {
    NAO  = 0,
    SIM  = 1,
}

export const AtivoList = new enumDescription(  [
    {   value: AtivoTypes.SIM, description: 'Sim'    },
    {   value: AtivoTypes.NAO, description: 'Não'    },
]);

export const SetorList = new enumDescription(  [
    {   value: SetorTypes.COMERCIAL, description: 'Comercial'    },
    {   value: SetorTypes.FINANCEIRO, description: 'Financeiro'    },
    {   value: SetorTypes.INFRA, description: 'Infra'    },
    {   value: SetorTypes.PROJETOS, description: 'Projetos'    },
    {   value: SetorTypes.QUALIDADE, description: 'Qualidade'    },
]);

export const TipoPerguntaList = new enumDescription(  [
    {   value: TipoPerguntaTypes.OBJETIVA, description: 'Objetiva'    },
    {   value: TipoPerguntaTypes.DISCURSIVA, description: 'Discursiva'    },
    {   value: TipoPerguntaTypes.MULTIPLA_ESCOLHA, description: 'Múltipla Escolha'    },
    {   value: TipoPerguntaTypes.SIM_NAO, description: 'Sim/Não'    }
]);

export const TipoMetricaList = new enumDescription(  [
    {   value: TipoMetrica.TEMPO, description: 'Tempo de Resposta'    },
]);

export const checklist_content_routes: Array<ContentRoute> = [
    {
        'path':'/checklist',
        'description': 'Checklist',
        'exact':true,
        'component': ChecklistList,
        'menu': true
    },
    {
        'path':'/checklist/incluir',
        'description': 'Incluir Checklist',
        'exact':true,
        'component': ChecklistForm,
        'menu': false
    },
    {
        'path':'/checklist/alterar/:id',
        'description': 'Alterar Checklist',
        'exact':true,
        'component': ChecklistForm,
        'menu': false
    },
    {
        'path':'/checklist/visualizar/:id',
        'description': 'Visualizar Checklist',
        'exact':true,
        'component': ChecklistForm,
        'menu': false
    },
    {
        'path':'/checklist/itens/:id',
        'description': 'Itens do Checklist',
        'exact':true,
        'component': ChecklistItemForm,
        'menu': false
    },
]