import React, {useState, useEffect} from 'react';
import useCustomForm from '../../services/useCustomForm'
import {
    CFormGroup,
    CLabel,
    CInput,
    CSelect
} from '@coreui/react';
import {NotificacaoDataFormData, NotificacaodestinatarioList, NotificacaoSituacaoList, NotificacaoTipoList, NotificacaoDestinatario} from "./index";
import Form, {FormApi} from "../../components/form";
import useCurrentAction from "../../services/useCurrentAction";
import RichTextEditor from "../../components/RichTextEditor";
import Select from "react-select";
import api, {getApi} from "../../apis/backendApis";
import {listRegimeTributacaoFiltro} from "../clients";
import AsyncSelect from "react-select/async";
import {Auth} from "../../models/Auth";

const NotificacaoForm = ()=>{

    const loadClientes = new Promise((resolve, reject) => {



    });

    const [listGateway, setListGateway] = useState([]);
    const [listFormaPagamento, setListFormaPagamento] = useState([]);
    const [listMarketplace, setListMarketplace] = useState([]);
    //filtros para envio
    const [gateway, setGateway] = useState([]);
    const [formaPagamento, setFormaPagamento] = useState([]);
    const [marketplace, setMarketplace] = useState([]);
    const [regimeTributacao, setRegimeTributacao] = useState([]);
    const [clientes, setClientes] = useState([]);

    const [formApi, setFormApi] = useState<FormApi>();
    const action = useCurrentAction();

    const initialValues:NotificacaoDataFormData = {
        id:0,
        titulo:'',
        descricao:'',
        situacao:1
    }



    useEffect(()=>{
        async function loadLists(){
            let api = getApi();
            api.get(`client-status/lists`).then((response)=>{
                let data = response.data;
                setListGateway(getList(data['gatewayList']))
                setListMarketplace(getList(data['marketplaceList']))
                setListFormaPagamento(getList(data['formaPagamentoList']))
            }).finally(()=>{

            });
        }
        loadLists();
    },[]);

    const getList = (list : any) => {
        let data : any = [];
        (Object.keys(list) as (keyof typeof list)[]).forEach((key, index) => {
            let item : any = {};
            item['label'] = list[key];
            item['value'] = key;
            data.push(item);
        });
        return data;
    }

    const {
        values,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        setValues
    } = useCustomForm(
        {
            initialValues,
            route:'notificacao',
            onSubmit: values => submitValues(values.values),
            onLoadDadosCompleted: (values) => {
                console.log(values);
                if(values.filterClientes){
                    if(values.filterClientes.length > 0){
                        //vamos carregar os valores das listas
                        let selectedClientes = values.filterClientes;
                        setClientes(selectedClientes);
                    }
                }
            }
        }
    );

    const getSelectedItems = (list : any, selecteds : any) =>{
        let selectList : any = [];
        list.map((item : any) => {
            let val = item.value;
            if(selecteds.includes(val)){
                selectList.push(item);
            }
        });
        return selectList;
    }

    useEffect(()=>{
        if(listGateway.length > 0 && values.filterGatewayPagamento){
            //vamos carregar os valores das listas
            let selectedGateway = values.filterGatewayPagamento;
            if(selectedGateway && selectedGateway.length > 0){
                let selectList = getSelectedItems(listGateway, selectedGateway);
                if(selectList.length > 0){
                    setGateway(selectList);
                }
            }
            delete values.filterGatewayPagamento;
        }
        if(listFormaPagamento.length > 0 && values.filterFormaPagamento){
            //vamos carregar os valores das listas
            let selectedFormaPagamento = values.filterFormaPagamento;
            if(selectedFormaPagamento && selectedFormaPagamento.length > 0){
                let selectList = getSelectedItems(listFormaPagamento, selectedFormaPagamento);
                if(selectList.length > 0){
                    setFormaPagamento(selectList);
                }
            }
            delete values.filterFormaPagamento;
        }
    },[listGateway, listFormaPagamento, values]);

    useEffect(()=>{
        if(listMarketplace.length > 0 && values.filterMarketplace){
            //vamos carregar os valores das listas
            let selectedMarketplace = values.filterMarketplace;
            if(selectedMarketplace && selectedMarketplace.length > 0){
                let selectList = getSelectedItems(listMarketplace, selectedMarketplace);
                if(selectList.length > 0){
                    setMarketplace(selectList);
                }
            }
            delete values.filterMarketplace;
        }
    },[listMarketplace, values]);

    useEffect(()=>{
        if(values.filterTributacao){
            //vamos carregar os valores das listas
            let selectedTributacao = values.filterTributacao;
            if(selectedTributacao && selectedTributacao.length > 0){
                let selectList = getSelectedItems(listRegimeTributacaoFiltro, selectedTributacao);
                if(selectList.length > 0){
                    setRegimeTributacao(selectList);
                }
            }
            delete values.filterTributacao;
        }
    },[listRegimeTributacaoFiltro, values]);

    const submitValues = (values : any) => {
        switch (parseInt(values.destinatario)){
            case NotificacaoDestinatario.DESTINATARIO_SELECIONAR_CLIENTE:
                values['clientes'] = parseListToFormData(clientes);
            break;
            case NotificacaoDestinatario.DESTINATARIO_MARKETPLACE:
                values['marketplaces'] = parseListToFormData(marketplace);
            break;
            case NotificacaoDestinatario.DESTINATARIO_GATEWAY_PAGAMENTO:
                values['gatewayPagamento'] = parseListToFormData(gateway);
                values['formaPagamento']   = parseListToFormData(formaPagamento);
            break;
            case NotificacaoDestinatario.DESTINATARIO_REGIME_TRIBUTACAO:
                values['regimeTributacao'] = parseListToFormData(regimeTributacao);
            break;
        }
        handleSubmitValues(values);
    }

    const parseListToFormData = (listParse : any) => {
        let list : any = [];
        listParse.map((item : any)=> {
            let value = item.value;
            if(!value && item.id > 0){
                value = item.id;
            }
            list.push(value);
        });
        return list;
    }

    const isView = () => {
        return action === 'view';
    };

    const handleFormReady = (formApi:FormApi)=>{
        setFormApi(formApi);
    }

    const handleChangeMarketplace = (option : any) => {
        setMarketplace(option);
    }

    const handleChangeFormaPagamento = (option : any) => {
        setFormaPagamento(option);
    }

    const handleChangeRegime = (option : any) => {
        setRegimeTributacao(option);
    }

    const handleChangeGateway = (option : any) => {
        setGateway(option);
    }

    const handleChangeClientes = (option : any) => {
        setClientes(option);
    }

    const promiseOptions = (inputValue:string):Promise<any> => {
        if(inputValue && inputValue.toString().length > 3){
            let api = getApi();
            return api.get(`/cliente/suggest/${inputValue}`).then(response=>response.data);
        } else {
            return new Promise((resolve, reject) => {
               resolve([]);
            });
        }
    }

    const editorStyle = {
        'height':"200px",
        'margin-bottom':'60px'
    }

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
        <Form entity="Notificacao" handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CFormGroup>
                <CLabel htmlFor="titulo">Assunto da Notificação (não enviado na mensagem, apenas para controle interno)</CLabel>
                <CInput readOnly={isView()} disabled={isView()} type="text" id="titulo" name="titulo" maxLength={600} onChange={handleChange} value={values.titulo} required/>
            </CFormGroup>
            <CFormGroup>
                <CLabel htmlFor="descricao">Mensagem ao Cliente</CLabel>
                <div>
                    Parâmetros:<br />
                    {"{{changelogLink}} - Link de acesso a consulta de ChangeLogs do Site da Panorama Sistemas (sprints)"}
                </div>
                <RichTextEditor
                    style={editorStyle}
                    onChange={(value: any) => {
                        setValues((previousValue: any) => {
                            return {...previousValue,descricao: value}
                        });
                    }}
                    value={values.descricao}
                />

            </CFormGroup>
            <CFormGroup>
                <CLabel className={'text-danger font-weight-bold'} htmlFor="tipoNotificacao">Tipo</CLabel>
                <CSelect readOnly={isView()} disabled={isView()} required name="tipoNotificacao" id="tipoNotificacao" onChange={event=>handleChange(event)} value={values.tipoNotificacao}>
                    {NotificacaoTipoList.getList(true).map(item=>(
                        <option key={item.value} value={item.value}>{item.description}</option>
                    ))}
                </CSelect>
            </CFormGroup>
            <CFormGroup>
                <CLabel className={'text-danger font-weight-bold'} htmlFor="destinatario">Filtro para Destinatário(s)</CLabel>
                <CSelect readOnly={isView()} disabled={isView()} name="destinatario" id="destinatario" onChange={event=>handleChange(event)} value={values.destinatario}>
                    {NotificacaodestinatarioList.getList(true).map(item=>(
                        <option key={item.value} value={item.value}>{item.description}</option>
                    ))}
                </CSelect>
            </CFormGroup>
            {(values.destinatario > 0 && values.destinatario == NotificacaoDestinatario.DESTINATARIO_MARKETPLACE) && (
                <CFormGroup>
                    <CLabel className={'text-danger font-weight-bold'} htmlFor="marketplace">Marketplace</CLabel>
                    <Select
                        id={'marketplace'}
                        name={'marketplace'}
                        options={listMarketplace}
                        isMulti
                        onChange={handleChangeMarketplace}
                        placeholder={'Marketplace'}
                        closeMenuOnSelect={false}
                        value={marketplace}
                    ></Select>
                </CFormGroup>
            )}
            {(values.destinatario > 0 && values.destinatario == NotificacaoDestinatario.DESTINATARIO_GATEWAY_PAGAMENTO) && (
                <>
                    <CFormGroup>
                        <CLabel htmlFor="gatewayPagamento">Gateway de Pagamento</CLabel>
                        <Select
                            id={'gatewayPagamento'}
                            name={'gatewayPagamento'}
                            options={listGateway}
                            isMulti
                            onChange={handleChangeGateway}
                            value={gateway}
                            placeholder={'Gateway de Pagamento'}
                            closeMenuOnSelect={false}
                        ></Select>
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel htmlFor="formaPagamento">Forma de Pagamento</CLabel>
                        <Select
                            id={'formaPagamento'}
                            name={'formaPagamento'}
                            options={listFormaPagamento}
                            isMulti
                            onChange={handleChangeFormaPagamento}
                            value={formaPagamento}
                            placeholder={'Forma de Pagamento'}
                            closeMenuOnSelect={false}
                        ></Select>
                    </CFormGroup>
                </>
            )}
            {(values.destinatario > 0 && values.destinatario == NotificacaoDestinatario.DESTINATARIO_REGIME_TRIBUTACAO) && (
                <CFormGroup>
                    <CLabel className={'text-danger font-weight-bold'} htmlFor="regimeTributacao">Regime de Tributação</CLabel>
                    <Select
                        name={'regimeTributacao'}
                        id={'regimeTributacao'}
                        options={listRegimeTributacaoFiltro}
                        isMulti
                        onChange={handleChangeRegime}
                        value={regimeTributacao}
                        placeholder={'Regime de Tributação'}
                        closeMenuOnSelect={false}
                    ></Select>
                </CFormGroup>
            )}

            {(values.destinatario > 0 && values.destinatario == NotificacaoDestinatario.DESTINATARIO_SELECIONAR_CLIENTE) && (
                <CFormGroup>
                    <CLabel className={'text-danger font-weight-bold'} htmlFor="cliente">Cliente</CLabel>
                    <AsyncSelect
                        id={'cliente'}
                        name={'cliente'}
                        isMulti
                        defaultOptions
                        value={clientes}
                        loadOptions={promiseOptions}
                        placeholder={'Informa o nome do cliente...'}
                        onChange={handleChangeClientes}>
                    </AsyncSelect>
                </CFormGroup>
            )}
            <CFormGroup className={'border-top pt-2'}>
                <CLabel htmlFor="dataHoraProgramada">Programar Data/Hora de Envio</CLabel>
                <CInput readOnly={isView()} disabled={isView()} type="datetime-local" id="dataHoraProgramada" name="dataHoraProgramada" onChange={handleChange} value={values.dataHoraProgramada} />
            </CFormGroup>
            <CFormGroup>
                <CLabel htmlFor="situacao">Situação</CLabel>
                <CSelect readOnly={true} disabled={true} name="situacao" id="situacao"  onChange={event=>handleChange(event)} value={values.situacao}>
                    {NotificacaoSituacaoList.getList(true).map(item=>(
                        <option key={item.value} value={item.value}>{item.description}</option>
                    ))}
                </CSelect>
            </CFormGroup>
        </Form>
    )
}
export default NotificacaoForm;