import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../components/grid';
import { ActionProps } from '../../components/gridAction';
import enumDescription from "../../services/enumDescription";
//import {SituacaoList, SituacaoTypes} from './index';
import {GridApi} from "ag-grid-community";

const ChangeLogList = (props:any) => {

    /*const situacaoListFormater  = (params:any) =>{
        return SituacaoList.getDescription(params.value);
    }*/

    const handleGridReady = (gridApi:GridApi)=>{
        /*
        const filterInstance = gridApi.getFilterInstance('situacao');
        const model = filterInstance?.getModel();
        filterInstance?.setModel({
            type: 'in',
            value: [SituacaoTypes.EM_EXECUCAO, SituacaoTypes.PENDENTE]
        });
        gridApi.onFilterChanged();*/
    }

    const actions: Array<ActionProps> = [
        {
            route:'changelog/add',
            color:'success',
            type:'normal',
            title:'Novo',
        },
        {
            route:'changelog/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'changelog/delete',
            color:'danger',
            type:'delete',
            title:'Excluir'
        },
        {
            route:'changelog/visualizar',
            color:'info',
            type:'grid',
            title:'Visualizar',
        },
    ];

    const defaultColumnDefinition = {
        resizable: true,
        flex: 1,
        filter: true,
        sortable: true
    };

    return (
        <Grid actions={actions} rota="changelog/list" handleGridReady={handleGridReady} defaultColDef={defaultColumnDefinition}>
            <AgGridColumn minWidth={50} headerName="#" field="id" ></AgGridColumn>
            <AgGridColumn minWidth={200} headerName="Version" field="version" ></AgGridColumn>
            <AgGridColumn minWidth={200} headerName="Data/Hora" field="date" sort={'desc'}></AgGridColumn>
        </Grid>
    );
};



export default ChangeLogList;