import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import {booleanFormater} from '../../../components/gridUtils';
import { ActionProps } from '../../../components/gridAction';
import enumDescription from "../../../services/enumDescription";
import {
    MesList,
    mesListFormater,
    SituacaoList,
    situacaoListFormater,
    SituacaoOrigemList,
    situacaoOrigemListFormater
} from '../index'
import {GridApi} from "ag-grid-community";
import {SituacaoTypes} from "./index";


const ResultadoList = (props:any) => {


    const handleGridReady = (gridApi:GridApi)=>{
        const filterInstance = gridApi.getFilterInstance('situacao');
        const model = filterInstance?.getModel();
        filterInstance?.setModel({
            type: 'in',
            value: [SituacaoTypes.SITUACAO_EM_ABERTO]
        });
        gridApi.onFilterChanged();
    }

    return (
        <Grid rota="financeiro/list" handleGridReady={handleGridReady}>
            <AgGridColumn width={150} sort="desc" headerName="#" checkboxSelection field="id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn width={150} headerName="Origem" field="origem"  filter={ FiltroOrigem } valueFormatter={situacaoOrigemListFormater} sortable={ true } ></AgGridColumn>
            <AgGridColumn width={150} headerName="#Fatura" field="fatura/id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn width={100} headerName="Ano" field="ano"  sortable={ true } filter={ true } ></AgGridColumn>
            <AgGridColumn width={150} headerName="Mês" field="mes"  sortable={ true } valueFormatter={mesListFormater} filter={ FiltroMes } ></AgGridColumn>
            <AgGridColumn width={150} headerName="#Cliente" field="cliente/id"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn flex={1} minWidth={300} headerName="Cliente" field="pessoa/nome"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn width={200} headerName="Situação" field="situacao"  sortable={ true } filter={ FiltroSituacao }  valueFormatter={situacaoListFormater} ></AgGridColumn>
            <AgGridColumn width={150} headerName="Valor Cobrança" field="valorCobranca" filter={ 'agNumberColumnFilter'} valueFormatter={'currencyFormatter'} sortable={ true } ></AgGridColumn>
            <AgGridColumn width={200} headerName="Data Atualização" field="dataHora"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn width={280} headerName="Objeto de Contrato" field="objetoContrato"  sortable={ true } filter={ false }></AgGridColumn>
            <AgGridColumn width={200} headerName="Qtde. Notas" field="quantidadeNotas" filter={ 'agNumberColumnFilter'}  sortable={ true } ></AgGridColumn>
            <AgGridColumn width={200} headerName="Valor Faturado Loja" field="valorFaturamento" filter={ 'agNumberColumnFilter'}  sortable={ true } ></AgGridColumn>
            <AgGridColumn width={250} headerName="Valor Faturado Marketplace" field="valorFaturamentoMarketplace" filter={ 'agNumberColumnFilter'}  sortable={ true } ></AgGridColumn>
            <AgGridColumn width={150} headerName="% Plataforma" field="aliquota" filter={ 'agNumberColumnFilter'}  sortable={ true } ></AgGridColumn>
            <AgGridColumn width={170} headerName="% Plataforma MKT" field="aliquotaMarketplace" filter={ 'agNumberColumnFilter'}  sortable={ true } ></AgGridColumn>
        </Grid>
    );
};

class FiltroSituacao extends ListaMultiplaFilter{
    getList(): enumDescription {
        return SituacaoList;
    }
}

class FiltroMes extends ListaMultiplaFilter{
    getList(): enumDescription {
        return MesList;
    }
}
class FiltroOrigem extends ListaMultiplaFilter{
    getList(): enumDescription {
        return SituacaoOrigemList;
    }
}

export default ResultadoList;