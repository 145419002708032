import React, {useEffect, useState} from 'react';
import useCustomForm from '../../../services/useCustomForm'
import {
    CFormGroup,
    CLabel,
    CInput,
    CCard,
    CCardBody,
    CButton,
    CTabs, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CSelect, CInputFile
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
    ClienteAnexoForm,
    ClienteFormData,
    ClienteProdutoForm,
    TipoAnexoTypes,
    TipoAnexoList,
    TipoProdutoList,
    TipoProdutoTypes,
    SituacaoList
} from "./index";
import AsyncSelect from "react-select/async";
import {getApi} from "../../../apis/backendApis";
import Form, {FormApi} from "../../../components/form";
import useCurrentAction from "../../../services/useCurrentAction";


const ClienteForm = ()=>{

    const [formApi, setFormApi] = useState<FormApi>();
    const [listProjetoRedmine, setListProjetoRedmine] = useState([]);

    const action = useCurrentAction();

    const getInitialValueProduto = ()=>{
        return {url: '', tipo: TipoProdutoTypes.SITE };
    }

    const getInitialValueAnexo = ()=>{
        return {nome: '', tipo: TipoAnexoTypes.CONTRATO };
    }

    const initialValues:ClienteFormData = {
        pessoa: {},
        clienteProduto: [getInitialValueProduto()],
        clienteAnexo: [getInitialValueAnexo()],
        projetoSuporteRedmine: ''
    }
    /*
    useEffect(()=>{

        let api = getApi();
        api.get(`cliente/projetos`,{
            params: {}
        }).then((response)=>{
            let data = response.data;
            setListProjetoRedmine(data);

        }).finally(()=>{

        });
    },[]);*/

    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        handleChangeSuggest,
        handleClickMinus,
        handleClickPlus,
    } = useCustomForm(
        {
            initialValues,
            route:'cliente',
            onSubmit: values => {
                let newProdutos = values.values.clienteProduto.filter((clienteProduto:ClienteProdutoForm) => {
                    return clienteProduto.url && clienteProduto.url !== '';
                })

                let newAnexos = values.values.clienteAnexo.filter((clienteAnexo:ClienteAnexoForm) => {
                    return clienteAnexo.nome && clienteAnexo.nome !== '';
                })

                let newValues = {...values.values, clienteProduto:newProdutos, clienteAnexo:newAnexos};

                handleSubmitValues(newValues, values.filesUpload)
            },
            onLoadDadosCompleted : (data)=>{
                let api = getApi();
                api.get(`cliente/projetos`,{
                    params: {}
                }).then((response)=>{
                    let dataList = response.data;
                    setListProjetoRedmine(dataList);
                    //setValores na tela
                    let clienteProdutoNew = data.clienteProduto;
                    if(clienteProdutoNew.length == 0){
                        clienteProdutoNew = [getInitialValueProduto()];
                    }

                    let clienteAnexoNew = data.clienteAnexo;
                    if(clienteAnexoNew.length == 0){
                        clienteAnexoNew = [getInitialValueAnexo()];
                    } else {
                        clienteAnexoNew = clienteAnexoNew.map((anexo:any)=>({...anexo, idDocumento:anexo.documento.id}))
                    }

                    let newData = {
                        ...data,
                        pessoa: {value: data.pessoa.id , label: data.pessoa.nome},
                        clienteAnexo: clienteAnexoNew,
                        clienteProduto: clienteProdutoNew
                    }
                    console.log(newData);
                    setValues(newData);

                }).finally(()=>{

                });



            }
        }
    );

    const isView = () => {
        return action === 'view';
    };

    const promiseOptions = (inputValue:string):Promise<any> => {
        let api = getApi();
        return api.get(`/pessoa/suggest/${inputValue}`).then(response=>response.data);
    }

    const generateLink = (id:number):string=>{
        let api = getApi();
        return `${api.defaults.baseURL}cliente/download_document/${id}`;
    }

    const handleFormReady = (formApi:FormApi)=>{
        setFormApi(formApi);
    }

    return (
        <Form entity="Cliente" handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>
                            Geral
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>
                            Produtos
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>
                            Anexos
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>
                            Configuração
                        </CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <CCard>
                            <CCardBody>
                                <CFormGroup>
                                    <CFormGroup>
                                        <CLabel htmlFor="pessoa">Pessoa</CLabel>
                                        <AsyncSelect isDisabled={isView()} defaultOptions loadOptions={promiseOptions} onChange={newValue=>handleChangeSuggest(newValue, 'pessoa')} value={values.pessoa}>
                                        </AsyncSelect>
                                    </CFormGroup>
                                    <CFormGroup>
                                        <CLabel htmlFor="situacao">Situação</CLabel>
                                        <CSelect readOnly={isView()} disabled={isView()} name="situacao" id="situacao" value={values.situacao} onChange={handleChange}>
                                            <option key={''} value={''}>Não informado</option>
                                            {SituacaoList.getList().map(item=>(
                                                <option key={item.value} value={item.value}>{item.description}</option>
                                            ))}
                                        </CSelect>
                                    </CFormGroup>
                                    <CFormGroup>
                                        <CLabel htmlFor="dataHomologacao">Data Homologação</CLabel>
                                        <CInput readOnly={isView()} disabled={isView()} type="date" id="dataHomologacao" name="dataHomologacao" onChange={handleChange} value={values.dataHomologacao} />
                                    </CFormGroup>
                                </CFormGroup>
                            </CCardBody>
                        </CCard>
                    </CTabPane>
                    <CTabPane>
                        {values.clienteProduto && values.clienteProduto.map((produto:ClienteProdutoForm, index:number)=>(
                            <CCard key={index}>
                                <CCardBody>
                                    <CCard>
                                        <CCardBody>
                                            <CFormGroup>
                                                <CLabel htmlFor="tipo">Tipo</CLabel>
                                                <CSelect readOnly={isView()} disabled={isView()} name="tipo" id="tipo"  onChange={event=>handleChange(event, index, 'clienteProduto')} value={values.clienteProduto[index].tipo}>
                                                    {TipoProdutoList.getList().map(item=>(
                                                        <option key={item.value} value={item.value}>{item.description}</option>
                                                    ))}
                                                </CSelect>
                                            </CFormGroup>
                                            <CFormGroup>
                                                <CLabel htmlFor="url">URL</CLabel>
                                                <CInput readOnly={isView()} disabled={isView()} type="text" id="url" name="url" onChange={event=>handleChange(event, index, 'clienteProduto')} value={values.clienteProduto[index].url} />
                                            </CFormGroup>
                                            <div hidden={isView()}>
                                                <CFormGroup row className="justify-content-end">
                                                    <CButton color="light" className="mr-1 small btn-sm" ><CIcon name="cil-plus" onClick={event=>handleClickPlus(getInitialValueProduto, 'clienteProduto')}/></CButton>
                                                    <CButton color="light" className="small btn-sm"><CIcon name="cil-minus" onClick={event=>handleClickMinus(index, getInitialValueProduto, 'clienteProduto')} /></CButton>
                                                </CFormGroup>
                                            </div>
                                        </CCardBody>
                                    </CCard>
                                </CCardBody>
                            </CCard>
                        ))}
                    </CTabPane>
                    <CTabPane>
                        {values.clienteAnexo && values.clienteAnexo.map((anexo:ClienteAnexoForm, index:number)=>(
                            <CCard key={index}>
                                <CCardBody>
                                    <CCard>
                                        <CCardBody>
                                            <CFormGroup>
                                                <CLabel htmlFor="tipo">Tipo</CLabel>
                                                <CSelect readOnly={isView()} disabled={isView() || values.clienteAnexo[index].idDocumento} name="tipo" id="tipo"  onChange={event=>handleChange(event, index, 'clienteAnexo')} value={values.clienteAnexo[index].tipo}>
                                                    {TipoAnexoList.getList().map(item=>(
                                                        <option key={item.value} value={item.value}>{item.description}</option>
                                                    ))}
                                                </CSelect>
                                            </CFormGroup>
                                            <CFormGroup>
                                                <CLabel htmlFor="arquivo">Arquivo</CLabel>
                                                {!values.clienteAnexo[index].idDocumento &&  (<CInputFile readOnly={isView()} disabled={isView()} type="text" id="nome" name="nome" onChange={event=>handleChange(event, index, 'clienteAnexo')}  />)}
                                                {values.clienteAnexo[index].idDocumento && (
                                                    <div>
                                                        <span className="pr-3">{values.clienteAnexo[index].nome}</span>
                                                        <a href={generateLink(values.clienteAnexo[index].idDocumento)} target="_blank" download >
                                                            <CButton className="btn-sm py-1" color="success" onClick={()=>{}}>
                                                                <CIcon name="cil-cloud-download" className="mr-2" />Baixar
                                                            </CButton>
                                                        </a>
                                                    </div>)}

                                            </CFormGroup>
                                            <div hidden={isView()}>
                                                <CFormGroup row className="justify-content-end">
                                                    <CButton color="light" className="mr-1 small btn-sm" ><CIcon name="cil-plus" onClick={event=>handleClickPlus(getInitialValueAnexo, 'clienteAnexo')}/></CButton>
                                                    <CButton color="light" className="small btn-sm"><CIcon name="cil-minus" onClick={event=>handleClickMinus(index,getInitialValueAnexo, 'clienteAnexo')} /></CButton>
                                                </CFormGroup>
                                            </div>
                                        </CCardBody>
                                    </CCard>
                                </CCardBody>
                            </CCard>
                        ))}
                    </CTabPane>
                    <CTabPane>
                        <CFormGroup>
                            <CLabel htmlFor="projetoSuporteRedmine">Projeto Suporte do Cliente no Redmine. Deve-se associar cadastros por CNPJ.</CLabel>
                            <CSelect readOnly={true} disabled={true} name="projetoSuporteRedmine" id="projetoSuporteRedmine"  value={values.projetoSuporteRedmine} onChange={handleChange}>
                                <option key={''} value={''}>Não identificado Projeto Associado no Redmine para Suporte do Cliente.</option>
                                {listProjetoRedmine.map( (item : any,index)=>(
                                    <option key={index} value={item.id}>{item.label}</option>
                                ))}
                            </CSelect>
                        </CFormGroup>

                    </CTabPane>
                </CTabContent>
            </CTabs>
        </Form>
    )
}

export default ClienteForm;