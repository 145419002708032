import React, {useEffect, useState} from 'react';
import {
    CAlert,
    CBadge,
    CButton,
    CCardBody,
    CCol,
    CForm,
    CFormGroup,
    CInput,
    CInputCheckbox,
    CLabel, CModal, CModalBody, CModalHeader, CModalTitle,
    CRow
} from "@coreui/react";
import {getApi} from "../../../../apis/backendApis";
import {useLocation} from "react-router-dom";
import {NumericFormat} from "react-number-format";
import {SituacaoFaturaDocumentoTypes} from "../../index";
import {numberDecimalFormat} from "../../../../components/numberBRLFormatForGrid";
import {numberDecimalFormatForm} from "../../../../components/numberBRLFormatForForm";
import useAlert from "../../../../services/useAlert";

const LiquidaBoleto = () => {

    let location = useLocation();
    let idLocation = location.pathname.split('/');
    let idFatura = idLocation[idLocation.length - 1];

    const initialValues = [{
        id: '',
        fatura: '',
        tipo: '',
        valor: '0',
        valorPago: '0',
        dataHoraVencimento: '',
        situacao: '',
        parcela: '',
        logMensagem: '',
        isPago: false
    }];
    const [showAlert] = useAlert();
    const [documentoFatura, setDocumentoFatura] = useState<any[]>(initialValues);
    const [saldoALiquidar, setSaldoALiquidar] = useState<number>(0);
    const [saldoPendente, setSaldoPendente] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [indexLogMensagem, setIndexLogMensagem] = useState<number>(0);
    const [forcandoEnvio, setForcandoEnvio] = useState<boolean>(false);
    useEffect(() => {
        atualizaDados();
    }, [idFatura]);

    const atualizaDados = () =>{
        let api = getApi();
        api.get('faturaDocumento/get/' + idFatura).then((response) => {
            setDocumentoFatura(response.data);
            setLoading(false);
            const documento = response.data;
            calcularSaldoPendente(documento);
        });
    }

    useEffect(() => {
        const novoSaldoALiquidar = documentoFatura
            .filter(doc => doc.situacao !== SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO  && doc.isPago)
            .reduce((acumulador, doc) => acumulador + parseFloat(doc.valorPago || doc.valor), 0);
        setSaldoALiquidar(novoSaldoALiquidar);
    }, [documentoFatura]);

    const calcularSaldoPendente = (documentos: any[]) => {
        let saldo = 0;

        if (documentos && documentos.length > 0) {
            saldo = documentos.reduce((total, documento) => {
                if (documento?.situacao != SituacaoFaturaDocumentoTypes.SITUACAO_CANCELADO && documento?.situacao != SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO) {
                    const valor = parseFloat(documento.valor) || 0;
                    const valorPago = parseFloat(documento.valorPago) || 0;
                    return total + (valor - valorPago);
                }
                return total;
            }, 0);
        }

        setSaldoPendente(saldo);
    };
    const situacaoBadge = (situacao: number) => {
        let color = '';
        let text = '';

        switch (situacao) {
            case SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO:
                color = 'success';
                text = 'Liquidado';
                break;
            case SituacaoFaturaDocumentoTypes.SITUACAO_PENDENTE:
            case SituacaoFaturaDocumentoTypes.SITUACAO_PRONTO_ENVIO:
            case SituacaoFaturaDocumentoTypes.SITUACAO_SINCRONIZADO:
                color = 'warning';
                text = 'Pendente';
                break;
            case SituacaoFaturaDocumentoTypes.SITUACAO_CANCELADO:
                color = 'danger';
                text = 'Cancelado';
                break;
            case SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO_ERRO_ENVIO:
                color = 'primary';
                text = 'Liquidado (Erro Envio)';
                break
            default:
                color = 'secondary';
                text = 'Desconhecido';
        }

        return <CBadge color={color}>{text}</CBadge>;
    };

    const handleCheckboxChange = (index: number) => {
        const newDocumentoFatura = [...documentoFatura];
        newDocumentoFatura[index].isPago = !newDocumentoFatura[index].isPago
        if (!newDocumentoFatura[index].isPago){
            newDocumentoFatura[index].valorPago = 0 ;
        }else{
            if (!newDocumentoFatura[index].valorPago){
                newDocumentoFatura[index].valorPago = newDocumentoFatura[index].valor;
            }
        }
        setDocumentoFatura(newDocumentoFatura);
    };

    const handleChangeValorPago = (value: any, index: number) => {
        const valorPago = value !== '' ? parseFloat(value) : 0;
        const newDocumentoFatura = [...documentoFatura];
        newDocumentoFatura[index] = {
            ...newDocumentoFatura[index],
            valorPago: isNaN(valorPago) ? 0 : valorPago
        };
        setDocumentoFatura(newDocumentoFatura);
    };

    const confirmarLiquidacao = () => {

        const faturasPagas = documentoFatura.filter((doc: any) => doc.isPago);

        if (faturasPagas.length > 0) {
            setLoading(true);
            let valuesString = JSON.stringify(faturasPagas);
            let formData = new FormData();
            formData.append('data', valuesString);
            let api = getApi();
            api.request({
                url: '/fatura/cliente/faturado/' + idFatura,
                method: "post",
                data: formData
            }).then((response) => {
                if (response.data['success']) {
                    showAlert({
                        msg: response.data['message'],
                        toaster: true,
                    });
                    atualizaDados();
                } else {
                    showAlert({
                        title: 'Ocorreu um problema!',
                        msg: response.data['message'],
                        type: 'danger',
                        fnOk: () => {
                            atualizaDados();
                        }
                    });
                }
            });
        }else{
            showAlert({
                title: 'Ocorreu um problema!',
                msg: 'Nenhum boleto selecionado!',
                type: 'danger',
                fnOk: () => {
                    setLoading(false);
                }
            });
        }
    }
    const forcarEnvio = (index: number) => {
        let forcaBoleto = documentoFatura[index];
        if (forcaBoleto.situacao === SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO_ERRO_ENVIO) {
            setForcandoEnvio(true)
            let valuesString = JSON.stringify(forcaBoleto);
            let formData = new FormData();
            formData.append('data', valuesString);
            let api = getApi();
            api.request({
                url: '/boleto/forcaEvioEcommerce/' + idFatura,
                method: "post",
                data: formData
            }).then((response) => {
                if (response.data['success']) {
                    showAlert({
                        msg: response.data['message'],
                        toaster: true,
                    });
                    atualizaDados();
                } else {
                    showAlert({
                        title: 'Ocorreu um problema!',
                        msg: response.data['message'],
                        type: 'danger',
                        fnOk: () => {
                            atualizaDados();
                        }
                    });
                }
                setForcandoEnvio(false)
            })
        }
    };
    return (
        <CForm className={'bg-white text-dark container-fluid'}>
            <div className={'tabela-boleto'}>
                <div className={'row font-weight-bold font-lg text-white m-0 p-0'}>
                    <div className={'col-3 border-right  border-bottom m-0 p-2'}>Parcela</div>
                    <div className={'col-3 border-right  border-bottom m-0 p-2'}>Log</div>
                    <div className={'col-3 border-right  border-bottom m-0 p-2'}>Valor</div>
                    <div className={'col  valor-pago border-right  border-bottom m-0 p-2'}>Valor Pago</div>
                    <div className={'col-1 border-bottom m-0 p-2'}>Liquidar?</div>
                </div>
                {documentoFatura.map((doc, index) => (
                    <div key={index} className={`row font-lg m-0 p-0 ${index % 2 === 0 ? 'bg-light' : 'bg-white'}`}
                         style={{height: '82px', overflow: 'hidden'}}>
                        <div className={'col-3 row border-right border-bottom m-0 p-2'}>
                            <CLabel className={'col'}>
                                Parcela
                                0{doc.parcela} - {doc.dataVencimento}
                            </CLabel>
                        </div>

                        <div className={'col-3 ali row border-right border-bottom m-0 p-2'}>
                            <div className={'col-8 p-1 overflow-hidden'}>
                                <CLabel>
                                    {doc?.logMensagem}
                                </CLabel>
                            </div>
                            <div className={'col'}>
                                {doc && doc.logMensagem && (
                                    <CButton size={'sm'} onClick={() => {
                                        setShowModal(true);
                                        setIndexLogMensagem(index);
                                    }} className="col cursor-pointer" color="info">
                                        View
                                    </CButton>
                                )}
                                {doc.situacao == SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO_ERRO_ENVIO && (
                                    <div className={'mt-2'}>
                                        <CButton disabled={forcandoEnvio} size={'sm'} onClick={() => {
                                            forcarEnvio(index);
                                        }} className="col cursor-pointer" color="danger">
                                            Forçar Envio
                                        </CButton>
                                    </div>
                                )}
                                <CModal
                                    show={showModal}
                                    onClose={() => {
                                        setShowModal(!showModal)
                                    }}
                                    color="info"
                                    size={'xl'}
                                >
                                    <CModalHeader>
                                        <CModalTitle>Log de Erro</CModalTitle>
                                    </CModalHeader>
                                    <CModalBody>{documentoFatura[indexLogMensagem].logMensagem}</CModalBody>
                                </CModal>
                            </div>
                        </div>
                        <div className={'row col-3 border-bottom border-right m-0 p-2 pr-3'}>
                            {doc.isPago && doc.isPago ? (
                                <NumericFormat
                                    customInput={CInput}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    value={doc.valorPago || doc.valor || 'R$ 0,00'}
                                    className={`${doc.situacao == SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO_ERRO_ENVIO ? 'col-8' : 'col-10'}`}
                                    onBlur={(event: any) => handleChangeValorPago(event.target.value, index)}
                                    readOnly={doc.situacao === SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO}
                                    disabled={doc.situacao === SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO}
                                />
                            ) : (
                                <CLabel
                                    className={`${doc.situacao == SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO_ERRO_ENVIO ? 'col-8' : 'col-10'}`}>
                                    {numberDecimalFormatForm(doc.valor)}
                                </CLabel>
                            )}
                            <div className={'col-1'}>
                                {situacaoBadge(doc.situacao)}
                            </div>
                        </div>
                        <div className={'col valor-pago border-bottom m-0 p-2'}>
                            {doc.situacao == SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO || doc.situacao == SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO_ERRO_ENVIO ? numberDecimalFormatForm(doc.valorPago) : numberDecimalFormatForm(0)}
                        </div>

                        <div className={'col-1 border-bottom border-left m-0 p-2'}>
                            <CInputCheckbox
                                className='ml-2'
                                disabled={doc.situacao == SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO || doc.situacao == SituacaoFaturaDocumentoTypes.SITUACAO_CANCELADO || doc.situacao == SituacaoFaturaDocumentoTypes.SITUACAO_LIQUIDADO_ERRO_ENVIO}
                                checked={doc.isPago}
                                onChange={() => handleCheckboxChange(index)}
                            />
                        </div>

                    </div>
                ))}
            </div>
            <div className="mt-4 justify-content-center align-items-center" style={{height: '100vh'}}>
                <CCardBody className={'bg-white text-dark container'}>
                    <CRow className="justify-content-center text-center">
                        <CCol md="">
                            <CFormGroup>
                                <CLabel htmlFor="valorTotal">Valor Total</CLabel>
                                <NumericFormat
                                    customInput={CInput}
                                    prefix={"R$ "}
                                    type="text"
                                    id="valorTotal"
                                    readOnly
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={documentoFatura.reduce((acumulador: number, currentValue: any) =>
                                            acumulador + parseFloat(currentValue.valor || '0')
                                        , 0).toFixed(2)}
                                />
                            </CFormGroup>
                        </CCol>
                        <CCol md="">
                            <CFormGroup>
                                <CLabel htmlFor="saldoPendente">Saldo Pendente</CLabel>
                                <NumericFormat
                                    customInput={CInput}
                                    prefix={"R$ "}
                                    type="text"
                                    id="saldoPendente"
                                    readOnly
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={saldoPendente?.toFixed(2)}
                                />
                            </CFormGroup>
                        </CCol>
                        <CCol md="">
                            <CFormGroup>
                                <CLabel htmlFor="valorLiquidar">Valor à Liquidar</CLabel>
                                <NumericFormat
                                    customInput={CInput}
                                    prefix={"R$ "}
                                    type="text"
                                    id="valorLiquidar"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={saldoALiquidar.toFixed(2)}
                                    readOnly
                                />
                            </CFormGroup>
                        </CCol>
                        <CCol md=" " className=" mt-2 d-flex justify-content-center align-items-center">
                            {loading ?
                                <CButton className="btn btn-info" type="button" disabled>
                                    <span className="spinner-border mr-1 spinner-border-sm" role="status"
                                          aria-hidden="true"></span>
                                    Confirmar
                                </CButton>
                                :
                                <CButton className="btn btn-info" type="button" onClick={confirmarLiquidacao}>
                                    Confirmar
                                </CButton>
                            }
                        </CCol>
                    </CRow>
                </CCardBody>
            </div>
        </CForm>
    );
};

export default LiquidaBoleto;