import React, {useState, useEffect} from 'react';
import useCustomForm from "../../../services/useCustomForm";
import {
    CFormGroup,
    CInput,
    CButton,
    CLabel,
    CNav,
    CNavItem,
    CNavLink,
    CSelect,
    CFormText,
    CInputCheckbox,
    CCardText,
    CRow,
    CCard,
    CCardImgOverlay,
    CTextarea,
} from "@coreui/react";
import Form, {FormApi} from "../../../components/form";
import {useHistory, useParams} from "react-router-dom";
import {getApi} from "../../../apis/backendApis";

const OperationPropagateForm = () => {

    let history = useHistory();

    const initialValues: any = {
        "id": null,
        "testCases": [],
        "currentOperation": []
    };
    const [formApi, setFormApi] = useState<FormApi>();
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    let pathName = history.location.pathname;

    interface GetParam {
        id?: string
    }

    const {id} = useParams<GetParam>();
    let actionPost: string = '';

    const {
        values,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        setValues
    } = useCustomForm(
        {
            initialValues,
            route: 'operation/propagar',
            action: actionPost,
            onSubmit: values => submitValues(values.values),
            onLoadDadosCompleted: (values) => {

            }
        }
    );

    useEffect(() => {
        let api = getApi();
        api.get(`/operation/propagate/${id}`).then((response) => {
            setValues({...response.data});
        }).finally(() => {
        });
    }, [id]);

    const submitValues = (values: any) => {
        handleSubmitValues(values);
    }

    const handleFormReady = (formApi: FormApi) => {
        setFormApi(formApi);
    }

    const checkedItem = (indexTest : number, indexOperation : number) => {
        let current = values['testCases'][indexTest]['operations'][indexOperation]['checked']
        values['testCases'][indexTest]['operations'][indexOperation]['checked'] = !(current);
        setValues({...values, ['testCases']: values['testCases']});
    }

    const selectAll = () => {
        let newChecked = !(selectAllChecked);
        setSelectAllChecked(newChecked);
        let testCase = values['testCases'];
        testCase.forEach((testCase: any) => {
            testCase['operations'].forEach((operation : any) => {
                operation.checked = newChecked
            });
        });
        setValues({...values, ['testCases']: testCase});

    };


    return (<Form entity={'Propagação de Operação ' + id} handleSubmit={handleSubmit} onFormReady={handleFormReady}>
        <div className={'mb-4'}>
            <strong>Operação atual:</strong>
                <div className={'font-lg'}>Nome:
                <div className='ml-1 bg-light text-left text-muted font-lg' id="nome"
                     style={{width: "100%", height: "30px"}}>{values['currentOperation']['name']}</div>
                </div>
                <div className={'font-lg'}>Configuração:
                <div className='ml-1 bg-light text-left text-muted font-lg' id="nome"
                     style={{width: "100%", height: "30px"}}>{JSON.stringify(values['currentOperation']['params'])}</div>
                </div>
        </div>
            <div className={'row'}>
                <CButton className={'mb-3 ml-4 flex-row text-center'} onClick={selectAll} color={'primary'}>
                    {selectAllChecked ? 'Desmarcar Todos' : 'Selecionar Todos'}
                </CButton>
            </div>

            {values["testCases"].map((testCase: any, indexTest: number) => (
                <div key={indexTest} className={'execute-task-items col-md-12'}>
                    <div className={"border-left border-right border-top mb-3"}>
                        <div>
                            <CRow className="col-12 m-0 p-2 text-wrap bg-secondary">
                                <div>
                                    <strong>Caso de Teste:&nbsp;</strong>
                                    {testCase['codigo']} - {testCase['name']}
                                </div>
                            </CRow>
                            <CRow>
                                <div className="col-12">
                                    <div className="row font-weight-bold bg-light m-0">
                                        <div className={'col-1'}>
                                            &nbsp;
                                        </div>
                                        <div className={'p-2 col-1 border-right border-left'}>
                                            Ordem
                                        </div>
                                        <div className={'p-2 col-3 border-right'}>
                                            Nome
                                        </div>
                                        <div className={'p-2 col-7'}>
                                            Configuração
                                        </div>
                                    </div>
                                    {testCase['operations'].map((row: any, index: number) => (
                                        <div className="row border-bottom m-0 cursor-pointer" key={index}
                                             onClick={() => {
                                                 checkedItem(indexTest, index)
                                             }}>
                                            <div className={'col-1 d-flex align-items-center justify-content-center'}>
                                                <div className={'text-center'}>
                                                    <input className={'form-control'} type="checkbox"
                                                           checked={row['checked']}/>
                                                </div>
                                            </div>
                                            <div
                                                className={'p-2 col-1 d-flex align-items-center border-right border-left'}>
                                                {row['index'] + 1}
                                            </div>
                                            <div className={'p-2 col-3 d-flex align-items-center border-right'}>
                                                 {row['operation']['name']}
                                            </div>
                                            <div className={'p-2 col-7 d-flex align-items-center'}>
                                                <div>
                                                    {JSON.stringify(row['operation']['params'])}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </CRow>
                        </div>
                    </div>
                </div>

            ))}
        </Form>
    )
}
export default OperationPropagateForm;