import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';
import enumDescription from "../../../services/enumDescription";
import {TipoPessoaList} from "./index";

const PessoaList = (props:any) => {


    const actions: Array<ActionProps> = [
        {
            route:'pessoa/incluir',
            color:'success',
            type:'normal',
            title:'Incluir'
        },
        {
            route:'pessoa/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'pessoa/visualizar',
            color:'info',
            type:'grid',
            title:'Visualizar',
        },
        {
            route:'pessoa/delete',
            color:'danger',
            type:'delete',
            title:'Excluir',
        }
    ];

    return (
        <Grid actions={actions} rota="pessoa/list">
            <AgGridColumn sort="desc" headerName="#" checkboxSelection field="id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn flex={2} field="nome"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn flex={2} field="nomeFantasia"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn headerName="Tipo Pessoa" field="tipo" sortable={ true } filter={ FiltroTipoPessoa }  ></AgGridColumn>
            <AgGridColumn headerName="CPF/CNPJ" field="cpfCnpj" sortable={ true } filter={true}></AgGridColumn>
        </Grid>
    );
};
class FiltroTipoPessoa extends ListaMultiplaFilter{
    getList(): enumDescription {
        return TipoPessoaList;
    }
}

export default PessoaList;