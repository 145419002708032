import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import {booleanFormater} from '../../../components/gridUtils';
import { ActionProps } from '../../../components/gridAction';
import enumDescription from "../../../services/enumDescription";
import {AtivoList, AtivoTypes} from "./index";
import {GridApi} from "ag-grid-community";
import {SituacaoTypes} from "../../task";

const UsuarioList = (props:any) => {
    const  situacaoListFormater = (params:any) =>{
        return AtivoList.getDescription(params.value);
    }
    const actions: Array<ActionProps> = [
        {
            route:'usuario/incluir',
            color:'success',
            type:'normal',
            title:'Incluir'
        },
        {
            route:'usuario/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'usuario/visualizar',
            color:'info',
            type:'grid',
            title:'Visualizar',
        },
        {
            route:'usuario/delete',
            color:'danger',
            type:'delete',
            title:'Excluir',
        }
    ];

    const handleGridReady = (gridApi:GridApi)=>{
        const filterInstance = gridApi.getFilterInstance('ativo');
        const model = filterInstance?.getModel();
        filterInstance?.setModel({
            type: 'in',
            value: [AtivoTypes.SIM]
        });
        gridApi.onFilterChanged();
    }
    
    return (
        <Grid actions={actions} rota="usuario/list" handleGridReady={handleGridReady} >
            <AgGridColumn sort="desc" headerName="#" checkboxSelection field="id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn flex={2} field="nome"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn flex={2} field="email" sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn flex={2} headerName={'Idade da Senha'} field="idadeSenha" sortable={ false } filter={ false }></AgGridColumn>
            <AgGridColumn field="ativo" sortable={ true } filter={ FiltroAtivo } valueFormatter={situacaoListFormater} ></AgGridColumn>
        </Grid>
    );
};
class FiltroAtivo extends ListaMultiplaFilter{
    getList(): enumDescription {
        return AtivoList;
    }
}
export default UsuarioList;