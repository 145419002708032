import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../components/grid';
import { ActionProps } from '../../components/gridAction';
import enumDescription from "../../services/enumDescription";
import {SituacaoList, SituacaoTypes, TiposErrosList} from './index';
import {GridApi} from "ag-grid-community";

const LogList = (props:any) => {

    const situacaoListFormater  = (params:any) =>{
        return SituacaoList.getDescription(params.value);
    }

    const handleGridReady = (gridApi:GridApi)=>{
        const filterInstance = gridApi.getFilterInstance('situacao');
        const model = filterInstance?.getModel();
        filterInstance?.setModel({
            type: 'in',
            value: [SituacaoTypes.EM_EXECUCAO, SituacaoTypes.PENDENTE]
        });
        gridApi.onFilterChanged();
    }

    const actions: Array<ActionProps> = [
        {
            route:'log/visualizar',
            color:'info',
            type:'grid',
            title:'Detalhes',
        }
    ];

    const defaultColumnDefinition = {
        resizable: true,
        flex: 1,
        filter: true,
        sortable: true
    };

    return (
        <Grid actions={actions} rota="log/list" handleGridReady={handleGridReady} defaultColDef={defaultColumnDefinition}>
            <AgGridColumn minWidth={200} headerName="Data/Hora" field="createAt" filter={'agDateColumnFilter'} sort={'desc'}></AgGridColumn>
            <AgGridColumn minWidth={250} headerName="Database" field="database"></AgGridColumn>
            <AgGridColumn minWidth={100} headerName="Cliente" field="cliente/nome"></AgGridColumn>
            <AgGridColumn minWidth={400} headerName="Erro" field="error"></AgGridColumn>
            <AgGridColumn minWidth={120} headerName="Tipo" field="tipo/id" filter={FiltroTiposErros} valueFormatter={FiltroTiposErrosFormatter}></AgGridColumn>
            <AgGridColumn minWidth={50} headerName="Código" field="identificador"></AgGridColumn>
            <AgGridColumn minWidth={50} headerName="#Usuário" field="usuario/id"></AgGridColumn>
            <AgGridColumn minWidth={100} headerName="Usuário" field="usuario/nome"></AgGridColumn>
        </Grid>
    );
};

class FiltroSituacao extends ListaMultiplaFilter{
    getList(): enumDescription {
        return SituacaoList;
    }
}

class FiltroTiposErros extends ListaMultiplaFilter{
    getList(): enumDescription {
        return TiposErrosList;
    }
}

const FiltroTiposErrosFormatter  = (params:any) =>{
    return TiposErrosList.getDescription(params.value);
}
export default LogList;