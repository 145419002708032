import {useDispatch} from "react-redux";
import {hideLoading,showLoading} from "../store/ducks/loading/actions"

const useLoading = () =>{
    const dispatch = useDispatch();

    const setLoading = (loading:boolean)=>{
        loading ? dispatch(showLoading()) : dispatch(hideLoading())
    }

    return [setLoading];
}

export default useLoading