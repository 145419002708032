import {ContentRoute} from "../../models/Route";
import ClientStatus from "./clientStatus";

export const listRegimeTributacaoFiltro : any = [
    {label: 'Simples Nacional', value:'1'},
    {label: 'Simples Nacional, excesso sublimite de receita bruta', value:'2'},
    {label: 'Regime Normal', value:'3'}
];

export const listConfiguracaoAtivaFiltro : any = [
    {label: 'Ativo', value:'1'},
    {label: 'Inativo', value:'0'}

];

export const listCheckoutConfig : any = [
    {label: 'v1-Versão Twig', value:'default'},
    {label: 'v2-Versão React / SPA', value:'react'}

];

const client_status_routes: Array<ContentRoute> = [
    {
        'path':'/client-status',
        'description': 'Status de Clientes',
        'exact':true,
        'component': ClientStatus,
        'menu': true
    }
]

export default client_status_routes;