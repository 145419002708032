import { Auth } from "../models/Auth";
import {retrieveToken, saveToken } from '../services/tokenVerify';
import api from "../apis/backendApis";
import {loggingIn, loggingOff, retrieving} from "../store/ducks/login/actions";
import {Dispatch} from "redux";

export function loadAuthDataFromToken() {
    return (dispatch:Dispatch) => {
        dispatch(retrieving());

        let token =  retrieveToken();

        if(token == ''){
            dispatch(loggingOff());
            return;
        }

        api.get<Auth>(`/auth/retrieve/${token}`).then(response =>{
            dispatch(loggingIn(response.data));
        }).catch(reason =>{
            loggingOff();
            dispatch(loggingOff());
        });
    };
}