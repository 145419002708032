import React, { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
// @ts-ignore
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';

// @ts-ignore
window.Quill = Quill
Quill.register('modules/imageResize', ImageResize);

function RichTextEditor(props: any) {
    return <ReactQuill
        theme="snow"
        modules={
            {
                toolbar: [
                    //[{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    //[{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'},
                        {'indent': '-1'}, {'indent': '+1'}],
                    ['link', 'image'], // 'video'
                    ['clean']
                ],
                clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                },
                imageResize: {
                    parchment: Quill.import('parchment'),
                    modules: ['Resize', 'DisplaySize']
                }
            }
        }
        formats={
            [
                //'header', 'font', 'size',
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet', 'indent',
                'link', 'image', 'width', 'height'//'video'
            ]
        }
        {...props} />;
}

export default RichTextEditor;