import React, {useEffect, useState} from 'react';
import {CForm, CCard, CCardBody, CCardFooter, CCardHeader, CButton} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import Alert from "./alert";
import {useAlertVisible} from '../components/alert';
import useCurrentAction from '../services/useCurrentAction';
import {useHistory} from "react-router-dom";

interface FormProps {
    entity : string,
    handleSubmit : (event: any)=>void,
    onFormReady?: (formApi:FormApi)=>void,
    header?: any,
    footer?: any
}

export interface FormApi {
    //showAlert: (type:'success'|'alert'|'danger', msg: string, title?:string)=>void,
}

type Props = CForm & FormProps;

const Form = (props : Props)=>{
    const history = useHistory();
    const action = useCurrentAction();


    useEffect(()=>{
        const formAPi = {
            //Por enquanto não tem função nenhuma
        }

        if(props.onFormReady){
            props.onFormReady(formAPi);
        }
    },[])

    const isView = () =>{
        return action === 'view';
    }

    const getTitulo = ()=>{
        let acao = '';
        switch (action) {
            case 'edit':
                acao = 'Alterar';
                break;
            case 'view':
                acao = 'Visualizar';
                break;
            default:
                acao = 'Incluir';
                break;
        }
        return `${acao} ${props.entity}`;
    }

    const handleCancelar = () =>{
        history.goBack();
    }

    const {handleSubmit, onFormReady, ...rest} = props;

    return (
        <CForm  onSubmit={handleSubmit} {...rest} action="">
            <CCard>
                {!props.header && (
                    <CCardHeader>
                        {getTitulo()}
                    </CCardHeader>
                )}
                {props.header && props.header}
                <CCardBody>
                    {props.children}
                </CCardBody>
                {props.footer && props.footer}
                {!props.footer && (
                    <CCardFooter hidden={isView()}>
                        <CButton type="submit" size="sm" color="primary">
                            <div className={'d-flex align-items-center flex-row'}>
                                <div><CIcon name="cil-save"/></div>
                                <div>&nbsp;Confirmar</div>
                            </div>
                        </CButton>
                        &nbsp;
                        <CButton className={'btn'} onClick={() => handleCancelar()} type="reset" size="sm"
                                 color="danger">
                            <div className={'d-flex align-items-center flex-row'}>
                                <div><CIcon name="cil-ban"/></div>
                                <div>&nbsp;Cancelar</div>
                            </div>
                        </CButton>
                    </CCardFooter>
                )}
            </CCard>
        </CForm>
    )
}

export default Form;