import {ContentRoute} from "../../../models/Route";
import enumDescription from "../../../services/enumDescription";
import groupTesteList from "./groupTesteList";
import groupTesteForm from "./groupTesteForm";
import {TypeOperationList} from "../operation";



export enum TypeGroupTest {
    TESTE_CASE = 1,
    EXE_TESTE = 2
}

export const TypeGroupTestListUse = [
    {   value: TypeGroupTest.TESTE_CASE, description: 'Casos de Teste'},
    {   value: TypeGroupTest.EXE_TESTE, description: 'Execução Teste'}
];

// @ts-ignore
export const TypeGroupTestListt = new enumDescription( TypeGroupTestListUse );

export const TypeGroupTestList : any = [
    {label: 'Casos de Teste', value:1},
    {label: 'Execução Teste', value:2},
];

export const group_teste_content_routes: Array<ContentRoute> = [
    {
        'path':'/group-teste',
        'description': 'Grupo de Testes',
        'exact':true,
        'component': groupTesteList,
        'menu': true
    },
    {
        'path':'/group-teste/incluir',
        'description': 'Novo Grupo de Testes',
        'exact':true,
        'component': groupTesteForm,
        'menu': false
    },
    {
        'path':'/group-teste/alterar/:id',
        'description': 'Alterar Grupo de Testes',
        'exact':true,
        'component': groupTesteForm,
        'menu': false
    },

]