import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {CInput} from "@coreui/react";

const CpfCnpj = (props:CInput) => {
    const TYPES = {
        CPF: "999.999.999-999",
        CNPJ: "99.999.999/9999-99",
    };
    const MAX_LENGTH = clear(TYPES.CNPJ).length;

    let valueProps = props.value;

    const [value,setState] = useState(clear(valueProps));

    useEffect(()=>{
        let localValue = clear(props.value);
        const mask = getMask(localValue);

        let nextLength = localValue.length;

        if (nextLength > MAX_LENGTH) return;

        let localState = applyMask(localValue, TYPES[mask]);
        setState(localState);

    },[props.value]);

    const { onChange, type } = props;

    function onLocalChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let localValue = clear(ev.target.value);

        let nextLength = localValue.length;

        if (nextLength > MAX_LENGTH) return;

        if(onChange){
            onChange(ev);
        }
    }

    function getMask(value:string) {
        return value.length > 11 ? "CNPJ" : "CPF";
    }

    function applyMask(value:string, mask:string) {
        let result = "";

        let inc = 0;
        Array.from(value).forEach((letter, index) => {
            if (!mask[index + inc].match(/[0-9]/)) {
                result += mask[index + inc];
                inc++;
            }
            result += letter;
        });
        return result;
    }

    function clear(value?:string | ReadonlyArray<string> | number) {
        return value &&  typeof value == 'string' ? value.replace(/[^0-9]/g, ""):'';
    }

    return (
        <CInput {...props} type={type} value={value} onChange={onLocalChange} />
    );
};

CpfCnpj.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

CpfCnpj.defaultProps = {
    type: "tel",
    value: "",
    onChange: () => {},
};

export default CpfCnpj;