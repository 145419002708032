import {ContentRoute} from "../../../models/Route";
import ExecuteTaskList from "./executeTaskList";
import ExecuteTaskForm from "./executeTaskForm";
import enumDescription from "../../../services/enumDescription";
import ExecuteTaskAnalysisForm from "./executeTaskAnalysisForm";

export enum TypeExecuteTask {
    TYPE_TEST_USER   = 1,
    TYPE_JOB = 2
}

export const TypeExecuteTaskList = new enumDescription(  [
    {   value: TypeExecuteTask.TYPE_TEST_USER, description: 'Execução Manual'    },
    {   value: TypeExecuteTask.TYPE_JOB, description: 'Execução Programada (Job)'    }
]);

export enum StatusExecuteTask {
    STATUS_IN_CONFIGURATION = 1,
    STATUS_WAITING = 2,
    STATUS_EXECUTING = 3,
    STATUS_FINISH = 4
}

export const StatusExecuteTaskList = new enumDescription(  [
    {value: StatusExecuteTask.STATUS_IN_CONFIGURATION, description: 'Configurando'},
    {value: StatusExecuteTask.STATUS_WAITING, description: 'Aguardando Execução'},
    {value: StatusExecuteTask.STATUS_EXECUTING, description: 'Executando'},
    {value: StatusExecuteTask.STATUS_FINISH, description: 'Finalizado'}
]);

export enum TypeExecuteTaskResult {
    STATUS_EXECUTANDO = 1,
    STATUS_FINALIZADO = 2
}

export const TypeExecuteTaskResultList = new enumDescription(  [
    {   value: TypeExecuteTaskResult.STATUS_EXECUTANDO, description: 'Executando'},
    {   value: TypeExecuteTaskResult.STATUS_FINALIZADO, description: 'Finalizado'}
]);


export const execute_task_content_routes: Array<ContentRoute> = [
    {
        'path':'/execute-task',
        'description': 'Execuções de Testes',
        'exact':true,
        'component': ExecuteTaskList,
        'menu': true
    },
    {
        'path':'/execute-task/incluir',
        'description': 'Novo Plano de Execução',
        'exact':true,
        'component': ExecuteTaskForm,
        'menu': false
    },
    {
        'path':'/execute-task/alterar/:id',
        'description': 'Alterar Plano de Execução',
        'exact':true,
        'component': ExecuteTaskForm,
        'menu': false
    },
    {
        'path':'/execute-task/execute/:id',
        'description': 'Execução e Resultados',
        'exact':true,
        'component': ExecuteTaskAnalysisForm,
        'menu': false
    }
]