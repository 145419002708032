import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../components/grid';
import { ActionProps } from '../../components/gridAction';
import enumDescription from "../../services/enumDescription";
import {SituacaoList, SituacaoTypes} from './index';
import {GridApi} from "ag-grid-community";

const TaskList = (props:any) => {

    const situacaoListFormater  = (params:any) =>{
        return SituacaoList.getDescription(params.value);
    }

    const handleGridReady = (gridApi:GridApi)=>{
        const filterInstance = gridApi.getFilterInstance('situacao');
        const model = filterInstance?.getModel();
        filterInstance?.setModel({
            type: 'in',
            value: [SituacaoTypes.EM_EXECUCAO, SituacaoTypes.PENDENTE]
        });
        gridApi.onFilterChanged();
    }

    const actions: Array<ActionProps> = [
        {
            route:'task/incluir',
            color:'success',
            type:'normal',
            title:'Incluir'
        },
        {
            route:'task/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'task/visualizar',
            color:'info',
            type:'grid',
            title:'Visualizar',
        },
        {
            route:'task/cancel',
            color:'danger',
            type:'post',
            validate: (rows)=>{
                return !rows.find(row=>(row.situacao != SituacaoTypes.PENDENTE)) ;
            },
            title:'Cancelar',
        },
        {
            route:'task/finish',
            color:'success',
            type:'post',
            validate: (rows)=>{
                return !rows.find(row=>(row.situacao != SituacaoTypes.EM_EXECUCAO)) ;
            },
            title:'Finalizar'
        },
        {
            route:'task/executar',
            color:'info',
            type:'grid',
            title:'Executar'
        },
    ];

    return (
        <Grid actions={actions} rota="task/list" handleGridReady={handleGridReady}>
            <AgGridColumn width={100} headerName="#" checkboxSelection field="id"  sortable={ true } sort={'desc'} filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn flex={1} minWidth={100} headerName="Cliente" field="pessoaCliente/nome"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn flex={1} minWidth={100} headerName="Gestor do Projeto" field="pessoaUsuario/nome"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn flex={2} minWidth={200} headerName="Descrição" field="descricao"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn flex={1} minWidth={100} headerName="% Conclusão" field="progresso"  filter={ 'agNumberColumnFilter'} sortable={ true } ></AgGridColumn>
            <AgGridColumn width={170} headerName="Situação"  field="situacao"  sortable={ true } filter={ FiltroSituacao } valueFormatter={situacaoListFormater}></AgGridColumn>
        </Grid>
    );
};

class FiltroSituacao extends ListaMultiplaFilter{
    getList(): enumDescription {
        return SituacaoList;
    }
}

export default TaskList;