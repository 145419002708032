import { CButton } from "@coreui/react"
import { GridApi } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export interface ActionProps {
    type: 'normal' | 'grid' | 'post' | 'get' | 'delete' | 'custom' ,
    route: string,
    title:string,
    color?:string,
    gridApi?:GridApi,
    rowParams?:Object[],
    fixedParams?:Object[],
    callBackFunction?:Function,
    onClickFunction?:(rows:any[], route:string)=>void,
    validate?:(rows:any[])=>boolean,
    openInNewTab?: boolean,
}

type Props = ActionProps & CButton

const GridAction  = (props:Props) =>{
    const [gridApiInterno, setGridApi] = useState<GridApi>();

    useEffect(()=>{
        setGridApi(
            (prevApi) => props.gridApi
        )
    }, [props.gridApi]);

    const history = useHistory();

    const onClickHandle = () =>{
        if (props.openInNewTab) {
            let id = gridApiInterno?.getSelectedRows()[0].id;
            window.open('#' + props.route+`/${id}`, '_blank');
            return;
        }
        switch(props.type){
            case 'normal':
                history.push(props.route);
            break;
            case 'grid':
                let id = gridApiInterno?.getSelectedRows()[0].id;
                history.push(props.route+`/${id}`);
            break;
            case 'post':
            case 'delete':
                if(gridApiInterno?.getSelectedRows() && props.onClickFunction){
                    let rows = gridApiInterno?.getSelectedRows();
                    props.onClickFunction(rows, props.route);
                }
            break;
        }
    }

    let {gridApi , validate, onClickFunction,openInNewTab, ...rest} = props;

    return (
        <CButton onClick={onClickHandle} {...rest}>
            {props.children}
        </CButton>
    )
}

export default GridAction