import React, {useState, useEffect} from 'react';
import useCustomForm from "../../../services/useCustomForm";
import {
    CFormGroup,
    CInput,
    CButton,
    CLabel, CNav, CNavItem, CNavLink, CSelect
} from "@coreui/react";
import Form, {FormApi} from "../../../components/form";
import {TypeOperationList, TypeTermList, TypeOperation} from "./index";
import {useHistory} from "react-router-dom";

const OperationForm = ()=> {

    let history = useHistory();

    const initialValues:any = {
        id: null,
        name: '',
        params: {
            type: '',
            value: '',
            data: '',
            type_term: ''
        }
    }
    const [formApi, setFormApi] = useState<FormApi>();
    let pathName = history.location.pathname;
    let  actionPost : string = '';
    const isDuplicar : boolean = pathName.toString().includes('duplicar');
    if(isDuplicar){
        actionPost =  'alterar/duplicar'
    }
    const {
        values,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        setValues
    } = useCustomForm(
        {
            initialValues,
            route:'operation',
            action: actionPost,
            onSubmit: values => submitValues(values.values),
            onLoadDadosCompleted: (values) => {
                if(isDuplicar){
                    values.name   = 'CÓPIA DE ' + values.name;
                }
                setValues(values);
            }
        }
    );

    const submitValues = (values : any) => {
        handleSubmitValues(values);
    }

    const handleFormReady = (formApi:FormApi)=>{
        setFormApi(formApi);
    }

    const handleChangeParam = (event : any) => {
        values.params[event.target.name] = event.target.value;
        setValues({ ...values, ['params']: values.params });
    }


    return (<Form entity={'Operação de Teste ' + values.id} handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CFormGroup>
                <CLabel className={'text-danger'} htmlFor="name">Nome Operação</CLabel>
                <CInput type="text" id="name" name="name" maxLength={100} onChange={handleChange} value={values.name} required/>
            </CFormGroup>
            <CFormGroup>
                <CLabel className={'text-danger'} htmlFor="type">Tipo de Operação</CLabel>
                <CSelect name="type" id="type" onChange={handleChangeParam} value={values['params'].type} required>
                    <option value=''>Selecionar...</option>
                    {TypeOperationList.map(item=>(
                        <option key={item.value} value={item.value}>{item.description}</option>
                    ))}
                </CSelect>
            </CFormGroup>
            <fieldset>
                <legend>Parâmetros</legend>
                { values['params'].type.length > 0
                    && values['params'].type != TypeOperation.TYPE_CALL_URL
                    && values['params'].type != TypeOperation.TYPE_EXECUTE_SCRIPT
                    && values['params'].type != TypeOperation.TYPE_WAIT && (
                    <>
                        <CFormGroup>
                            <CLabel htmlFor="type_term">Tipo de Busca</CLabel>
                            <CSelect name="type_term" id="type_term" onChange={handleChangeParam} value={values['params'].type_term}>
                                <option value=''>Selecionar...</option>
                                {TypeTermList.map(item=>(
                                    <option key={item.value} value={item.value}>{item.description}</option>
                                ))}
                            </CSelect>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="value">Termo de Busca</CLabel>
                            <CInput type="text" id="value" name="value" maxLength={100} onChange={handleChangeParam} value={values['params'].value} />
                        </CFormGroup>
                    </>
                )}
                <CFormGroup>
                    <CLabel htmlFor="data">Parâmetro/Valor para Operação</CLabel>
                    <CInput type="text" id="data" name="data" maxLength={100} onChange={handleChangeParam} value={values['params'].data} />
                </CFormGroup>
            </fieldset>

        </Form>)
}
export default OperationForm;