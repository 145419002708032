import {getApi} from "../apis/backendApis";
import {AxiosResponse, Method} from "axios";

interface SubmitValuesParams {
    route: string,
    values: any,
    action?: string,
    files?:File[]
}

const submitValues = ({
 route, values, action, files
}:SubmitValuesParams):Promise<AxiosResponse> =>{
    let api = getApi()
    let valuesString =  JSON.stringify(values);
    let formData = new FormData();

    formData.append('data', valuesString);
    if(files){
        let fileNames:string[] = Object.keys(files);
        fileNames.forEach((fileName:any)=>{
            formData.append(fileName, files[fileName]);
        })
    }

    let url = `${route}/`;
    let actionPost = '';
    if(action){
        actionPost = action;
    }

    url += actionPost;
    let method:Method = 'post';

    return api.request({
        url,
        method,
        data: formData,
        headers:{ 'Content-Type': 'multipart/form-data'}});
    }

export default submitValues;