import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Redirect, useLocation, useHistory, Link, RouterProps } from 'react-router-dom';
import {
    CButton,
    CHeader,
    CHeaderNav,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {clearToken} from "../../services/tokenVerify";
import {loggingOff} from "../../store/ducks/login/actions";
import {States} from "../../store/ducks/rootReducer";
import Breadcrumb from './breadCrumb';

const TheHeader = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const loginState = useSelector((state:States)=> state.login);
    const [showRollback, setShowRollback] = useState(false);

    useEffect(()=>{
        setShowRollback(location.pathname  !== '/');
    },[location])

    const logout = () => {
        dispatch(loggingOff());
        clearToken();
        return (<Redirect to="/login"/>);
    }

    const rollback = ()=>{
        history.goBack();
    }

    return (
        <CHeader>
            <CHeaderNav className="px-3 ">
                {showRollback && (
                    <CButton size={'sm'} className={"m-0 p-0"} onClick={()=>{history.goBack();}}>
                        <CIcon name="cil-arrow-left" className="m-3" />
                    </CButton>
                )}
            </CHeaderNav>
            <CHeaderNav className="d-md-down-none mr-auto">
                <Breadcrumb />
            </CHeaderNav>
            <CHeaderNav className="px-3">
                Usuário: {loginState.data?.user.name}
                <a href="#" onClick={logout}>
                    <CIcon name="cil-account-logout" className="mx-2"/>
                </a>
            </CHeaderNav>
        </CHeader>
    )
}

// @ts-ignore
export default TheHeader;