import React from 'react';
import Routes from './routes';
import './scss/style.scss';
import store from '../src/store';
import {Provider} from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

function App() { 
  return (   
    <Provider store = {store}>
      <Routes />
    </Provider>
  );
}

export default App;