import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

const useCurrentAction = () =>{

    const [action, setAction] = useState<string>();

    let history = useHistory();
    let location = useLocation();

    useEffect(()=>{
        let theLocation = location.pathname;
        let action = 'add';
        let isAlteracao = theLocation.split('/').includes('alterar');
        let isVisualizacao = theLocation.split('/').includes('visualizar');
        if(isAlteracao){
            action = 'edit';
        } else if (isVisualizacao){
            action = 'view';
        }

        setAction(action);

    }, [location]);

    return action;
};

export default useCurrentAction;