import {usuario_content_routes } from './usuario';
import {pessoa_content_routes} from './pessoa';
import {cliente_content_routes} from './cliente';
import {checklist_content_routes} from './checklist';
import {ContentRoute} from "../../models/Route";
import {setor_content_routes} from "./setor";

const routes: Array<ContentRoute> = usuario_content_routes
                                    .concat(pessoa_content_routes)
                                    .concat(cliente_content_routes)
                                    .concat(checklist_content_routes)
                                    .concat(setor_content_routes)

export default routes;