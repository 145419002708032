import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';

const TestCaseList = (props:any) => {

    const actions: Array<ActionProps> = [
        {
            route:'test-case/incluir',
            color:'success',
            type:'normal',
            title:'Incluir'
        },
        {
            route:'test-case/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'test-case/delete',
            color:'danger',
            type:'delete',
            title:'Excluir'
        },
        {
            route:'test-case/alterar/duplicar',
            color:'primary',
            type:'grid',
            title:'Duplicar'
        }
    ];

    return (
        <Grid actions={actions} rota="test-case/list">
            <AgGridColumn sort="desc" width={100} headerName="#" checkboxSelection field="id"  sortable={ true } filter={'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn minWidth={150} field="codigo" sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn flex={1} minWidth={200} headerName={"Nome"} field="name"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn flex={1} minWidth={200} headerName="Grupo de Teste" field="groupTest/name"  sortable={ true } filter={ true }></AgGridColumn>
        </Grid>
    );
};
export default TestCaseList;