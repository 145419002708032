import React, {useState} from 'react';
import useCustomForm from '../../../services/useCustomForm'
import {
    CFormGroup,
    CLabel,
    CInput,
    CInputCheckbox,
    CCol, CSelect,
} from '@coreui/react';
import {ChecklistFormData, SetorList} from "./index";
import Form, {FormApi} from "../../../components/form";
import useCurrentAction from "../../../services/useCurrentAction";

const ChecklistForm = ()=>{

    const [formApi, setFormApi] = useState<FormApi>();
    const action = useCurrentAction();

    const initialValues:ChecklistFormData = {
        nome:'',
        setor:-1,
        ordem:1,
        ativo:true,
        versao:1,
    }

    const {
        values,
        handleChange,
        handleSubmit,
        handleSubmitValues,
    } = useCustomForm(
        {
            initialValues,
            route:'checklist',
            onSubmit: values => handleSubmitValues(values.values),
        }
    );

    const isView = () => {
        return action === 'view';
    };

    const handleFormReady = (formApi:FormApi)=>{
        setFormApi(formApi);
    }

    return (
        <Form entity="Checklist" handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CFormGroup>
                <CLabel htmlFor="nome">Nome</CLabel>
                <CInput readOnly={isView()} disabled={isView()} type="text" id="nome" name="nome" maxLength={120} onChange={handleChange} value={values.nome} required/>
            </CFormGroup>
            <CFormGroup>
                <CLabel htmlFor="setor">Setor</CLabel>
                <CSelect readOnly={isView()} disabled={isView()} name="setor" id="setor"  onChange={event=>handleChange(event)} value={values.setor}>
                    {SetorList.getList(true).map(item=>(
                        <option key={item.value} value={item.value}>{item.description}</option>
                    ))}
                </CSelect>
            </CFormGroup>
            <CFormGroup className="row">
                <CCol className="col-lg-6 col-sm-12">
                    <CLabel htmlFor="ordem">Ordem</CLabel>
                    <CInput readOnly={isView()} disabled={isView()} type="number" id="ordem" name="ordem" step={1} onChange={handleChange} value={values.ordem} required/>
                </CCol>
                <CCol className="col-lg-6 col-sm-12" >
                    <CLabel htmlFor="versao">Versão</CLabel>
                    <CInput readOnly={isView()} disabled={isView()} type="number"  id="versao" name="versao" onChange={handleChange} value={values.versao} required/>
                </CCol>
            </CFormGroup>
            <CFormGroup row>
                <CCol className="offset-1 custom-switch">
                    <CInputCheckbox
                        id="ativo"
                        name="ativo"
                        checked={values.ativo}
                        onChange={handleChange}
                        readOnly={isView()}
                        disabled={isView()}

                    />
                    <CLabel variant="checkbox" htmlFor="ativo" >Ativo</CLabel>
                </CCol>
            </CFormGroup>
        </Form>
    )
}

export default ChecklistForm;