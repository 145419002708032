import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../components/grid';
import { ActionProps } from '../../components/gridAction';
import enumDescription from "../../services/enumDescription";
import {NotificacaoLogSituacaoList} from './index';
import {GridApi} from "ag-grid-community";
import {useParams} from "react-router-dom";

const NotificacaoLogList = (props:any) => {

    /*const situacaoListFormater  = (params:any) =>{
        return SituacaoList.getDescription(params.value);
    }*/

    let id : any = useParams();

    const handleGridReady = (gridApi:GridApi)=>{


        /*
        const filterInstance = gridApi.getFilterInstance('situacao');
        const model = filterInstance?.getModel();
        filterInstance?.setModel({
            type: 'in',
            value: [SituacaoTypes.EM_EXECUCAO, SituacaoTypes.PENDENTE]
        });
        gridApi.onFilterChanged();*/
    }

    const actions: Array<ActionProps> = [];

    const defaultColumnDefinition = {
        resizable: true,
        flex: 1,
        filter: true,
        sortable: true
    };

    return (
        <Grid actions={actions} rota={"notificacaoLog/list/" + id.id} handleGridReady={handleGridReady} defaultColDef={defaultColumnDefinition}>
            <AgGridColumn headerName="#" field="id" ></AgGridColumn>
            <AgGridColumn minWidth={100} headerName="Data/Hora" field="dataHora" ></AgGridColumn>
            <AgGridColumn minWidth={50} headerName="#Cliente" sortable={false} field="cliente_id" ></AgGridColumn>
            <AgGridColumn minWidth={150} headerName="Cliente" field="cliente" ></AgGridColumn>
            <AgGridColumn minWidth={100} headerName="Situação" field="situacao" filter={ FiltroSituacao } valueFormatter={situacaoListFormater} ></AgGridColumn>
            <AgGridColumn minWidth={100} headerName="Data/Hora Lida" field="dataHoraLida" ></AgGridColumn>
        </Grid>
    );
};

class FiltroSituacao extends ListaMultiplaFilter{
    getList(): enumDescription {
        return NotificacaoLogSituacaoList;
    }
}

const situacaoListFormater  = (params:any) =>{
    return NotificacaoLogSituacaoList.getDescription(params.value);
}

const formatterHtml = (params:any) => {
    return params.value;

}

export default NotificacaoLogList;