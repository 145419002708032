import {ContentRoute} from "../../models/Route";
import ProgramacaoAgendamento from "./programacaoAgendamento";


export enum SituacaoAgendamento {
    SITUACAO_ABERTO = 1,
    SITUACAO_FECHADO = 2,
}

const programacaoAgendaRouter: Array<ContentRoute> = [
    {
        'path':'/programacao',
        'description': 'Programação',
        'exact':true,
        'component': ProgramacaoAgendamento,
        'menu': false
    }
]

export default programacaoAgendaRouter;