import React, {useState, useEffect} from 'react';
import useCustomForm from "../../services/useCustomForm";
import {CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs} from "@coreui/react";
import AceEditor from "react-ace-builds";

const LogForm = ()=>{
    const initialValues:any = {}

    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        handleChangeSuggest,
        handleClickMinus,
        handleClickPlus,
    } = useCustomForm({
            initialValues,
            route:'log',
            onSubmit: values => {
                console.log('submit');
            },
            onLoadDadosCompleted: data=>onLoadCompleted(data),
        },
    );

    const onLoadCompleted = (data:any) =>{
        setValues({...data});
    }

    return (<div className={'w-100'}>
        <CTabs>
            <CNav variant="tabs">
                <CNavItem>
                    <CNavLink>
                        Geral
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink>
                        Trace
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink>
                        Info
                    </CNavLink>
                </CNavItem>
            </CNav>
            <CTabContent>
                <CTabPane>
                    <div className={'row'}>
                        <div className={'col-sm-2 text-right font-weight-bold'}>Database:</div>
                        <div className={'col-sm-10'}>{values.database}</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-sm-2 text-right font-weight-bold'}>Data/Hora:</div>
                        <div className={'col-sm-10'}>{values.dataHora}</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-sm-2 text-right font-weight-bold'}>Code:</div>
                        <div className={'col-sm-10'}>{values.code}</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-sm-2 text-right font-weight-bold'}>Line:</div>
                        <div className={'col-sm-10'}>{values.line}</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-sm-2 text-right font-weight-bold'}>Empresa:</div>
                        <div className={'col-sm-10'}>{values?.empresa?.id} - {values?.empresa?.nome}</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-sm-2 text-right font-weight-bold'}>Usuário:</div>
                        <div className={'col-sm-10'}>{values?.usuario?.id} - {values?.usuario?.nome}</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-sm-2 text-right font-weight-bold'}>Erro:</div>
                        <div className={'col-sm-10'}>
                            <div className={'w-100 h-50'}>
                                <textarea rows={20} className={'form-control w-100'} value={values.error} readOnly={true}></textarea>
                            </div>
                        </div>
                    </div>
                </CTabPane>
                <CTabPane>
                    <div className={'row p-3'}>
                        <textarea rows={30} className={'form-control w-100'} readOnly={true} value={values.trace}></textarea>
                    </div>
                </CTabPane>
                <CTabPane>
                    <div className={'p-3 w-100 h-100'}>
                        {values.info && <AceEditor
                            mode="json"
                            theme="github"
                            showGutter={true}
                            showPrintMargin={true}
                            highlightActiveLine={false}
                            width={''}
                            fontSize={12}
                            value={JSON.stringify(values.info,null, 2)}
                            setOptions={{
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: false,
                                enableSnippets: false,
                                showLineNumbers: true,
                                tabSize: 2,
                            }}
                            name="UNIQUE_ID_OF_DIV"
                        />}
                    </div>
                </CTabPane>
            </CTabContent>
        </CTabs>
    </div>)
}
export default LogForm;