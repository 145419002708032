import React, {useState} from 'react';
import useCustomForm from '../../../services/useCustomForm'
import {CButton, CCard, CFormGroup, CInput, CInputCheckbox, CLabel, CSelect, CTextarea} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Form, {FormApi} from "../../../components/form";
import useCurrentAction from "../../../services/useCurrentAction";
import {TipoCobrancaList, TipoConfigTypes} from "./index";
import {useParams} from "react-router-dom";
import {NumericFormat, numericFormatter} from 'react-number-format';
import Select from "react-select";


const ObjetoContratoForm = () => {

    const [formApi, setFormApi] = useState<FormApi>();
    const action = useCurrentAction();

    interface GetParam {
        id?: string
    }

    const {id} = useParams<GetParam>();
    const initialValues = {
        nome: '',
        codigo: '',
        descricao: '',
        finalidade: '',
        valorSetup: '',
        tipoConfiguracao: '',
        configuracao: {},
        ativo: true,
        type: '',
    };
    const initialValuesConfiguracaoComissao = [{
        valorFaturamento: '',
        percentualLoja: '',
        percentualMarketplace: '',
        comissaoB2B: ''
    }];
    const initialValuesConfiguracaoComissaoMin = [{
        valorMinimo: ''
    }];
    const initialValuesConfiguracaoSobDemanda = [{
        quantidade: '',
        precoUnidade: ''
    }];

    const initialValuesValorFixo = [{
        valorFixo: '',
    }];

    const listTypes = [
        {value: 1, label: 'Comercial'},
        {value: 2, label: 'Interno'},
    ]

    const isView = () => {
        return action === 'view';
    };
    const {
        setValues,
        values,
        handleSubmit,
        handleSubmitValues,
        handleChange,
    } = useCustomForm(
        {
            initialValues,
            route: 'objeto-contrato',
            onSubmit: values => {
                const configJson = JSON.stringify(values.configuracao);
                values.configuracao = configJson;
                let newValues = {...values.values};
                handleSubmitValues(newValues, values.filesUpload)
            },
        }
    );

    const defineTipoCobranca = (event: any) => {
        let valorTipoCobranca = event.target.value;
        if (valorTipoCobranca) {
            valorTipoCobranca = parseInt(valorTipoCobranca);
            let configuracao = null;
            switch (valorTipoCobranca) {
                case TipoConfigTypes.TABELA_COMISSAO:
                    values.configuracao = initialValuesConfiguracaoComissaoMin[0];
                    configuracao = initialValuesConfiguracaoComissao;
                    values['configuracao']['tabelaComissao'] = configuracao;
                    break;
                case TipoConfigTypes.SOB_DEMANDA:
                    configuracao = initialValuesConfiguracaoSobDemanda;
                    values['configuracao'] = configuracao;
                    break;
                case TipoConfigTypes.VALOR_FIXO:
                    configuracao = initialValuesValorFixo;
                    values['configuracao'] = configuracao;
                    break;
            }
        }
        values['tipoConfiguracao'] = valorTipoCobranca;
        setValues({...values});
        {console.log(values)}

    }

    const handleType = (option: any) =>{
        values.type = option.value;
        setValues({...values});
    }

    const handleFormReady = (formApi: FormApi) => {
        setFormApi(formApi);
    }
    const updateConfigValues = (row: number, fieldName: string, value: any) => {
        values.configuracao['tabelaComissao'][row][fieldName] = value;
        setValues({...values});
    };

    const updateConfigValuesDemanda = (row: number, fieldName: string, value: any) => {
        values.configuracao[row][fieldName] = value;
        setValues({...values});
    };

    const updateConfigValuesFix = (row: number, fieldName: string, value: any) => {
        values.configuracao[row][fieldName] = value;
        setValues({...values});
    };

    const updateConfigValuesMinValue = (fieldName: string, value: any) => {
        values.configuracao[fieldName] = value;
        setValues({...values});
    };

    const addRow = () => {
        switch (values.tipoConfiguracao) {
            case TipoConfigTypes.TABELA_COMISSAO:
                values['configuracao']['tabelaComissao'].push(initialValuesConfiguracaoComissao[0]);
                break;
            case TipoConfigTypes.SOB_DEMANDA:
                values['configuracao'].push(initialValuesConfiguracaoSobDemanda[0]);
                break;
        }
        setValues({...values})
    }

    const lessRow = (index: any, allRow: boolean) => {
        if (values.configuracao.length - 1 != 0) {
            switch (values.tipoConfiguracao) {
                case TipoConfigTypes.TABELA_COMISSAO:
                    if (!allRow) {
                        if (values.configuracao['tabelaComissao'].length - 1 > 0) {
                            values['configuracao']['tabelaComissao'].splice(index, 1);

                        }
                    } else {
                        values['configuracao']['tabelaComissao'].splice(1);
                    }
                    break;
                case TipoConfigTypes.SOB_DEMANDA:
                    if (!allRow) {
                        values['configuracao'].splice(index, 1);
                    } else {
                        values['configuracao'].splice(1);
                    }
                    break;
            }
            setValues({...values});
        }
    }
    const checkedItem = () => {
        setValues({...values, ativo: !values.ativo})
    }

    return (
        <Form entity="Objeto de Contrato" handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CFormGroup>
                <CLabel className={'text-danger'} htmlFor="Código">Código</CLabel>
                <CInput
                    readOnly={isView()}
                    disabled={isView()}
                    type="text" id="codigo"
                    name="codigo"
                    maxLength={15}
                    onChange={handleChange}
                    value={values.codigo}
                    required
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel className={'text-danger'} htmlFor="nome">Nome</CLabel>
                <CInput
                    readOnly={isView()}
                    disabled={isView()}
                    maxLength={80}
                    type="text"
                    id="nome"
                    name="nome"
                    onChange={handleChange}
                    value={values.nome}
                    required
                />
            </CFormGroup>
            <CFormGroup>
            <CLabel className={'text-danger'} htmlFor="nome">Tipo</CLabel>
            <Select
                options={listTypes}
                readOnly={isView()}
                isDisabled={isView()}
                name="type"
                id="type"
                onChange={handleType}
                value={listTypes.find((type: any) => type.value === values.type)}
                required
            >
            </Select>
            </CFormGroup>
            <CFormGroup>
                <CLabel htmlFor="finalidade">Finalidade do Objeto</CLabel>
                <CInput
                    readOnly={isView()}
                    disabled={isView()}
                    maxLength={80}
                    type="text"
                    id="finalidade"
                    name="finalidade"
                    onChange={handleChange}
                    value={values.finalidade}
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel className={'text-danger'} htmlFor="Descrição">Descrição do Objeto</CLabel>
                <CTextarea
                    required
                    readOnly={isView()}
                    disabled={isView()}
                    type="text"
                    id="descricao"
                    name="descricao"
                    maxLength={700}
                    onChange={handleChange}
                    value={values.descricao}
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel className={'text-danger'}  htmlFor="nome">Valor de Setup (R$)</CLabel>
                <NumericFormat
                    required
                    name="valorSetup"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    customInput={CInput}
                    value={values.valorSetup}
                    onChange={(e) => {
                        const valorSemSeparadores = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                        setValues({...values, valorSetup: valorSemSeparadores});
                    }}
                    readOnly={isView()}
                    disabled={isView()}
                />
            </CFormGroup>
            <CFormGroup className={'form-check'}>
                <CInputCheckbox readOnly={isView()} checked={values.ativo} onChange={checkedItem} value={values.ativo}/>
                <CLabel readOnly={isView()} htmlFor="ativo">Ativo?</CLabel>
            </CFormGroup>
            <CFormGroup>
                <CLabel htmlFor="tipo">Selecione o tipo de cobrança</CLabel>
                <CSelect
                    readOnly={isView()}
                    disabled={isView()}
                    name="tipo"
                    id="tipo"
                    onChange={defineTipoCobranca}
                    value={values.tipoConfiguracao}
                    required
                >
                    <option value={''}>Selecione...</option>
                    {TipoCobrancaList.getList().map(item => (
                        <option key={item.value} value={item.value}>{item.description}</option>
                    ))}
                </CSelect>
            </CFormGroup>
            <CFormGroup>
                {values['tipoConfiguracao'] == TipoConfigTypes.TABELA_COMISSAO &&
                    <CCard className={'mt-4 pl-3'}>
                        <div className={'row p-1 mb-2'}>
                            <div className={'col-12 p-3'}>
                                <CLabel htmlFor="ValorMínimo">Valor Mínimo</CLabel>
                                <NumericFormat
                                    required
                                    name={"valorMinimo"}
                                    id={'valorMinimo'}
                                    placeholder={"R$"}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    customInput={CInput}
                                    value={values.configuracao['valorMinimo']}
                                    onBlur={(e) => {
                                        const valorSemSeparadores = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                        updateConfigValuesMinValue('valorMinimo', valorSemSeparadores);
                                    }}
                                    readOnly={isView()}
                                    disabled={isView()}
                                />
                            </div>
                        </div>
                        <div className={"row ml-1 "}>
                            <div className={'col border font-weight-bold '}>
                                <CLabel className={'p-1 m-0'} htmlFor="Faturamento">Faturamento</CLabel>
                            </div>
                            <div className={'col border-top font-weight-bold border-right border-bottom'}>
                                <CLabel className={'p-1 m-0'}
                                        htmlFor="Faturamento">Custo Loja (%)</CLabel>
                            </div>
                            <div className={'col border-top font-weight-bold border-right border-bottom'}>
                                <CLabel className={'p-1 m-0'}
                                        htmlFor="Faturamento">Custo Marketplace (%)</CLabel>
                            </div>
                            <div className={'col border-top font-weight-bold border-right border-bottom'}>
                                <CLabel className={'p-1 m-0'}
                                        htmlFor="Faturamento">Comissão B2B</CLabel>
                            </div>
                            <div style={{width: "200px"}}>
                                {!isView() &&
                                    <div className="d-flex  flex-row justify-content-center p-1">
                                        <CButton onClick={() => lessRow(Math.random(), true)}
                                                 color="light"
                                                 className=" btn-sm ">Apagar todas as linhas
                                        </CButton>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={'pb-1'}>
                            {values.configuracao && values.configuracao['tabelaComissao'].map((configuracao: any, index: number) => (
                                <div key={index} className={"row ml-1"}>
                                    <div className={'col border-right border-left '}>
                                        <NumericFormat
                                            className={'mt-1'}
                                            name={"valorFaturamento"}
                                            id={'valorFaturamento'}
                                            placeholder={"R$"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            customInput={CInput}
                                            value={configuracao.valorFaturamento}
                                            onBlur={(e) => {
                                                const valorSemSeparadores = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                                updateConfigValues(index, 'valorFaturamento', valorSemSeparadores);
                                            }}
                                            readOnly={isView()}
                                            disabled={isView()}
                                        />

                                    </div>
                                    <div className={'col border-right'}>
                                        <NumericFormat
                                            className={'mt-1'}
                                            placeholder={"%"}
                                            id="configCustoLoja"
                                            name={'configCustoLoja'}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            customInput={CInput}
                                            onBlur={(e) => {
                                                const valorSemSeparadores = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                                updateConfigValues(index, 'percentualLoja', valorSemSeparadores)
                                            }}
                                            value={configuracao['percentualLoja']}
                                            readOnly={isView()}
                                            disabled={isView()}
                                        />
                                    </div>
                                    <div className={'col border-right'}>
                                        <NumericFormat
                                            className={'mt-1'}
                                            placeholder={"%"}
                                            id="configCustoMarketplace"
                                            name={'configCustoMarketplace'}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            customInput={CInput}
                                            onBlur={(e) => {
                                                const valorSemSeparadores = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                                updateConfigValues(index, 'percentualMarketplace', valorSemSeparadores)
                                            }}
                                            value={configuracao['percentualMarketplace']}
                                            readOnly={isView()}
                                            disabled={isView()}/>
                                    </div>
                                    <div className={'col border-right'}>
                                        <NumericFormat
                                            className={'mt-1'}
                                            placeholder={"%"}
                                            id="comissaoB2B"
                                            name={'comissaoB2B'}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            customInput={CInput}
                                            onBlur={(e) => {
                                                const valorSemSeparadores = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                                updateConfigValues(index, 'comissaoB2B', valorSemSeparadores)
                                            }}
                                            value={configuracao['comissaoB2B']}
                                            readOnly={isView()}
                                            disabled={isView()}/>
                                    </div>
                                    <div style={{width: "200px"}} className={''}>
                                        {!isView() &&
                                            <div className="mt-1 d-flex flex-row justify-content-center p-1">
                                                <CButton onClick={addRow} color="light"
                                                         className="mr-1 small btn-sm"><CIcon
                                                    name="cil-plus"/></CButton>
                                                <CButton onClick={() => lessRow(index, false)} color="light"
                                                         className="small btn-sm"><CIcon
                                                    name="cil-minus"/></CButton>
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </CCard>
                }
                {values['tipoConfiguracao'] == TipoConfigTypes.SOB_DEMANDA &&
                    <CCard className={"mt-4 pl-3"}>
                        <div className={"row"}>
                            <div className={'col font-weight-bold border-right border-bottom'}>
                                <CLabel className={'p-1 m-0'} htmlFor="quantidade">A cada quantidade de</CLabel>
                            </div>
                            <div className={'col font-weight-bold border-bottom border-right'}>
                                <CLabel className={'p-1 m-0'} htmlFor="quantidade">Paga-se o valor</CLabel>
                            </div>
                            <div style={{width: "200px"}}>
                                {!isView() &&
                                    <div className="d-flex flex-row justify-content-center p-1">
                                        <CButton onClick={() => lessRow(Math.random(), true)}
                                                 color="light"
                                                 className="small btn-sm ">Apagar todas as linhas
                                        </CButton>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={'pb-1'}>
                            {values.configuracao && values.configuracao.map((configuracao: any, index: number) => (
                                <div key={index} className={"row"}>
                                    <div className={'col border-right'}>
                                        <NumericFormat
                                            className={'mt-1'}
                                            readOnly={isView()}
                                            disabled={isView()}
                                            customInput={CInput}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={0}
                                            fixedDecimalScale={false}
                                            id="configSobDemanda"
                                            name={'valorSetup'}
                                            // @ts-ignore
                                            onBlur={(e) => {
                                                let val = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                                updateConfigValuesDemanda(index, 'quantidade', val)
                                            }}
                                            value={configuracao['quantidade']}/>
                                    </div>
                                    <div className={'col border-right'}>
                                        <NumericFormat
                                            name={"precoUnidade"}
                                            id={'precoUnidade'}
                                            placeholder={"0,00"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            customInput={CInput}
                                            value={configuracao['precoUnidade']}
                                            onBlur={(e) => {
                                                let val = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                                updateConfigValuesDemanda(index, 'precoUnidade', val);
                                            }}
                                            readOnly={isView()}
                                            disabled={isView()}
                                            className={'mt-1'}
                                        />

                                    </div>
                                    <div style={{width: '200px'}}>
                                        {!isView() &&
                                            <div className="d-flex flex-row justify-content-center">
                                                <CButton onClick={addRow} color="light"
                                                         className="mr-1 small btn-sm"><CIcon
                                                    name="cil-plus"/></CButton>
                                                <CButton onClick={() => lessRow(index, false)} color="light"
                                                         className="small btn-sm"><CIcon name="cil-minus"/></CButton>
                                            </div>
                                        }
                                    </div>
                                </div>

                            ))}
                        </div>

                    </CCard>
                }
                {values['tipoConfiguracao'] == TipoConfigTypes.VALOR_FIXO &&
                    values.configuracao && values.configuracao.map((configuracao: any, index: number) => (
                        <NumericFormat
                            name={"valorFixo"}
                            id={'valorFixo'}
                            placeholder={"0,00"}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            customInput={CInput}
                            value={configuracao['valorFixo']}
                            onBlur={(e) => {
                                let val = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                updateConfigValuesFix(index, 'valorFixo', val);
                            }}
                            readOnly={isView()}
                            disabled={isView()}
                            className={'mt-1'}
                        />
                    ))
                }
            </CFormGroup>
        </Form>

    );
}

export default ObjetoContratoForm;


