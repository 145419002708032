import {createStore, Store, applyMiddleware} from 'redux';
import {LoginState} from './ducks/login/types';
import rootReducer from './ducks/rootReducer';
import thunk from 'redux-thunk';

export interface ApplicationState {
    login: LoginState
}

var initialState: LoginState | undefined = undefined;

const middlewares = [thunk];

const store: Store<ApplicationState> = createStore(rootReducer,{login:initialState}, applyMiddleware(...middlewares));

export default store;