import React, {useState, useEffect} from 'react';
import useCustomForm from "../../../services/useCustomForm";
import {
    CButton,
    CFormGroup,
    CInput,
    CInputCheckbox,
    CLabel,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CNav,
    CNavItem,
    CNavLink, CTabContent, CTabPane, CTabs
} from "@coreui/react";
import {FormApi} from "../../../components/form";
import {getApi} from "../../../apis/backendApis";
import {useParams} from "react-router-dom";
import {TypeExecuteTask, TypeExecuteTaskResult, TypeExecuteTaskResultList} from "./index";
import CIcon from "@coreui/icons-react";
import {TypeOperation, TypeOperationList} from "../operation";
import AceEditor from "react-ace-builds";
import "react-ace-builds/webpack-resolver-min";
import axios from "axios";


const ExecuteTaskAnalysisForm = () => {

    interface GetParam {
        id?: string
    }

    const initialValues: any = {
        id: '',
        name: '',
        type: TypeExecuteTask.TYPE_TEST_USER,
        testCase: {},
        mockup: {}
    }

    const [formApi, setFormApi] = useState<FormApi>();
    const [data, setData] = useState(initialValues);
    const [dataResult, setDataResult] = useState([]);
    const [dataResultLog, setDataResultLog] = useState({"messages": [], "mockups": [], "testCase": []});
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [screen, setScreen] = useState(false);
    const [jsonValue, setJsonValue] = useState('');


    const {id} = useParams<GetParam>();

    const onClickExecutar = () => {
        let api = getApi();
        api.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://127.0.0.1:4000';
        api.put(`execute-task/execute/` + id + `?open_screen=` + screen).then((response) => {
            console.log(response.data);
            if(response.data && response.data['params'] && response.data['params'].length > 0){
                let apiLocal = axios.create({
                    baseURL: 'http://127.0.0.1:4000'
                });
                for(let executeParams of response.data['params']){
                    console.log('execute http://127.0.0.1:4000', executeParams);
                    apiLocal.post(`execute-tester`,executeParams);
                }
                reloadDataResult();
            } else {

            }

        }).finally(() => {
            //console.log('finally');
        });
    }

    const onClickExecutarResult = (result: any) => {
        let api = getApi();
        api.put(`execute-task/execute-result/` + result['id'] + `?open_screen=` + screen).then((response) => {
            reloadDataResult();
        });
    }

    const onClickReload = () => {
        reloadDataResult();
    }

    const onClickDetalhes = (result: any) => {
        let api = getApi();
        api.get(`execute-task/result-log/` + result['id']).then((response) => {
            setDataResultLog(response.data);
            setShowModal(true);
        }).finally(() => {
            //console.log('finally');
        });
    }
    const onCloseModal = () => {
        setDataResultLog({"messages": [], "mockups": [], "testCase": []});
        setShowModal(false);
    }

    const onClickExcluirResult = (result: any) => {
        let api = getApi();
        api.delete(`execute-task/delete-result/` + result['id']).then((response) => {
            reloadDataResult();
        });
    }

    const onClickExcluirTodos = () => {
        let api = getApi();
        api.delete(`execute-task/delete-all/` + id).then((response) => {
            reloadDataResult();
        });
    }

    const onClickExibirJanela = () => {
        setScreen(!(screen));
    }

    useEffect(() => {
        setJsonValue(JSON.stringify(dataResultLog.mockups, null, 2));
    }, [dataResultLog.mockups]);

    async function reloadDataResult() {
        setLoading(true);
        setDataResult([]);
        let api = getApi();
        api.get(`/execute-task/result/` + id).then((response) => {
            setDataResult(response.data);
            setLoading(false);
        }).finally(() => {
            //setLoading(false);
        });
    }

    useEffect(() => {
        async function loadData() {
            let api = getApi();
            api.get(`execute-task/get/` + id).then((response) => {
                setData(response.data)
                reloadDataResult();
            }).finally(() => {
                //console.log('finally');
            });
        }

        loadData();
    }, []);

    return (
        <div className="container-fluid bg-white">
            <div className="row p-1">
                <div className="col-md-3"><h4>{data['name']}</h4></div>
            </div>
            <div className="row p-3">
                <CButton color="primary" onClick={onClickExecutar}>
                    <CIcon name="cil-media-play" className="cil-media-play"/>&nbsp;
                    Executar Task Completa
                </CButton>
                &nbsp;
                <CButton color="info" onClick={onClickReload}>
                    <CIcon name="cil-reload" className="cil-reload"/>&nbsp;
                    Buscar Resultados
                </CButton>
                &nbsp;
                <CButton color="danger" onClick={onClickExcluirTodos}>
                    <CIcon name="cil-trash" className="cil-trash"/>&nbsp;
                    Excluir Todos Resultados
                </CButton>
                &nbsp;
                <CButton color={(screen) ? 'success' : 'default border'} onClick={onClickExibirJanela}>
                    <CIcon name="cil-screen-desktop" className="cil-screen-desktop"/>&nbsp;
                    {screen ? 'Abrir Janela Navegador' : 'Não abre Janela Navegador'}
                </CButton>
            </div>
            <div className="border-top execute-task-result">
                {(dataResult.length == 0 && !loading) && (
                    <div>Não existe nenhuma informação de resultados.</div>
                )}
                {loading && (
                    <div>
                        Carregando resultados...
                    </div>
                )}
                {dataResult.map((result: any) => {
                    return <div className={'card'} key={result['id']}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-5">
                                    <h5 className="card-title">
                                        {result['testCase']}
                                    </h5>
                                    <h6 className="card-subtitle mb-2 text-muted">
                                        #{result['id']} - Mockup: {result['mockup']}
                                    </h6>
                                </div>
                                <div className="col-md-5 row">
                                    <div className="col-md-9">
                                        <div>
                                            {/*{ result['status'] == TypeExecuteTaskResult.STATUS_EXECUTANDO && (*/}
                                            {/*    <>*/}
                                            {/*        <CIcon className="cil-reload" ></CIcon>*/}
                                            {/*    </>*/}
                                            {/*)}*/}
                                            {TypeExecuteTaskResultList.getDescription(result['status'])}
                                        </div>
                                        <div>
                                            {result['dataHoraInicio']}&nbsp;{result['dataHoraFim']}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="text-success text-right">
                                            Sucesso: {result['amount_success']}
                                        </div>
                                        <div className="text-danger text-right">
                                            Erros: {result['amount_erros']}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <CButton color="primary" onClick={() => {
                                        onClickExecutarResult(result)
                                    }}>
                                        <CIcon name="cil-media-play" className="cil-media-play"/>
                                    </CButton>
                                    &nbsp;

                                    <CButton color="info" onClick={() => {
                                        onClickDetalhes(result)
                                    }}>
                                        <CIcon name="cil-settings" className="cil-settings"/>
                                    </CButton>


                                    &nbsp;

                                    <CButton color="danger" onClick={() => {
                                        onClickExcluirResult(result)
                                    }}>
                                        <CIcon name="cil-trash" className="cil-trash"/>
                                    </CButton>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
            <CModal
                show={showModal}
                onClosed={onCloseModal}
                backdrop={true}
                color="info"
                size={'lg'}
            >
                <CModalHeader closeButton>
                    <CModalTitle>Mensagens do Teste</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className="p-2">
                        <div>
                            <CTabs>
                                <CNav variant="tabs">
                                    <CNavItem>
                                        <CNavLink>
                                            Registro dos Resultados
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem>
                                        <CNavLink>
                                            Mockups
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem>
                                        <CNavLink>
                                            Casos De Teste
                                        </CNavLink>
                                    </CNavItem>
                                </CNav>
                                <CTabContent>
                                    <CTabPane>
                                        {dataResultLog["messages"].map((message: any, index) => {
                                            return <div className="row" key={index}>
                                                {message}
                                            </div>
                                        })}
                                    </CTabPane>
                                    <CTabPane>
                                        {(dataResultLog["mockups"]).map((mockup: any, index) => {
                                            return <div className="row" key={index}>
                                                <AceEditor
                                                    mode="json"
                                                    theme="kuroir"
                                                    width={"100%"}
                                                    fontSize={15}
                                                    value={jsonValue}
                                                    readOnly={true}
                                                />
                                            </div>
                                        })}
                                    </CTabPane>
                                    <CTabPane>
                                        {(dataResultLog["testCase"]).map((testCase: any, index) => {
                                            return <div className="row  p-2" key={index}>
                                                <div className='text-muted text-justify'>ID</div>
                                                <CInput readOnly={true} className='mb-2 bg-light'
                                                        value={testCase.id}></CInput>
                                                <div className='text-muted text-justify'>Nome</div>
                                                <CInput readOnly={true} className='mb-2 bg-light'
                                                        value={testCase.name}></CInput>
                                                <div className='text-muted text-justify'>Código</div>
                                                <CInput readOnly={true} className='mb-4 '
                                                        value={testCase.codigo}></CInput>
                                                <div className='text-muted font-weight-bold'>Operações:</div>
                                                &nbsp;
                                                {(testCase['operations']).map((operation: any, index: number) => (
                                                    <div className={'border-bottom mb-1'} style={{width: "100%"}} key={index}>
                                                        <details className={'mb-2'}>
                                                            <summary className={'mb-1 font-lg '}>
                                                                {operation.name}
                                                            </summary>
                                                            Parâmetros
                                                            <CInput readOnly={true}
                                                                    value={JSON.stringify(operation.params)}></CInput>
                                                        </details>
                                                    </div>
                                                ))}
                                            </div>
                                        })}
                                    </CTabPane>
                                </CTabContent>
                            </CTabs>
                         </div>
                    </div>
                </CModalBody>
            </CModal>
        </div>)
}
export default ExecuteTaskAnalysisForm;