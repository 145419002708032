import React, {useState} from 'react';
import useCustomForm from '../../../services/useCustomForm'
import {
    CFormGroup,
    CLabel,
    CInput,
    CInputCheckbox,
    CCol,
} from '@coreui/react';
import {UsuarioFormData} from "./index";
import AsyncSelect from "react-select/async";
import {getApi} from "../../../apis/backendApis";
import Form, {FormApi} from "../../../components/form";
import useCurrentAction from "../../../services/useCurrentAction";


const UsuarioForm = ()=>{

    const [formApi, setFormApi] = useState<FormApi>();
    const action = useCurrentAction();

    const initialValues:UsuarioFormData = {
        email:'',
        senha:'',
        ativo:true,
        pessoa:{}
    }

    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        handleChangeSuggest,
    } = useCustomForm(
        {
            initialValues,
            route:'usuario',
            onSubmit: values => handleSubmitValues(values.values),
            onLoadDadosCompleted : (data)=>{
                let newData = {...data, pessoa: {value: data.pessoa.id , label: data.pessoa.nome}}
                setValues(newData);
            }
        }
    );

    const promiseOptions = (inputValue:string):Promise<any> => {
        let api = getApi();
        return api.get(`/pessoa/suggest/${inputValue}`).then(response=>response.data);
    }

    const isView = () => {
        return action === 'view';
    };

    const handleFormReady = (formApi:FormApi)=>{
        setFormApi(formApi);
    }

    return (
        <Form entity="Usuario" handleSubmit={handleSubmit} onFormReady={handleFormReady} >
            <CFormGroup>
                <CLabel className={'text-danger'} htmlFor="pessoa">Pessoa</CLabel>
                <AsyncSelect isDisabled={isView()} required defaultOptions loadOptions={promiseOptions} onChange={newValue=>handleChangeSuggest(newValue, 'pessoa')} value={values.pessoa}>
                </AsyncSelect>
            </CFormGroup>
            <CFormGroup>
                <CLabel className={'text-danger'} htmlFor="email">E-mail</CLabel>
                <CInput readOnly={isView()} disabled={isView()} type="text" id="email" name="email" onChange={handleChange} value={values.email} autoComplete={'off'} required/>
            </CFormGroup>
            <CFormGroup>
                <div className={'alert alert-info'}>
                    <strong>Política de Senhas para usuários da Panorama Sistemas</strong>
                    <ul>
                        <li>A senha deve ter pelo menos 12 caracteres</li>
                        <li>A senha deve conter pelo menos uma letra maiúscula</li>
                        <li>A senha deve conter pelo menos uma letra minúscula</li>
                        <li>A senha deve conter pelo menos um número</li>
                        <li>A senha deve conter pelo menos um caractere especial</li>
                        <li>A senha tem validade de no máximo de 365 dias</li>
                        <li>Não deve conter parte do nome de usuário</li>
                    </ul>
                </div>
            </CFormGroup>

            <CFormGroup>
                <CLabel className={'text-danger'} htmlFor="senha">Senha</CLabel>
                <CInput minLength={12} readOnly={isView()} disabled={isView()} type="password" id="senha" name="senha" onChange={handleChange} value={values.senha} autoComplete={'new-password'} required/>
            </CFormGroup>
            <CFormGroup>
                <CLabel className={'text-danger'} htmlFor="senha_confirm">Confirma Senha</CLabel>
                <CInput readOnly={isView()} disabled={isView()} type="password" id="senha_confirm" name="senha_confirm" onChange={handleChange} value={values.senha_confirm} autoComplete={'new-password'} />
            </CFormGroup>


            <CFormGroup>
                <CCol className="pl-4">
                    <CInputCheckbox
                        id="ativo"
                        name="ativo"
                        checked={values.ativo}
                        onChange={handleChange}
                        readOnly={isView()}
                        disabled={isView()}
                    />
                    <CLabel variant="checkbox" htmlFor="ativo" >Ativo</CLabel>
                </CCol>
            </CFormGroup>
        </Form>
    )
}

export default UsuarioForm;