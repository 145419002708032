import { Reducer } from 'redux';
import {LoginTypes, LoginState, StatusTypes} from './types';

export const INITIAL_STATE: LoginState = {
    status: StatusTypes.RETRIEVING,
    data: {
        user : {
            login:'',
            name: '',
            id: 0,
            tipo: 0,
            isManagerTeam: false
        },
        expires_in :'',
        token: ''
    }
}

const reducer: Reducer<LoginState> = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case LoginTypes.LOGGING_IN:
            return {data:action.payload, status:StatusTypes.LOGGED}
        case LoginTypes.LOGGING_OFF:
            return {data:null, status:StatusTypes.UNLOGGED}
        case LoginTypes.LOGGING_RETRIEVING:
            return {data:null, status:StatusTypes.RETRIEVING}
        default:
            return state
    }
}

export default reducer