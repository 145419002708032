import React, {useContext, useEffect, useRef, useState} from "react";
import Form, {FormApi} from "../../components/form";
import {LoadingStatusTypes} from "../../store/ducks/loading/types";
import {
    CCard,
    CCardBody,
    CCardHeader,
    CRow,
    CCollapse,
    CBadge,
    CFormGroup,
    CLabel,
    CInput,
    CTextarea,
    CSelect,
    CButton,
    CInputCheckbox,
    CSwitch,
    CProgress,
    CInputGroup,
    CDropdownToggle,
    CDropdown,
    CDropdownMenu,
    CDropdownItem,
    CInputGroupAppend,
    CDropdownDivider,
    CButtonGroup,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CTabs,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane, CModal, CCallout
} from "@coreui/react";
import {TipoPerguntaTypes} from '../cadastros/checklist/index'
import CIcon from "@coreui/icons-react";
import api, {getApi} from "../../apis/backendApis";
import {useParams} from "react-router-dom";
import submitValues from "../../services/submitValues";
import {SetorList} from "../cadastros/checklist/index";
import {TipoProdutoList} from "../cadastros/cliente/index";
import useLoading from "../../services/useLoading";
import useAlert from "../../services/useAlert";
import TaskModalAgenda from "./taskModalAgenda";
import {useSelector} from "react-redux";
import {States} from "../../store/ducks/rootReducer";
import FormatDate from "../../components/formatDate";


interface ChecklistProps {
    id: number,
    nome: string,
    expandir : boolean
    setor : number
    ordem?: number,
    perguntas : PerguntaProps[],
    isGestor: any,
    agendando: any,
    onCloseModal: Function
    cnpjCliente: any
}

interface PerguntaProps {
    checklistItem: number,
    titulo: string,
    descricao: string,
    respondida: boolean,
    obrigatoria: boolean,
    tipo:number,
    id?: number,
    tipoMetrica?: number,
    resposta?: string,
    usuario?: string,
    data_hora?: string,
    opcoes?: {numero: number, descricao: string}[],
    intervalTimer?:any
    atualizaRespondidas:Function,
    atualizadoResposta:Function,
    onClick:Function,
    itemCondicional: string
    valorCondicional: string
}

interface GetParam {
    id?: string
}

interface EnvioRepostaRetorno{
    id: number,
    percentual: number,
    perguntas: number,
    respostas: number,
}

interface ExecuteContext {
    onRespostaEfetuada: Function,
}

interface DadosCliente{
    nome:string,
    id: number,
    produtos?: Array<ClienteProduto>
}

interface ClienteProduto{
    tipo: number,
    url:string
}

const ExecuteTaskContext = React.createContext({onRespostaEfetuada: ()=>{}} as ExecuteContext);

const Pergunta = (props:PerguntaProps) => {
    const { id:idTask } = useParams<GetParam>();
    const [respondendo, setRespondendo] = useState(false);
    const [time, setTime] = useState<number>(0);
    const [initialTime, setInitialTime] = useState<Date>();
    const [resposta, setResposta]= useState<any | undefined>(props.resposta);
    const [idResposta, setIdResposta] = useState(props.id);
    const [loading, setLoadingResposta] = useState(false);
    const [usuario, setUsuario] = useState(props.usuario);
    const [dataHora, setDataHora] = useState(props.data_hora);
    const refTime = useRef<any>();
    const executeTaskContext = useContext(ExecuteTaskContext);
    const [showAlert] = useAlert();

    useEffect(()=>{
        if(respondendo){
            if(!initialTime){
                setInitialTime(new Date());
            }
        } else {
            if(refTime.current){
               clearInterval(refTime.current);
               setInitialTime(undefined);
            }
        }
    }, [respondendo])

    useEffect(()=>{
        refTime.current = setInterval(()=>{
            if(initialTime){
                const newDate = new Date();
                let interval = newDate.getTime()-initialTime.getTime();
                setTime(lastValue=>lastValue+interval)

            }
        },10);
    }, [initialTime]);

    const handleChangeDiscursiva = (event:React.ChangeEvent<HTMLTextAreaElement>)=>{
        setResposta(event.target.value);
    }

    const handleChangeObjetiva = (event:React.ChangeEvent<HTMLSelectElement>)=>{
        let localReposta = event.target.value;
        enviarResposta(localReposta);
        setResposta(localReposta);
    }

    const handleChangeSimNao = (opcao:boolean)=>{
        enviarResposta(opcao);
        setResposta(opcao);
    }

    const handleChangeMultiplaEscolha = (event:React.ChangeEvent<HTMLInputElement>)=>{
        let localReposta = {...resposta, [event.target.id]: event.target.checked};

        enviarResposta(localReposta);
        setResposta(localReposta);
    }

    const montaObjetiva = () => (
        <CSelect onChange={handleChangeObjetiva} value={ resposta ? resposta*1 : '' }>
            <option value={undefined}>{'Selecione'}</option>
            {props.opcoes?.map(opcao=>(
                <option key={opcao.numero} value={opcao.numero}>{opcao.descricao}</option>
            ))}
        </CSelect>
    );

    const montaMultiplaEscolha = () => (
        <div>
            {props.opcoes?.map(opcao=>(
                    <CFormGroup key={opcao.numero} variant="checkbox" className="checkbox">
                        <CInputCheckbox
                            id={opcao.numero.toString()}
                            name={opcao.descricao}
                            checked={resposta && resposta[opcao.numero.toString()] != undefined ? resposta[opcao.numero.toString()] : false}
                            onChange={handleChangeMultiplaEscolha}
                        />
                        <CLabel variant="checkbox" className="form-check-label" htmlFor={opcao.numero.toString()}>{opcao.descricao}</CLabel>
                    </CFormGroup>
                ))}
        </div>
    );

    const montaDiscusiva = () => (
        <CTextarea onChange = {handleChangeDiscursiva} value = {resposta ? resposta : ''}/>
    );

    const getClassBotaoSim = ()=>{
        let classname = "small";

        if(resposta === true){
            classname += " btn-success";
        } else {
            classname += " btn-outline-success";
        }

        return classname;
    }

    const getClassBotaoNao = ()=>{
        let classname = "small mr-1";

        if(resposta === false){
            classname += " btn-danger";
        } else {
            classname += " btn-outline-danger";
        }

        return classname;
    }

    const montaSimNao = () => (
        <div>
            <CButton className={getClassBotaoNao()} onClick={()=>handleChangeSimNao(false)}>Não</CButton>
            <CButton className={getClassBotaoSim()} onClick={()=>handleChangeSimNao(true)}>Sim</CButton>
            {/*<CSwitch checked={resposta && resposta} value={resposta} onChange={handleChangeSimNao} className="mx-1 small" color={'success'} labelOn={'\u2713'} labelOff={'\u2715'} />            */}
        </div>
    );

    const getPergunta = (tipo:number)=>{
        switch (tipo) {
            case TipoPerguntaTypes.OBJETIVA: return montaObjetiva()
            case TipoPerguntaTypes.DISCURSIVA: return montaDiscusiva()
            case TipoPerguntaTypes.SIM_NAO: return montaSimNao()
            case TipoPerguntaTypes.MULTIPLA_ESCOLHA: return montaMultiplaEscolha()
        }
    }

    const getPlayPauseIcon = ()=>(respondendo ? 'cil-media-pause' : 'cil-media-play')

    const getPlayPauseColor = ()=>(respondendo ? 'btn-secondary' : 'btn-primary')

    const handleClickPlayPause = ()=> setRespondendo(lastValue=>!lastValue)

    const handleClickExcluirResposta = ()=>{
        let api = getApi();
        if(idResposta){
            setLoadingResposta(true);
            api.delete(`task/answer/delete/${idResposta}`).then((response)=>{
                setResposta(undefined);
                setIdResposta(undefined);
                setUsuario(undefined);
                setDataHora(undefined);
                executeTaskContext.onRespostaEfetuada(response.data.data);
                props.atualizadoResposta(true)
                props.atualizaRespondidas((lastvalue:number[])=>{
                    let arrayCopy = Array.from(lastvalue);
                    let repostaIndex = lastvalue.findIndex(id=>(id == idResposta));
                    arrayCopy.splice(repostaIndex, 1);

                    return arrayCopy;
                })
                props.onClick();
            }).finally(()=>setLoadingResposta(false));
        }
    }

    const enviarResposta = (respostaParam:any)=>{
        let {checklistItem} = {...props};
        let values =  {id:idResposta, checklistItem, resposta:respostaParam, time, task: idTask};
        setLoadingResposta(true);
        submitValues({
            route:'task/answer',
            action:'add',
            values
        }).then((response)=>{
            if(response.data.data.id){
                let newId = response.data.data.id;
                setIdResposta(newId);
                props.atualizaRespondidas((lastvalue:number[])=>{
                    let arrayCopy = Array.from(lastvalue);
                    let repostaIndex = lastvalue.findIndex(id=>(id == newId));
                    if(repostaIndex === -1){
                        if((props.obrigatoria && props.tipo === TipoPerguntaTypes.SIM_NAO && respostaParam === true) || (!props.obrigatoria || props.tipo !== TipoPerguntaTypes.SIM_NAO)){
                            arrayCopy.push(newId);
                        }
                    } else {
                        if(props.obrigatoria && props.tipo === TipoPerguntaTypes.SIM_NAO && respostaParam === false){
                            arrayCopy.splice(repostaIndex, 1);
                        }
                    }
                    props.onClick();
                    executeTaskContext.onRespostaEfetuada(response.data.data);
                    setDataHora(response.data.data.data_hora);
                    setUsuario(response.data.data.usuario);
                    props.atualizadoResposta(true)
                    return arrayCopy;
                })
            }
        }).catch((error)=>{
            showAlert({
                title:'Erro',
                msg: error.response.data.error,
                details:error.response.data.trace,
            });
        })
          .finally(()=>setLoadingResposta(false));
    }

    const Loading = () =>{
        return (
            <div className="">
                <div className="spinner-border" role="status">
                </div>
            </div>
        )
    }

    return (
        <div className="pergunta">
            {loading && <Loading/>}
            <CFormGroup>
                <div>
                    <CLabel className="font-weight-bold">
                        {props.titulo}
                    </CLabel>
                    {idResposta && (<CButton className="pt-0" title="Exclui a resposta." onClick={()=>handleClickExcluirResposta()}><CIcon name="cil-trash"></CIcon></CButton>)}
                    {idResposta && (
                        <div className="float-right font-xs">
                            <CLabel>{usuario} - {dataHora}</CLabel>
                        </div>
                    )}
                </div>
                <p>{props.descricao}</p>
                {getPergunta(props.tipo)}
            </CFormGroup>
            <div className="justify-content-end row">
                {false/*props.tipoMetrica == TipoMetrica.TEMPO*/ && (
                    <CButton className={`${getPlayPauseColor()} mx-1 mb-2 btn-sm`} onClick={handleClickPlayPause}>
                        <CIcon name={getPlayPauseIcon()} className="c-icon-sm"></CIcon>
                    </CButton>
                )}
                {props.tipo == TipoPerguntaTypes.DISCURSIVA && (
                    <CButton className="btn-success mx-1 mb-2 btn-sm" onClick={()=>resposta && enviarResposta(resposta)}>
                        Enviar
                    </CButton>
                )}
            </div>
        </div>
    )
}

const Checklist = (props:ChecklistProps) => {
    const { id } = useParams<GetParam>();
    const [accordion, setAccordion] = useState<number | null>();
    const [respondidas, setRespondidas] = useState<(number | undefined)[]>();
    const [modalVisible, setModalVisible] = useState(false);
    const [showAlert] = useAlert();


    useEffect(() => {
        let respondidas = props.perguntas.filter((pergunta) => (pergunta.respondida));
        let ids = respondidas.map((pergunta) => (pergunta.id));
        setRespondidas(ids);
    }, [props.perguntas])

    useEffect(() => {
        setAccordion(props.expandir ? props.id : null);
    }, [props.expandir])

    const handleModalClick = () => {
        setModalVisible(true);
    };

    const isPastDueDate = (dateString: string) => {
        return new Date(dateString) < new Date();
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        props.onCloseModal()
    };

    const atualiza = () => {
        props.onCloseModal()
    };

    let [count, setCount] = useState<number>();
    useEffect(() => {
        let newCount = 0;
        props.perguntas.forEach((pergunta: any) => {
            if (validaCondicional(pergunta)) {
                newCount++;
            }
        });
        setCount(newCount);
    }, [props.perguntas]);

    const validaCondicional = (pergunta: any) => {
        const idItemCondicional = pergunta?.itemCondicional;
        if (idItemCondicional <= 0) return true;
        const itemPai = props?.perguntas.find(p => p?.checklistItem == idItemCondicional);
        if (pergunta.valorCondicional == null && itemPai?.respondida) return true;
        return props?.perguntas.filter(p => p?.checklistItem == idItemCondicional && p?.respondida).some(value => (
            value.tipo === 1 ? value?.resposta == pergunta?.valorCondicional?.numero :
                value.tipo === 2 ? true :
                    value.tipo === 3 ? !!value?.resposta?.[pergunta?.valorCondicional?.numero] :
                        value.tipo === 4 ? (value?.resposta && pergunta?.valorCondicional?.numero == 1) || (!value?.resposta && pergunta?.valorCondicional?.numero == 2) :
                            false
        ));
    };

    const [afetado, setAfetado] = useState(false)
    const [idAgendaFechamento, setIdAgendaFechamento] = useState<number>(0)
    const validaFechamentoAgenda = (id: number) => {
        let agendado = props.agendando.find((agenda: any) => agenda.idChecklist === id)
            ? { isAgenda: true, id: props.agendando.find((agenda: any) => agenda.idChecklist === id)?.id }  : undefined;

        if (agendado?.isAgenda) {
            setIdAgendaFechamento(agendado.id);
            setAfetado(true);
        }
    }

    useEffect(() => {
        if (respondidas?.length == count && count != undefined && idAgendaFechamento > 0) {
            const api = getApi();
            api.get('checklistAgenda/fecharAgendamento/' + idAgendaFechamento).then((response => {
                if (response.data.success) {
                    showAlert({
                        msg: response.data.msg,
                        toaster: true,
                        type: 'success',
                    });
                }
            }))
        }
        setAfetado(false)
    }, [afetado]);

    return (
        <CCard>
            <CCardHeader className="checklist-header row">
                <div onClick={() => {
                    setAccordion(accordion === props.id ? null : props.id)
                }} className={'col-10'}>
                    <span className="h4">{props.nome}</span>
                    <span className="h6 ml-2">({SetorList.getDescription(props.setor)})</span>
                    {props.agendando?.map((agendamento: any) => (
                        agendamento.idChecklist === props.id && (
                            <CCallout  className={'shadow-none agendado border-top border-right border-bottom'} color={`${respondidas && respondidas.length === count ? 'success' : isPastDueDate(agendamento.dataFim) ? 'danger' : ''}`}>
                                <div key={agendamento.idChecklist} className="mb-2 mt-2">
                                    <div>
                                        <div className="d-flex flex-column">
                                            <p className={`mb-0 ${respondidas && respondidas.length === count ? '' : isPastDueDate(agendamento.dataFim) ? 'text-danger' : ''}`}>
                                                <CIcon name="cil-calendar" className="mr-1 mb-1 text-primary"/>
                                                <strong>Data da Agenda:</strong> {FormatDate(agendamento.dataInicio, agendamento.dataFim)}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="mb-0 ">
                                                <CIcon name="cil-people" className="mr-1 mb-1 text-primary"/>
                                                <strong>Setor:</strong> {agendamento.setor}
                                            </p>
                                            <p className="mb-0">
                                                <CIcon name="cil-user" className="mr-1 mb-1 text-primary"/>
                                                <strong>Responsável:</strong> {agendamento.responsavel}
                                            </p>
                                        </div>

                                        <div className={' '}>
                                            {agendamento.issue &&
                                                <div className="mb-0">
                                                    <p className="mb-0">
                                                        <CIcon name="cil-task" className="mr-1 mb-1 text-primary"/>
                                                        <strong>Atividade:</strong>
                                                       &nbsp; <a className="link-opacity-10"
                                                           href={`https://redmine.panoramasistemas.com.br/issues/` + agendamento.issue}
                                                           target="_blank">#{agendamento.issue}</a>
                                                    </p>
                                                </div>
                                            }
                                            {!agendamento.issue &&
                                                <div className="mb-0">
                                                    <p className="mb-0">
                                                        <CIcon name="cil-task" className="mr-1 mb-1 text-primary"/>
                                                        <strong>Atividade:</strong>
                                                        &nbsp;Sem Atividade
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </CCallout>
                        )
                    ))}
                </div>
                <div className="d-flex float-right align-items-center col-2">
                    <div>
                        {props.isGestor &&
                            <CButton onClick={handleModalClick}
                                     className={'agendar mt-2 mr-3 ml-3 btn-sm btn-linkedin d-flex align-items-center'}>
                                <CIcon className='calendar' name='cil-calendar'></CIcon>
                                &nbsp; Agendar
                            </CButton>
                        }
                        {modalVisible &&
                            <TaskModalAgenda
                                show={modalVisible}
                                onClosed={handleCloseModal}
                                itemChecklist={props}
                                idTask={id}
                            />
                        }
                    </div>
                    <div className="d-flex mt-2 float-right align-items-center col">
                        <CBadge title="Respostas" shape="pill"
                                color={respondidas && respondidas.length === count ? "success" : "primary"}>{respondidas ? respondidas.length : 0}/{count}</CBadge>
                    </div>
                </div>
            </CCardHeader>
            <CCollapse show={accordion === props.id}>
                <CCardBody className="ml-4 mr-4 mt-2">
                    {props.perguntas.map((pergunta, index) => (
                        validaCondicional(pergunta) && (
                            <div key={pergunta.checklistItem}>
                                <Pergunta {...pergunta} onClick={()=>{validaFechamentoAgenda(props.id)}}  atualizaRespondidas={setRespondidas}
                                          atualizadoResposta={atualiza}/>
                                {index !== props.perguntas.length - 1 && <CRow className="border-bottom mb-2"/>}
                            </div>
                        )
                    ))}
                </CCardBody>
            </CCollapse>
        </CCard>
    )
}


const TaskExecuteForm = () => {
    const {id} = useParams<GetParam>();
    const [checklists, setChecklists] = useState<Array<ChecklistProps>>([]);
    const [formApi, setFormApi] = useState<FormApi>();
    const [valorFiltro, setValorFiltro] = useState();
    const [expandir, setExpandir] = useState(false);
    const [semResposta, setSemReposta] = useState(false);
    const [noSend, setNoSend] = useState(false);
    const [filtro, setFiltro] = useState('Checklist');
    const [classButtonExpandir, setClassButtonExpandir] = useState('btn-outline-info');
    const [iconButtonExpandir, setIconButtonExpandir] = useState('cil-chevron-circle-right-alt');
    const [percentual, setPercentual] = useState(0);
    const [dadosCliente, setDadosCliente] = useState<DadosCliente>();
    const [setLoading] = useLoading();
    const loadingState = useSelector((state:States)=> state.loading);
    const [firstAccess, setFirstAccess] = useState(false);
    const [cnpjCliente, setCnpjCliente] = useState();
    const [loadingProdutos, setLoadingProdutos] = useState(false);
    const [ userResponsible, setUserResponsible] = useState([]);
    const [agendado, setAgendado] = useState<any[]>([]);
    const [modalClose, setModalClose] = useState(false);

    const atualizaSituacao = ()=>{
        if(!formApi || !id){
            return;
        }
        let api    = getApi();
        api.post(`/task/execute/${id}`)
            .catch()
            .finally();
    }

    useEffect(() => {
        realizaBuscaRespostas();
    }, [noSend]);
    const handleClick = () => {
        setNoSend((prevNoSend) => !prevNoSend);
    };

    const realizaBuscaRespostas = ()=>{
        if(!formApi || !id){
            return;
        }
        let api    = getApi();
        let params: {checklist?:string, item?:string, sem_resposta?:number, no_send?:number} = {};

        if (!modalClose){
            setLoading(true);
            setModalClose(true)
        }

        if(valorFiltro && valorFiltro !== ''){
            switch (filtro) {
                case 'Checklist':
                    params['checklist'] = valorFiltro;
                    break;
                case 'Pergunta':
                    params['item'] = valorFiltro;
                    break;
            }
        }

        if(semResposta){
            params['sem_resposta'] = 1;
        }

        if(noSend){
            params['no_send'] = 1;
        }

        api.get(`/task/answer/list/${id}`, {
            params
        }).then((response)=>{
            if(params['no_send'] == 1){
                const checklists = response.data.checklists || [];
                const agenda = response.data.agenda || [];

                const newChecklist = checklists.filter((checklist: any) => {
                    return agenda.some((agendaItem: any) =>
                        agendaItem.idChecklist === checklist.id && agendaItem.issue == null
                    );
                });
                setChecklists(newChecklist);
            }else{
                setChecklists(response.data.checklists);
            }
            setPercentual(response.data.percentual);
            setFirstAccess(response.data.gestor);
            setDadosCliente(response.data.dados_cliente);
            setAgendado(response?.data?.agenda);
            setCnpjCliente(response.data.cnjpCliente)
        }).finally(()=>{
            setLoading(false);
        });
    };

    useEffect(()=>{
        if(id && formApi){
            realizaBuscaRespostas();
            atualizaSituacao();
        }
    },[id, formApi]);

    useEffect(()=>{
        setClassButtonExpandir(expandir ? 'btn-info' : 'btn-outline-info');
        setIconButtonExpandir(expandir ? 'cil-chevron-circle-down-alt' : 'cil-chevron-circle-right-alt');
    },[expandir]);

    useEffect(()=>{
        realizaBuscaRespostas();
    },[semResposta]);

    const handleFormReady = (formApi:FormApi)=> {
        setFormApi(formApi);
    }

    const isModalClose = ()=> {
        realizaBuscaRespostas();
    }

    const handleOnKeyPressFiltro = (event:React.KeyboardEvent<HTMLInputElement>)=>{
        if(event.charCode == 13){
            realizaBuscaRespostas();
            event.preventDefault();
        };
    }

    const handleClickAlterarDadosCliente = ()=>{
        window.open(process.env.PUBLIC_URL+'#/cliente/alterar/'+dadosCliente?.id, '_blank');
    }

    const handleClickProduto = (tipo:number)=>{
        var api = getApi();
        setLoadingProdutos(true);
        api.get(`/task/answer/dados_cliente_header/${id}`, {}).then((response)=>{
            setDadosCliente(response.data);
            let {url} = response.data.produtos.find((produto:ClienteProduto)=>produto.tipo == tipo);
            url ? window.open(url, '_blank') : alert('Url não encontrada!');
        }).finally(()=>{
            setLoadingProdutos(false);
        });
    }

    const onRespostaEfetuada = (data:EnvioRepostaRetorno)=>{
        setPercentual(data.percentual);
    }

    const Loading = () =>{
        return (
            <div className="">
                <div className="spinner-border" role="status">
                </div>
            </div>
        )
    }
    useEffect(()=>{
        let api = getApi()
        api.get(`/task/get/${id}`).then((response) =>{
            setUserResponsible(response.data['usuario'])
        });
    },[]);

    const montaHeader = ()=>(
        <CCardHeader>
            <CRow>
                <div className="col-10">
                    <CRow className="h4 justify-content-center">{dadosCliente?.nome}</CRow>
                    <CRow className="justify-content-center">
                        <CButtonGroup size="sm">
                            <CButton color="secondary" onClick={handleClickAlterarDadosCliente}>Alterar Cadastro</CButton>
                            {dadosCliente?.produtos?.map((produto,index)=>(
                                <CButton key={index} color="secondary" onClick={()=>handleClickProduto(produto.tipo)}>Acessar {TipoProdutoList.getDescription(produto.tipo)}</CButton>
                            ))}
                            {loadingProdutos && <Loading/>}
                        </CButtonGroup>
                    </CRow>
                    <CRow className="mt-3 ml-3">
                        <CInputGroup>
                            <CDropdown className="input-group-prepend">
                                <CDropdownToggle  color="primary">
                                    {filtro}
                                </CDropdownToggle>
                                <CDropdownMenu >
                                    <CDropdownItem onClick={()=>setFiltro('Checklist')}>Checklist</CDropdownItem>
                                    <CDropdownItem onClick={()=>setFiltro('Pergunta')}>Pergunta</CDropdownItem>
                                </CDropdownMenu>
                            </CDropdown>
                            <CInput type="text" onKeyPress={handleOnKeyPressFiltro} id="filtro" name="filtro"
                                    value={valorFiltro}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
                                        setValorFiltro(event.target.value)
                                    }}/>
                            <CInputGroupAppend>
                                <CButton type="button" color="primary" onClick={() => {setModalClose(false); realizaBuscaRespostas()}}>Buscar</CButton>
                            </CInputGroupAppend>
                        </CInputGroup>
                    </CRow>
                    <div className={'row'}>
                        <CRow className="col-4 mt-3 ml-3">
                            <div className={'row col'}>
                                <CLabel htmlFor="nao_respondida">Não Respondidas:</CLabel>
                                <CSwitch name="nao_respondida" id="nao_respondida" className={'mx-1'} color={'success'}
                                         onChange={() => {
                                             setModalClose(false);
                                             setSemReposta(!semResposta)
                                         }}/>
                                <CButton type="button" className={`btn-sm ml-2 small ${classButtonExpandir}`}
                                         onClick={() => setExpandir(!expandir)}><CIcon
                                    name={iconButtonExpandir}/> {expandir ? 'Recolher' : 'Expandir'}</CButton>
                            </div>
                            <div className={'row col-7'}>
                                <CLabel htmlFor="nao_respondida">Não Enviadas ao Redmine:</CLabel>
                                <CSwitch
                                    name="nao_respondida"
                                    id="nao_respondida"
                                    className={'mx-1'}
                                    color={'success'}
                                    onChange={() => {
                                        setModalClose(false);
                                        handleClick();
                                    }}
                                />
                            </div>
                        </CRow>
                        <div className={'col row'}>
                            <div className={'col-3 ml-5 mt-4 badge font-lg'}> Gestor do Projeto:</div>
                            {//@ts-ignore
                                <div className={'col mt-4 font-lg '}> {userResponsible?.nome} </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-2 mt-3 text-center">
                    <h6>Progresso</h6>
                    <CProgress color={percentual >= 100 ? "success" : "primary"} value={percentual} showValue
                               className="mb-3"/>
                </div>
            </CRow>
        </CCardHeader>
    )

    const montaFooter = () => (<></>);

    return (
        <Form
            entity={'Execução Atividade'}
            handleSubmit={() => {
            }}
            onFormReady={handleFormReady}
            header={montaHeader()}
            footer={montaFooter()}
        >
            <ExecuteTaskContext.Provider value={{onRespostaEfetuada}}>
                {
                    checklists && checklists.map((checklist) => (
                        <Checklist key={checklist.id} {...checklist} expandir={expandir} isGestor={firstAccess} agendando={agendado} onCloseModal={isModalClose} cnpjCliente={cnpjCliente}/>
                    ))
                }
            </ExecuteTaskContext.Provider>
            {(loadingState.state == LoadingStatusTypes.DISPLAY_OFF && checklists.length == 0) &&
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="alert alert-danger text-center" role="alert">
                                <h4 className="alert-heading">Sem registros</h4>
                                <p>Não localizado nenhuma checklist disponível!</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Form>
    )
}

export default TaskExecuteForm;