import React, { Component, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CAlert
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { ApplicationState } from '../../store';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from "../../models/Auth";
import * as actions from "../../store/ducks/login/actions";
import { saveToken } from '../../services/tokenVerify';
import api, {getApi} from "../../apis/backendApis";

enum LoggingStatus {
    LOGGING_OK = 1,
    LOGGING_ERROR = 2,
    LOGGING_STANDING_BY = 3,
}

interface FormProps {
    login : string,
    password: string,
    status : LoggingStatus
}

interface DispatchProps {
    loggingIn(data:Auth):void
}

interface OwnProps {
    history:string[]
}

type Props = DispatchProps & OwnProps

class Login extends Component<Props, FormProps> {

    constructor(props:Props) {
        super(props);
        this.state = {
            login:'',
            password:'',
            status: LoggingStatus.LOGGING_STANDING_BY
        };
    }    

    render (){
        const onChangeInput = (e : ChangeEvent<HTMLInputElement>) => {        
            const {name, value} = e.target;
            this.setState({...this.state, [name]:value});
        }

        const handleConfirm = () => {
            const {login, password} = this.state;

            const saveTokenResponse = (response:Auth) =>{
                saveToken(response.token);
                this.props.loggingIn(response);
                this.props.history.push('/');
            }
            const handlingError = () =>{
                this.setState({...this.state, status:LoggingStatus.LOGGING_ERROR});
            }
            const formData = new FormData();

            formData.append('login', login);
            formData.append('password', password);

            //Faz o post e retorna o auth token, ou não
            api.post<Auth>(
                '/auth/login',
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data'}
                }).then(reponse => {
                saveTokenResponse(reponse.data);
            }).catch(function (response){
                handlingError();
            });
        }

        const handleOnKeyPressLogin = (event:React.KeyboardEvent<HTMLInputElement>)=>{
            if(event.charCode == 13){
                handleConfirm();
                event.preventDefault();
            };
        }
        
        return (
            
            <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                {(this.state.status == LoggingStatus.LOGGING_ERROR) &&
                    <CRow className="justify-content-center">
                        <CCol md="8">
                            <CAlert color="danger" closeButton>
                                Credenciais inválidas, favor tente novamente!
                            </CAlert>
                        </CCol>
                    </CRow>
                }

                <CRow className="justify-content-center">
                    <CCol md="8">
                        <CCardGroup>
                        <CCard className="p-4">
                            <CCardBody>
                            <CForm>
                                <h1>Login</h1>                                
                                <CInputGroup className="mb-3">
                                    <CInputGroupPrepend>
                                        <CInputGroupText>
                                            <CIcon name="cil-user" />
                                        </CInputGroupText>
                                    </CInputGroupPrepend>
                                    <CInput name="login" onChange={onChangeInput} type="text" placeholder="Login" autoComplete="login" />
                                </CInputGroup>
                                <CInputGroup className="mb-4">
                                    <CInputGroupPrepend>
                                        <CInputGroupText>
                                            <CIcon name="cil-lock-locked" />
                                        </CInputGroupText>
                                    </CInputGroupPrepend>
                                    <CInput name="password" onKeyPress={handleOnKeyPressLogin} onChange={onChangeInput} type="password" placeholder="Password" autoComplete="current-password" />
                                </CInputGroup>
                                <CRow>
                                    <CCol xs="6">
                                        <CButton onClick={handleConfirm} color="primary" className="px-4">Login</CButton>
                                    </CCol>                            
                                </CRow>
                            </CForm>
                            </CCardBody>
                        </CCard>                   
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {   
        user:state.login.data?.user
    };
}

const mapDispatchToProps = (dispatch : Dispatch) => (
    bindActionCreators(actions, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Login) ;