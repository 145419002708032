import React from 'react';
import ReactDOM from 'react-dom';
import {icons as iconsTeste}  from './assets/icons';
import App from './App';

interface React {
  icons: any;
}
   // @ts-ignore
React.icons = iconsTeste;

ReactDOM.render(
  <React.StrictMode>  
        <App />    
  </React.StrictMode>,
  document.getElementById('root')
);
