import {ContentRoute} from "../../../models/Route";
import OperationList from "./operationList";
import OperationForm from "./operationForm";
import enumDescription from "../../../services/enumDescription";
import OperationPropagateForm from "./operationPropagateForm";

export enum TypeOperation {
    TYPE_CALL_URL = 'call_url',
    TYPE_INPUT_DATA  = 'input_data',
    TYPE_CLICK  = 'click',
    TYPE_CLOSE = 'close',
    TYPE_VALIDATE_MESSAGE = 'validate_message',
    TYPE_WAIT = 'wait',
    TYPE_EXECUTE_SCRIPT = 'execute_script'
}

export enum TypeTerm {
    TYPE_CLASS = 'class',
    TYPE_NAME = 'name',
    TYPE_CSS = 'css',
    TYPE_XPATH = 'xpath'
}

export const TypeOperationList = [
    {value: TypeOperation.TYPE_CALL_URL, description: 'Acessar Url'},
    {value: TypeOperation.TYPE_INPUT_DATA, description: 'Informar Valor (input data)'},
    {value: TypeOperation.TYPE_CLICK, description: 'Clicar (click component)'},
    {value: TypeOperation.TYPE_VALIDATE_MESSAGE, description: 'Validar Mensagem (validate test)'},
    {value: TypeOperation.TYPE_WAIT, description: 'Aguardar em Segundos (wait)'},
    {value: TypeOperation.TYPE_CLOSE, description: 'Finalizar (close window)'},
    {value: TypeOperation.TYPE_EXECUTE_SCRIPT, description: 'Executar Script'}
];

// @ts-ignore
export const ListTypes = new enumDescription( TypeOperationList );

export const TypeTermList = [
    {value: TypeTerm.TYPE_CLASS, description: 'Class'},
    {value: TypeTerm.TYPE_NAME, description: 'Name'},
    {value: TypeTerm.TYPE_CSS, description: 'Css'},
    {value: TypeTerm.TYPE_XPATH, description: 'xPath'}
];

export const operation_content_routes: Array<ContentRoute> = [
    {
        'path':'/operation',
        'description': 'Operações',
        'exact':true,
        'component': OperationList,
        'menu': true
    },
    {
        'path':'/operation/incluir',
        'description': 'Nova Operação',
        'exact':true,
        'component': OperationForm,
        'menu': false
    },
    {
        'path':'/operation/alterar/:id',
        'description': 'Alterar Operação',
        'exact':true,
        'component': OperationForm,
        'menu': false
    },
    {
        'path':'/operation/alterar/duplicar/:id',
        'description': 'Duplicar Operação',
        'exact':true,
        'component': OperationForm,
        'menu': false
    },
    {
        'path':'/operation/propagar/:id',
        'description': 'Alterar Operação',
        'exact':true,
        'component': OperationPropagateForm,
        'menu': false
    },
]