import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';
import {ListTypes} from "./index";
import enumDescription from "../../../services/enumDescription";


const OperationList = (props:any) => {
    const TypeListFormater = (params: any) => {
        return ListTypes.getDescription(params.value);
    }

        const actions: Array<ActionProps> = [
            {
                route: 'operation/incluir',
                color: 'success',
                type: 'normal',
                title: 'Incluir'
            },
            {
                route: 'operation/alterar',
                color: 'warning',
                type: 'grid',
                title: 'Alterar'
            },
            {
                route: 'operation/delete',
                color: 'danger',
                type: 'delete',
                title: 'Excluir'
            },
            {
                route:'operation/alterar/duplicar',
                color:'primary',
                type:'grid',
                title:'Duplicar'
            },
            {
                route: 'operation/propagar',
                color: 'info',
                type: 'grid',
                title: 'Propagar Cadastro'
            }
        ];

        return (
            <Grid actions={actions} rota="operation/list">
                <AgGridColumn sort="desc" width={100} headerName="#" checkboxSelection field="id" sortable={true} filter={'agNumberColumnFilter'}></AgGridColumn>
                <AgGridColumn flex={1} minWidth={200} headerName="Nome" field="op_name" sortable={true} filter={true}></AgGridColumn>
                <AgGridColumn flex={1} minWidth={200} headerName="Tipo da Operação" field="type" sortable={false} filter={FiltroTipo} valueFormatter={TypeListFormater}></AgGridColumn>
            </Grid>
        );
    };

    class FiltroTipo extends ListaMultiplaFilter {
        getList(): enumDescription {
            return ListTypes;
        }
    }
export default OperationList;