import React, {useState, useEffect} from 'react';
import useCustomForm from "../../../services/useCustomForm";
import {
    CFormGroup,
    CInput,
    CTabs,
    CTabContent,
    CTabPane,
    CTextarea,
    CLabel, CNav, CNavItem, CNavLink, CButton, CCol, CRow, CFormText, CCardText, CCallout
} from "@coreui/react";
import Form, {FormApi} from "../../../components/form";
import CIcon from "@coreui/icons-react";
import AceEditor from "react-ace-builds";
import "react-ace-builds/webpack-resolver-min";
import {TypeGroupTestList} from "./index";
import Select from "react-select";
import {listRegimeTributacaoFiltro} from "../../clients";


const GroupTesteForm = () => {

    const initialValues: any = {
        id: null,
        name: '',
        type: '',
    }


    const {
        values,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        setValues
    } = useCustomForm(
        {
            initialValues,
            route: 'group-teste',
            onSubmit: values => submitValues(values.values),
            onLoadDadosCompleted: (values) => {
                //console.log(values);
            }
        }
    );
    const [formApi, setFormApi] = useState<FormApi>();
    const [tipo, setTipo] = useState('');

    const handleChangeType = (value: any) => {
        let tipoNum = value['value']
        setTipo( value['label'])
        if (tipoNum) {
            values.type = tipoNum;
            setValues({...values,['type']: values.type});
        }
    }

    useEffect(() => {
        setTipo( values.type)
    }, [values.type]);

    useEffect(() => {
        if (values.type){
          if (values.type == 1){
              setTipo('Caso de Teste');
          }else
              setTipo('Execução Teste');
      }
    }, [tipo]);

    const submitValues = (values: any,) => {
        handleSubmitValues(values);
    }

    const handleFormReady = (formApi: FormApi) => {
        setFormApi(formApi);
    }

    return (
        <Form entity={'Grupo de Teste ' + values.id} handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CFormGroup>
                <CLabel htmlFor="name">Nome Grupo de Teste</CLabel>
                <CInput type="text" id="name" name="name" maxLength={100} onChange={handleChange} value={values.name}
                        required/>
                <br/>
                <CLabel htmlFor="name">Tipo do Grupo de Teste</CLabel>
                    <Select
                        options={TypeGroupTestList}
                        placeholder={tipo}
                        onChange={handleChangeType}
                        closeMenuOnSelect={true}
                        className={'filtro-select'}
                    ></Select>
            </CFormGroup>


        </Form>
)
}

export default GroupTesteForm;