import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';
import {SituacaoList, SituacaoTypes} from './index'
import enumDescription from "../../../services/enumDescription";
import {GridApi} from "ag-grid-community";


const ClienteList = (props:any) => {

    const situacaoListFormater  = (params:any) =>{
        return SituacaoList.getDescription(params.value);
    }

    const handleGridReady = (gridApi:GridApi)=>{
        const filterInstance = gridApi.getFilterInstance('situacao');
        filterInstance?.setModel({
            type: 'in',
            value: [SituacaoTypes.ATIVO, SituacaoTypes.EM_PROJETO]
        });
        gridApi.onFilterChanged();
    }

    const actions: Array<ActionProps> = [
        {
            route:'cliente/incluir',
            color:'success',
            type:'normal',
            title:'Incluir'
        },
        {
            route:'cliente/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'cliente/visualizar',
            color:'info',
            type:'grid',
            title:'Visualizar',
        },
        {
            route:'cliente/delete',
            color:'danger',
            type:'delete',
            title:'Excluir',
        },
        {
            route:'cliente/produto',
            color:'info',
            type:'grid',
            title:'Cliente x Produto',
        },
        {
            route:'cliente-objeto-contrato',
            color:'primary',
            type:'grid',
            title:'Objetos de Contrato',
        }
    ]; 
    
    return (
        <Grid actions={actions} rota="cliente/list" handleGridReady={handleGridReady} >
            <AgGridColumn headerName="#" width={90} checkboxSelection field="id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn headerName="Nome"  flex={1}  field="pessoa/nome"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn headerName="Nome Fantasia"  flex={1}  field={"pessoa/nomeFantasia"}  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn headerName="CPF/CNPJ" width={200} field={"pessoa/cpfCnpj"}  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn sort="asc" headerName="Situação" width={200} field={"situacao"}  sortable={ true } filter={ FiltroSituacao }  valueFormatter={situacaoListFormater}></AgGridColumn>
            <AgGridColumn headerName="Data Homologação" width={200} field={"dataHomologacao"}  sortable={ true } filter={ 'agDateColumnFilter'}></AgGridColumn>
        </Grid>
    );
};

class FiltroSituacao extends ListaMultiplaFilter{
    getList(): enumDescription {
        return SituacaoList;
    }
}
export default ClienteList;