import {Reducer} from "redux";

import {AlertsState,ActionsTypes} from './types'

const reducer: Reducer<AlertsState> = (state = {alerts:[]}, action) => {
    let newAlerts = Array.from(state.alerts);
    switch (action.type){
        case ActionsTypes.ADD_ALERT:
            newAlerts.push({...action.payload, id:Math.random()});

            return {alerts:newAlerts};
        case ActionsTypes.REMOVE_ALERT:

            let alertIndex = newAlerts.findIndex((alert)=>alert.id === action.payload);
            alertIndex !==  -1 && newAlerts.splice(alertIndex,1);

            return {alerts:newAlerts};
        case ActionsTypes.CLEAR_ALERTS:
            return {alerts:[]}
        default:
            return state
    }
}

export default reducer;