import enumDescription from "../../services/enumDescription";
import {ContentRoute} from "../../models/Route";
import ChangeLogList from "./changeLogList";
import ChangeLogForm from "./changeLogForm";
import {PessoaContatoFormData, TipoContatoTypes} from "../cadastros/pessoa";
import PessoaForm from "../cadastros/pessoa/pessoaForm";



export enum ChangeLogTypes {
    FEATURES = 1,
    BUG      = 2
}

export interface ChangeLogDataFormData {
    type:number
    description:string
}

export const ChangeLogTypeList = new enumDescription(  [
    {   value: ChangeLogTypes.FEATURES, description: 'Funcionalidade'    },
    {   value: ChangeLogTypes.BUG, description: 'Bug'    }
]);

export interface ChangeLogFormData {
    id: number,
    version: string,
    changeLog: Array<ChangeLogDataFormData>
}

const changelog_content_routes: Array<ContentRoute> = [
    {
        'path':'/changelog',
        'description': 'Loglist',
        'exact':true,
        'component': ChangeLogList,
        'menu': true
    },
    {
        'path':'/changelog/add',
        'description': 'Change Log',
        'exact':true,
        'component': ChangeLogForm,
        'menu': false
    },
    {
        'path':'/changelog/alterar/:id',
        'description': 'Change Log',
        'exact':true,
        'component': ChangeLogForm,
        'menu': false
    },
    {
        'path':'/changelog/visualizar/:id',
        'description': 'Change Log',
        'exact':true,
        'component': ChangeLogForm,
        'menu': false
    }
]

export default changelog_content_routes;