import {ContentRoute} from "../../../models/Route";
import TestCaseList from "./testCaseList";
import TestCaseForm from "./testCaseForm";


export const test_case_content_routes: Array<ContentRoute> = [
    {
        'path':'/test-case',
        'description': 'Casos de Teste',
        'exact':true,
        'component': TestCaseList,
        'menu': true
    },
    {
        'path':'/test-case/incluir',
        'description': 'Novo Caso de Teste ',
        'exact':true,
        'component': TestCaseForm,
        'menu': false
    },
    {
        'path':'/test-case/alterar/:id',
        'description': 'Alterar Caso de Teste',
        'exact':true,
        'component': TestCaseForm,
        'menu': false
    },
    {
        'path':'/test-case/alterar/duplicar/:id',
        'description': 'Copiar Caso de Teste',
        'exact':true,
        'component': TestCaseForm,
        'menu': false
    }


]