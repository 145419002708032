//Acoes
import { Auth } from '../../../models/Auth'

export enum LoginTypes {
    LOGGING_IN = "@login/LOGGING_IN",
    LOGGING_OFF = "@login/LOGGING_OFF",
    LOGGING_RETRIEVING = "@login/LOGGING_RETRIEVING",
}

export enum StatusTypes {
    LOGGED = "@login/STATUS_LOGGED",
    UNLOGGED = "@login/STATUS_UNLOGGED",
    RETRIEVING = "@login/STATUS_RETRIEVING",
}

export interface LoginState {
    readonly data : Auth | null,
    readonly status : string
}