import React, {useState, useEffect} from 'react';
import useCustomForm from '../../services/useCustomForm'
import {
    CFormGroup,
    CLabel,
    CInput,
    CSelect, CButton, CTextarea,
} from '@coreui/react';
import {ChangeLogFormData, ChangeLogDataFormData, ChangeLogTypeList} from "./index";
import Form, {FormApi} from "../../components/form";
import useCurrentAction from "../../services/useCurrentAction";
import CIcon from "@coreui/icons-react";
import RichTextEditor from "../../components/RichTextEditor";

const ChangeLogForm = ()=>{

    const [formApi, setFormApi] = useState<FormApi>();
    const action = useCurrentAction();

    const getInitialValueChangeLog = () =>{
        return {
            type:1,
            description:'',
        };
    }

    const initialValues:ChangeLogFormData = {
        id:0,
        version:'',
        changeLog:[getInitialValueChangeLog()]
    }

    const {
        values,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        handleClickMinus,
        handleClickPlus,
        setValues
    } = useCustomForm(
        {
            initialValues,
            route:'changelog',
            onSubmit: values => handleSubmitValues(values.values),
        }
    );

    const isView = () => {
        return action === 'view';
    };

    const handleFormReady = (formApi:FormApi)=>{
        setFormApi(formApi);
    }

    return (
        <Form entity="ChangeLog" handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CFormGroup>
                <CLabel htmlFor="version">Versão</CLabel>
                <CInput readOnly={isView()} disabled={isView()} type="text" id="version" name="version" maxLength={120} onChange={handleChange} value={values.version} required/>
            </CFormGroup>
            <CFormGroup>
                <CLabel htmlFor="date">Data Publicação</CLabel>
                <CInput readOnly={isView()} disabled={isView()} type="date" id="date" name="date" maxLength={120} onChange={handleChange} value={values.date} required/>
            </CFormGroup>
            <fieldset>
                <legend>Logs da Versão</legend>
            {values.changeLog && values.changeLog.map((changeLog:ChangeLogDataFormData, index:number)=>(
                <div className="border-bottom mb-2" key={index}>
                    <CFormGroup>
                        <CSelect readOnly={isView()} disabled={isView()} name="type" id="type"  onChange={event=>handleChange(event, index, 'changeLog')} value={values.changeLog[index].type}>
                            {ChangeLogTypeList.getList().map(item=>(
                                <option key={item.value} value={item.value}>{item.description}</option>
                            ))}
                        </CSelect>
                    </CFormGroup>
                    <CFormGroup>
                        <RichTextEditor
                            onChange={(value: any) => {
                                setValues((previousValue: any) => {
                                    let newValue = previousValue.changeLog;
                                    newValue[index].description = value;
                                    return {...previousValue, changeLog: newValue}
                                });
                            }}
                            value={values.changeLog[index].description}
                        />
                    </CFormGroup>
                    <div hidden={isView()}>
                        <CFormGroup row className="justify-content-end">
                            <CButton color="light" className="mr-1 small btn-sm" ><CIcon name="cil-plus" onClick={event=>handleClickPlus(getInitialValueChangeLog, 'changeLog')}/></CButton>
                            <CButton color="light" className="small btn-sm"><CIcon name="cil-minus" onClick={event=>handleClickMinus(index, getInitialValueChangeLog, 'changeLog')} /></CButton>
                        </CFormGroup>
                    </div>
                </div>
            ))}
            </fieldset>



        </Form>
    )
}
export default ChangeLogForm;