import axios, {AxiosBasicCredentials} from 'axios';
import {retrieveToken} from '../services/tokenVerify';

const api = axios.create({
    baseURL: process.env.REACT_APP_HOST,
});

export const getApi = function (){
    const token = retrieveToken();
    let url = process.env.REACT_APP_HOST;
    const api = axios.create({
        baseURL: url,
        params: {_system_token: token, XDEBUG_SESSION_START:13360}
    });

    return api;
}

export default api;