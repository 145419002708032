import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../components/grid';
import { ActionProps } from '../../components/gridAction';
import enumDescription from "../../services/enumDescription";
import {NotificacaoSituacaoList, NotificacaoSituacao} from './index';
import {GridApi} from "ag-grid-community";

const NotificacaoList = (props:any) => {

    /*const situacaoListFormater  = (params:any) =>{
        return SituacaoList.getDescription(params.value);
    }*/

    const handleGridReady = (gridApi:GridApi)=>{
        /*const filterInstance = gridApi.getFilterInstance('situacao');
        filterInstance?.setModel({
            type: 'in',
            value: [NotificacaoSituacao.SITUACAO_ENVIAR, NotificacaoSituacao.SITUACAO_ABERTO, NotificacaoSituacao.SITUACAO_ERRO]
        });
        gridApi.onFilterChanged();*/
    }

    const actions: Array<ActionProps> = [
        {
            route:'notificacao/add',
            color:'success',
            type:'normal',
            title:'Novo',
        },
        {
            route:'notificacao/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'notificacao/visualizar',
            color:'info',
            type:'grid',
            title:'Visualizar',
        },
        {
            route:'notificacao/log',
            color:'info',
            type:'grid',
            title:'Log de Envio',
        },
        {
            route:'notificacao/liberar',
            color:'success',
            type:'delete',
            title:'Liberar para Envio',
        }
    ];

    const defaultColumnDefinition = {
        resizable: true,
        flex: 1,
        filter: true,
        sortable: true
    };

    return (
        <Grid actions={actions} rota="notificacao/list" handleGridReady={handleGridReady} defaultColDef={defaultColumnDefinition}>
            <AgGridColumn sort="desc" checkboxSelection headerName="#" field="id" ></AgGridColumn>
            <AgGridColumn minWidth={150} headerName="Título" field="titulo" ></AgGridColumn>
            <AgGridColumn headerName="Descrição" field="descricao" cellRenderer={({value}) => `${value}`} ></AgGridColumn>
            <AgGridColumn minWidth={100} headerName="Situação" field="situacao" filter={ FiltroSituacao } valueFormatter={situacaoListFormater} ></AgGridColumn>
            <AgGridColumn minWidth={100} headerName="Data/Hora de Envio" field="dataHoraEnvio" ></AgGridColumn>
            <AgGridColumn minWidth={100} headerName="Usuário" field="usuario" ></AgGridColumn>
        </Grid>
    );
};

class FiltroSituacao extends ListaMultiplaFilter{
    getList(): enumDescription {
        return NotificacaoSituacaoList;
    }
}

const situacaoListFormater  = (params:any) =>{
    return NotificacaoSituacaoList.getDescription(params.value);
}

const formatterHtml = (params:any) => {
    return params.value;

}

export default NotificacaoList;