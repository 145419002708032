import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';
import {TypeGroupTestListt, TypeGroupTestListUse} from "./index";
import enumDescription from "../../../services/enumDescription";


const GroupTesteList = (props:any) => {

    const TypeListFormater = (params: any) => {
        return TypeGroupTestListt.getDescription(params.value);
    }

    const actions: Array<ActionProps> = [
        {
            route:'group-teste/incluir',
            color:'success',
            type:'normal',
            title:'Incluir'
        },
        {
            route:'group-teste/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'group-teste/delete',
            color:'danger',
            type:'delete',
            title:'Excluir'
        }
    ];


    return (
        <Grid actions={actions} rota="group-teste/list">
            <AgGridColumn sort="desc" width={100} headerName="#" checkboxSelection field="id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn flex={1} minWidth={200} headerName="Nome" field="name"  sortable={ true } filter={true}></AgGridColumn>
            <AgGridColumn flex={1} minWidth={200}  headerName="Tipo" field="type"  sortable={ true } filter={FiltroTipo} valueFormatter={TypeListFormater}></AgGridColumn>
        </Grid>
    );
};
    class FiltroTipo extends ListaMultiplaFilter {
        getList(): enumDescription {
            return TypeGroupTestListt;
        }
    }
export default GroupTesteList;