import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { contentRoutes } from '../../contentRoutes';

const Breadcrumb = () => {
    const location = useLocation();
    const findRoute = (path: string, routes: any[]): any => {
        const parts = path.split('/');
        if (!isNaN(Number(parts[parts.length - 1]))) {
            parts[parts.length - 1] = '*';
            path = parts.join('/');
        }
        for (let route of routes) {
            let routePath = '';
            let partsRouter: any = route.path ? route?.path.split('/') : '';
            if (partsRouter[partsRouter.length-1] === ':id'){
                routePath = route?.path.replace(/:\w+/g, '*');
            }else{
                routePath = route?.path
            }
            if (routePath === path) {
                return route;
            }
            if (route.children) {
                const childRoute = findRoute(path, route.children);
                if (childRoute) return childRoute;
            }
        }
        return null;
    };

    const generateBreadcrumbs = () => {
        const breadcrumbs: JSX.Element[] = [];
        const paths = location.pathname.split('/').filter(x => x);
        let currentPath = '';

        paths.forEach((path, index) => {
            currentPath += `/${path}`;
            const route = findRoute(currentPath, contentRoutes);
            if (route) {
                breadcrumbs.push(
                    <li key={index}>
                        <Link to={currentPath}>{route.description}</Link>
                    </li>
                );
            }
        });

        return breadcrumbs;
    };

    return (
        <nav>
            <ul style={{display: 'flex', listStyleType: 'none', padding: 0, margin: 0}}>
                {generateBreadcrumbs().map((breadcrumb, index) => (
                    <React.Fragment key={index}>
                        <li
                            style={{
                                color: index === generateBreadcrumbs().length - 1 ? '#000' : '#007bff',
                                fontWeight: index === generateBreadcrumbs().length - 1 ? 'bold' : 'normal',
                                cursor: index < generateBreadcrumbs().length - 1 ? 'pointer' : 'default',
                                textDecoration: index < generateBreadcrumbs().length - 1 ? 'underline' : 'none',
                                marginRight: '8px',
                            }}
                        >
                            {breadcrumb}
                        </li>
                        {index < generateBreadcrumbs().length - 1 && (
                            <span style={{marginRight: '8px', color: '#6c757d'}}>/</span>
                        )}
                    </React.Fragment>
                ))}
            </ul>
        </nav>
    );
};

export default Breadcrumb;