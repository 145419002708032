import React, {useEffect, useState} from 'react';
import {getApi} from "../../../apis/backendApis";
import CIcon from '@coreui/icons-react';
import "./Styles.scss";
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CCollapse, CInput,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle, CNav, CNavItem, CNavLink,
    CRow, CSelect, CTabContent, CTabPane, CTabs
} from "@coreui/react";
import {Bar} from 'react-chartjs-2';
import {
    dataBaseSprint,
    dataBaseAtividade,
    resolutionScreen,
    optionsHoras,
    optionsRetorno,
    dataBaseDev,
    optionsDesenvolvidosEstimados
} from "./index";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import useAlert from "../../../services/useAlert";
import Select from "react-select";

ChartJS.register(
    Title,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Tooltip,
    Legend
);

const SprintList = (props: any) => {

    const [loadingData, setLoandingDados] = useState(false);
    const initialValues: any = [];
    const [showAlert] = useAlert();
    const [dataResponseSprint, setData] = useState(initialValues);
    const [dadosAtividadeMembros, setDadosAtividadeMembros] = useState(initialValues);
    const [dadosSprintMembros, setDadosSprintMembros] = useState(initialValues);
    const [dataResponseAtividade, setDataAtividade] = useState(initialValues);
    const [dataLinearAtividade, setDataLinearAtividade] = useState({initialValues, datasets: []});
    const [dataLinearSprint, setDataLinearSprint] = useState({initialValues, datasets: []});
    const [dataLinearSprintDev, setDataLinearSprintDev] = useState({initialValues, datasets: []});
    const [afetado, setAfetado] = useState(true);
    const [visible, setVisible] = useState<number | null>(-1);
    const [visible1, setVisible1] = useState<number | null>(null);
    const [uptade, setUpdate] = useState(false);
    const [resultion, setResultion] = useState(false);
    const [indexI, setIndex] = useState<number | null>(0);
    const [showModal, setShowModal] = useState(false);
    const [dataResultLog, setDataResultLog] = useState(initialValues);
    const [rowEquals, setRowEquals] = useState<any>();
    const [listSprints, setListSprints] = useState<[]>([]);
    const [valueFilter, setValueFilter] = useState([]);
    let var1 = -1
    let var2 = -2

    const loadingoff = () => {
        setUpdate(false);
    }
    useEffect(() => {
        setAfetado(true);
        if (afetado) {
            setUpdate(true);
            atualizaGraficoSprint();
            atualizaGraficoAtividade();
            setResultion(resolutionScreen);
            getListSprints();
        }
    }, [afetado]);

    const updateData = () => {
        setAfetado(false)
    }

    const porcentagem = (parcial: any, total: any) => {

        if (parcial && total != 0) {
            return Math.round((parcial * 100) / total);
        } else {
            return 0;
        }

    }

    const getListSprints = () =>{
        let api = getApi();
        api.get(`get/list/sprints`).then((response) => {
            setListSprints(response.data)
            setValueFilter(response.data[0])
            handleOptionSprint(response.data[0])
        });
    }

    const horasMais = (horasTrabalhadas: any, horasEstimadas: any) => {
        return Math.round(horasEstimadas - horasTrabalhadas);
    }
    const atualizaGraficoAtividade = () => {
        setDataLinearAtividade(initialValues)
        let api = getApi();
        api.get(`retorno-atividade/list/`).then((response) => {
            setDataAtividade(response.data)
            //@ts-ignore
            setDataLinearAtividade(dataBaseAtividade(response.data));
            loadingoff();
        }).catch(() => {
            showAlert({
                title: 'Não foi possível acessar a requisição.',
                // @ts-ignore
                msg: error.toString(),
                toaster: false,
            });
        });
    }

    const atualizaGraficoSprint = () => {
        setDataLinearSprint(initialValues)
        let api = getApi();
        api.get(`sprints/list/`).then((response) => {
            setData(response.data)
            //@ts-ignore
            setDataLinearSprint(dataBaseSprint(response.data));
            loadingoff();
        }).catch((error) => {
            showAlert({
                title: 'Não foi possível acessar a requisição.',
                // @ts-ignore
                msg: error.toString(),
                toaster: false,
            });
        });
    }

    const buscarSprintMembro = (sprint: any) => {
        setLoandingDados(true);
        let api = getApi();
        api.get(`busca-equipe/list/` + sprint).then((response) => {
            setDadosSprintMembros(response.data)
            setLoandingDados(false)
            loadingoff();
        }).catch((error) => {
            showAlert({
                title: 'Não foi possível acessar a requisição.',
                // @ts-ignore
                msg: error.toString(),
                toaster: false,
            });
        });
    }
    const buscarAtividadeMembro = (sprint: any, index: number) => {
        if (rowEquals == index){
            return
        }
        setRowEquals(index);
        setLoandingDados(true);
        let api = getApi();
        api.get(`retorno-atividade-membro/list/` + sprint).then((response) => {
            setDadosAtividadeMembros(response.data)
            setLoandingDados(false)
        }).catch((error) => {
            showAlert({
                title: 'Não foi possível acessar a requisição.',
                // @ts-ignore
                msg: error.toString(),
                toaster: false,
            });
        });
    }


    const onClickDetalhes = (sprint: any, idUser: any) => {
        let api = getApi();
        api.get(`detalhes/list/` + sprint + `?membro=` + idUser).then((response) => {
            setDataResultLog(response.data);
            setShowModal(true);
        }).finally(() => {

        });
    }

    const onCloseModal = () => {
        setShowModal(false);
    }

    const accordionSprint = (index: number) => {
        setVisible(visible === index ? null : index)
    }
    const accordionAtividade = (index: number) => {
        setVisible1(visible1 == index ? null : index)
    }

    const AccordionDetalhes = (index: number) => {
        setIndex(indexI == index ? null : index)
    }

    const handleOptionSprint = (option : any)=>{
        let idSprint = option.value;
        setValueFilter(option)
        let api = getApi();
        api.get(`busca-equipe/list/` + idSprint).then((response) => {
            //@ts-ignore
            setDataLinearSprintDev(dataBaseDev(response.data))
        })
    }

    return (<div className={' w-100 mt-2'}>
            <div className={'flex-row text-right'}>
                <CButton className={'btn-info m-1 '} onClick={updateData}>
                    {uptade && <span className="spinner-border spinner-border-sm mr-2" role="status"
                                     aria-hidden="true"></span>}
                    Atualizar
                </CButton>
            </div>

            <div className={'bg-white w-100 mt-2 p-3'}>
                <div className={'m-3 card shadow-sm p-4 rounded-lg'}>
                    <div className={resultion ? '' : 'p-5 pt-lg-0 pb-lg-0 '}>
                        <Bar width="1200" height="350" options={optionsHoras} data={dataLinearSprint}></Bar>
                    </div>
                </div>
                <div className={'m-3 card shadow-sm p-4 rounded-lg'}>
                    <div className={resultion ? '' : 'p-5 pt-lg-0 pb-lg-0 '}>
                        <Bar width="1200" height="350" options={optionsRetorno} data={dataLinearAtividade}></Bar>
                    </div>
                    <div className={'text-muted execute-task-items col-md-12'}>
                        <br/>
                        <div className={"border-left font-lg border-right border-top mt-1 mb-3"}>
                            <div className="row m-0 bg-gray-200 p-2 ">
                                <div className={'col-11 cursor-pointer p-2 font-weight-bold text-dark mb-0 '}
                                     onClick={() => AccordionDetalhes(var2)}>Detalhes Atividades x Retornos Qualidade
                                </div>
                                <div onClick={() => AccordionDetalhes(var2)}
                                     className={'col-1 text-lg-right cursor-pointer font-weight-bold '}>
                                    <CIcon name="cilChevronCircleDownAlt"></CIcon></div>
                            </div>
                            <div className='border-bottom'/>
                            <CCollapse className={'collapse-var2'} show={indexI == var2}>
                                <div>
                                    <CRow>
                                        <div className="col-12">
                                            <div className="row font-weight-bold border-bottom m-0">
                                                <div className={resultion ? 'text-center col p-2 pt-4' : 'p-2 col'}>
                                                    Sprint
                                                </div>
                                                <div
                                                    className={resultion ? 'p-2 col border-left text-center pt-4' : 'p-2 col border-left text-center'}>
                                                    Total de Atividades
                                                </div>
                                                <div
                                                    className={resultion ? 'col border-left text-center pt-4' : 'p-2 col border-left text-center'}>
                                                    Total de Atividades Retornadas
                                                </div>
                                                <div
                                                    className={resultion ? 'col border-left text-center pt-4' : 'p-2 col border-left text-center'}>
                                                    Índice Retorno
                                                </div>
                                            </div>
                                        </div>
                                    </CRow>
                                    {dataResponseAtividade.map((result: any, index: number) => (
                                        <div key={index}>
                                            <div className={'detalhes-retorno-atividade'} key={index} color="primary"
                                                 onClick={() => accordionAtividade(index)}>
                                                <div
                                                    className={"text-hover-sprints row border-bottom m-0 cursor-pointer"}
                                                    onClick={() => buscarAtividadeMembro(result['sprint'], index)}>
                                                    <div className={'col d-flex'}>
                                                        <div className={'pt-2 '}>
                                                            {result['sprint']}
                                                        </div>
                                                    </div>
                                                    <div className={'col text-center border-left'}>
                                                        {result['total']}
                                                    </div>
                                                    <div className={' col text-center border-left'}>
                                                        {result['totalRetorno']}&nbsp;
                                                        ({porcentagem(result['totalRetorno'], result['total'])}%)
                                                    </div>
                                                    <div className={'col text-center border-left'}>
                                                        {result['indiceRetorno'] != null && !isNaN(result['indiceRetorno'])
                                                            ? parseFloat(result['indiceRetorno']).toLocaleString('pt-BR', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })
                                                            : 'N/A'}
                                                    </div>
                                                </div>
                                            </div>
                                            <CCollapse className={'border-bottom pb-4 pt-4'} show={visible1 == index}>
                                                <div className="col-12 p-0">
                                                <div className={loadingData ? " spinner-border" : ""}></div>
                                                    {!loadingData &&
                                                        <div className="col-12 p-0 ">
                                                            {dadosAtividadeMembros && dadosAtividadeMembros.map((membro: any, indexMembro: number) => (
                                                                <div
                                                                    className={((indexMembro % 2) === 0) ? 'row m-0 detalhes  bg-light' : 'row  m-0 detalhes'}
                                                                    key={indexMembro}>
                                                                    <div
                                                                        className="text-hover col-12 align-content-around"
                                                                        onClick={() => onClickDetalhes(result['sprint'], membro['id_membro'])}
                                                                        key={indexMembro}>
                                                                        <div
                                                                            className={" row cursor-pointer"}>
                                                                            <div
                                                                                className={resultion ? 'col overflow-hidden border-right' : " col text-left  border-right"}>
                                                                                {membro.nome}
                                                                            </div>
                                                                            <div className="col text-center ">
                                                                                {membro.taskTotais}
                                                                            </div>
                                                                            <div
                                                                                className="col text-center border-left">
                                                                                {membro.taskRecusadas ? membro.taskRecusadas : 0}
                                                                                &nbsp;({porcentagem(membro.taskRecusadas, membro.taskTotais)}%)
                                                                            </div>
                                                                            <div
                                                                                className="col text-center border-left">
                                                                                {typeof membro.indiceRetorno === 'number'
                                                                                    ? membro.indiceRetorno.toLocaleString('pt-BR', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                    : parseFloat(membro.indiceRetorno).toLocaleString('pt-BR', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }
                                                </div>
                                            </CCollapse>
                                        </div>
                                    ))}
                                </div>
                            </CCollapse>
                        </div>
                    </div>
                </div>
                <div className="m-3 card shadow-sm p-4 rounded-lg">
                    <div className="row bg-gray-200 ml-3 mr-3 p-2 align-items-center">
                        <div className="col-md-9">
                            <h5 className="font-weight-bold text-dark mb-0">
                                Estimado x Desenvolvido (h)
                            </h5>
                        </div>
                        <div className="col-md-3 text-dark">
                            <Select
                                className=""
                                options={listSprints}
                                onChange={handleOptionSprint}
                                value={valueFilter}
                                placeholder="Selecione a Sprint"
                            />
                        </div>
                    </div>
                    <div className={`chart-container mt-3 ${resultion ? '' : 'p-5 pt-lg-0 pb-lg-0'}`}>
                        <Bar
                            width={1200}
                            height={350}
                            options={optionsDesenvolvidosEstimados}
                            data={dataLinearSprintDev}
                        />
                    </div>
                </div>
            </div>

            <div className={'p-4'}/>
            <CModal
                show={showModal}
                onClosed={onCloseModal}
                backdrop={true}
                color="info"
                size={'lg'}
            >
                <CModalHeader closeButton>
                    {dataResultLog.length > 0 && (
                        <CModalTitle className={"col-10"}>Detalhes Tasks
                            #{dataResultLog[0]['destino']}
                            <p className={'col-3 float-right text-sm-right font-italic font-sm'}>Sprint
                                #{dataResultLog[0]['name']}</p></CModalTitle>)}
                </CModalHeader>
                <CModalBody>
                    <div className={'row border-bottom border-top'}>
                        <div className={"col-3 text-center pt-1"} aria-hidden={true}>
                            Task
                        </div>
                        <div className={"p-2 col-3  border-left text-center pt-1"} aria-hidden={true}>
                            Quem Rejeitou
                        </div>
                        <div className={"p-2 col-3 border-left text-center pt-1"} aria-hidden={true}>
                            Vezes Rejeitada
                        </div>
                        <div className={"p-2 col-3 border-left text-center pt-1"} aria-hidden={true}>
                            Horas Trabalhadas
                        </div>
                    </div>
                    {dataResultLog.map((result: any, index: number) => (
                            <div
                                className={((index % 2) === 0) ? 'row border-bottom border-0 detalhes bg-gray-100' : 'row border-bottom detalhes'}
                                key={index}>
                                <div className={"col-3 text-center pt-1"} aria-hidden={true}>
                                    <a href={`https://redmine.panoramasistemas.com.br/issues/${result.id_issue}`}
                                       target="_blank">#{result['id_issue']}</a>
                                </div>
                                <div className={" col-3 border-left"} aria-hidden={true}>
                                    {result['user_origem']}
                                </div>
                                <div className={" col-3 border-left"} aria-hidden={true}>
                                    {result['amount_recused']}
                                </div>
                                <div className={" col-3 border-left text-center text-hidden"} aria-hidden={true}>
                                    {result['horas_trabalhadas'] !? parseFloat(result['horas_trabalhadas']).toFixed(2) : 0}
                                </div>
                            </div>
                        )
                    )}
                </CModalBody>
            </CModal>
        </div>
    );
};
export default SprintList;