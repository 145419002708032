import React, {useEffect, useState} from "react";
import {FormApi} from "../../components/form";
import useAlert from "../../services/useAlert";
import useCustomForm from "../../services/useCustomForm";
import {getApi} from "../../apis/backendApis";
import {
    CButton,
    CFormGroup,
    CInput,
    CLabel,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CTabContent,
    CTabPane,
    CTabs,
    CTextarea
} from "@coreui/react";
import './style.css'
import Select from "react-select";
import CIcon from "@coreui/icons-react";
import {SituacaoAgendamento} from "../programacao";

interface ModalProps {
    show: boolean;
    onClosed: () => void;
    itemChecklist: any;
    idTask: any;
}

interface setor {
    id: number,
    nome: string,
    usuarios: any[]
}

const TaskModalAgenda: React.FC<ModalProps> = ({show, onClosed, itemChecklist, idTask}) => {

    const [modalShow, setModalShow] = useState(show);
    const [listResponsaveis, setListResponsaveis] = useState<any[]>([]);
    const [listSetor, setListSetor] = useState<any[]>([]);
    const [listUser, setListUser] = useState<any[]>([]);
    const [formApi, setFormApi] = useState<FormApi>();
    const [success, setSuccess] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [tempoEstimado, setTempoEstimado] = useState();
    const [error, setError] = useState(false);
    const [showAlert] = useAlert();

    const initialValues: any = {
        setor: '',
        usuario: '',
        situacao: '',
        dataHoraInicio: '',
        dataHoraFim: '',
        checklistItem: itemChecklist.id,
        task: idTask,
        cnpj: ''
    };

    const {
        setValues,
        values,
        handleSubmitValues,
    } = useCustomForm(
        {
            initialValues,
            route: 'task-modal-agenda',
            onSubmit: values => {
                let newValues = {...values.values};
                handleSubmitValues(newValues, values.filesUpload)
            },
        }
    );

    useEffect(() => {
        let isMounted = true;
        const api = getApi();
        api.get('/setor/suggest').then((response) => {
            setListSetor(getListSelectSetor(response.data['setores']));
            setListUser(response.data['setores']);
        });
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        let isMounted = true;
        const api = getApi();
        api.get(`/checklist-agenda/get/${idTask}/${itemChecklist.id}`).then((response) => {
            setLoaded(true)
            if (response.data['success']) {
                values.id = response.data['body']['id'];
                values.cnpj = response.data['body']['cnpj-cliente'];
                values.setor = response.data['body']['setor'];
                values.usuario = response.data['body']['usuario'];
                values.situacao = response.data['body']['situacao'];
                values.observacao = response.data['body']['observacao'];
                values.responsavel = response.data['body']['responsavel'];
                values.dataHoraFim =  response.data['body']['dataFim'];
                values.issueRedmine =  response.data['body']['issue'];
                values.dataHoraInicio =  response.data['body']['dataInicio'];
                values.horasEstimadas =   response.data['body']['horasEstimadas'];
                setSuccess(true);
                const user = {
                    value: response.data['body']['setor'],
                    label: ''
                };
                if (values.dataHoraFim)
                    getListUserSetorHandleSetor(user);
            } else {
                values.cnpj = response.data['body']['cnpj-cliente'];
                setSuccess(false);
            }
        });
        return () => {
            isMounted = false;
        };
    }, [listUser]);

    const validaCampos = () => {
        setLoaded(false);
        let errors = [];
        let resolve: boolean;
        if (!(values.setor > 0)) {
            errors.push('Setor');
        }
        if (!(values.dataHoraInicio.toString().length > 0)) {
            errors.push('Data/Hora Início');
        }
        if (errors.length > 0) {
            showAlert({
                title: 'Existem campos não preenchidos.',
                msg: 'Informe os campo(s): ' + errors.join(', ') + '.',
                type: 'danger',
                fnOk: () => {
                    setLoaded(true);
                }
            });
            resolve = false;
        } else {
            resolve = true;
        }
        return resolve;
    }

    const edit = (isEnvioRedimine: boolean) => {
        if (validaCampos()) {
            values.isEnvioRedmine = isEnvioRedimine;
            setValues({...values})
            setLoaded(false);
            let formData = new FormData();
            formData.append('data', JSON.stringify(values));
            let api = getApi();
            api.request({
                url: '/checklist-agenda/edit/' + values.id,
                method: "post",
                data: formData
            }).then((response) => {
                if (response.data['success']) {
                    setLoaded(true);
                    showAlert({
                        msg: response.data['msg'],
                        toaster: true,
                    });
                    handleClose();
                } else {
                    showAlert({
                        title: 'Ocorreu um problema!',
                        msg: response.data['msg'],
                        type: 'danger',
                        fnOk: () => {
                            setLoaded(true);
                        }
                    });
                }
            })
        }
    }

    const abreAgendamento = (isEnvioRedimine: boolean) => {
        if (validaCampos()) {
            values.isEnvioRedmine = isEnvioRedimine;
            setValues({...values})
            setLoaded(false);
            let valuesString = JSON.stringify(values);
            let formData = new FormData();
            formData.append('data', valuesString);
            let api = getApi();
            api.request({
                url: '/checklist-agenda/add',
                method: "post",
                data: formData
            }).then((response) => {
                if (response.data['success']) {
                    setLoaded(true);
                    showAlert({
                        msg: response.data['msg'],
                        toaster: true,
                    });
                    handleClose();
                } else {
                    showAlert({
                        title: 'Ocorreu um problema!',
                        msg: response.data['msg'],
                        type: 'danger',
                        fnOk: () => {
                            setLoaded(true);
                        }
                    });
                }
            });
        }
    }

    const calculateHoursDifference = (): any => {
        const HORA_COMECA_TRABALHO = 8;
        const HORA_TERMINA_TRABALHO = 18;
        const COMECA_HORARIO_ALMOCO = 12;
        const TERMINA_HORARIO_ALMOCO = 13;
        const TERMINA_HORARIO_ALMOCO_MINUTO = 12;
        const startDateTime = new Date(values?.dataHoraInicio);
        const endDateTime = new Date(values?.dataHoraFim);
        let totalMinutes = 0;
        let currentDateTime = new Date(startDateTime);
        while (currentDateTime < endDateTime) {
            const currentDay = currentDateTime.getDay(); // 0 = Domingo, 6 = Sábado
            const currentHour = currentDateTime.getHours();
            const currentMinute = currentDateTime.getMinutes();
            if (currentDay !== 0 && currentDay !== 6) {
                if (currentHour >= HORA_COMECA_TRABALHO && currentHour < HORA_TERMINA_TRABALHO) {
                    if (!(currentHour === COMECA_HORARIO_ALMOCO && currentMinute >= 0 && currentMinute < 60) &&
                        !(currentHour === TERMINA_HORARIO_ALMOCO && currentMinute < TERMINA_HORARIO_ALMOCO_MINUTO)) {
                        totalMinutes++;
                    }
                }
            }
            currentDateTime.setMinutes(currentDateTime.getMinutes() + 1);
        }
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        values.horasEstimadas = `${hours}:${minutes.toString().padStart(2, '0')}`;
        setValues({...values});
    }

    const cancelamentoAgendamento = () => {
        /* let valuesString = JSON.stringify(values);
         let formData = new FormData();
         formData.append('data', valuesString);
         let api = getApi();
         api.request({
             url: '/redmine/delete-issue/' + values.issueRedmine,
             method: "delete",
             data: formData
         }).then((response) => { */
        setLoaded(false)
        let valuesString = JSON.stringify(values);
        let formData = new FormData();
        formData.append('data', valuesString);
        let api = getApi();
        api.request({
            url: '/checklist-agenda/delete/' + values.id,
            method: "delete",
            data: formData
        }).then((response) => {
            if (response.data['msg']) {
                showAlert({
                    msg: 'O agendamento foi cancelado!',
                    toaster: true,
                });
                handleClose();
            }
            /* }).catch((error) => {
                 showAlert({
                     title: 'Algo deu errado!',
                     msg: error.toString(),
                     type: 'danger',
                 });
                 setModalShow(false);
             }); */
        })
    }

    const getListSelectSetor = (list: any[]) => {
        let data: any[] = [];
        list.forEach((setor) => {
            data.push({
                label: setor['nome'],
                value: setor['id']
            });
        });
        return data;
    }

    const getListUserSetorHandleSetor = (selectedOption: any) => {
        const setorId = selectedOption.value;
        let setor : setor = {id: 0, nome: '', usuarios: []};
        listUser.forEach((setorFind) => {
            if(setorFind['id'] == setorId){
                setor = setorFind;
                return;
            }
        })
        if (setor && setor['usuarios']) {
            let listResponsaveis : any[] = [];
            listResponsaveis.push({label: 'Nenhum', value: ''});
            setor['usuarios'].forEach((user: any) => {
                listResponsaveis.push({label: user['nome'], value: user['id']});
                return ;
            });
            setListResponsaveis(listResponsaveis);
            values.setor = selectedOption.value
            setValues({...values})
        } else {
            setListResponsaveis([]);
        }
    }

    const handleClose = () => {
        setModalShow(false);
        onClosed();
    };

    const handleUsuario = (selectedOption: any) => {
        values.usuario = selectedOption.value
        setValues({...values})
    }

    const handleDataHoraInicio = (value: any) => {
        if (values?.dataHoraFim){
            const dataInicio = new Date(value)
            const dataFim = new Date(values?.dataHoraFim)
            if (dataFim < dataInicio){
                setError(true);
                return;
            }
        }
        setError(false);
        values.dataHoraInicio = value
        setValues({...values})
        values.dataHoraFim && calculateHoursDifference();
    }

    const handleObservacao = (value: any) => {
        values.observacao = value
        setValues({...values})
    }

    const handleDataHoraFim = (value: any) => {
        if (values?.dataHoraInicio){
            const dataInicio = new Date(values?.dataHoraInicio)
            const dataFim = new Date(value)
            if (dataFim < dataInicio){
                setError(true);
                return;
            }
        }
        setError(false);
        values.dataHoraFim = value
        setValues({...values})
        values.dataHoraInicio && calculateHoursDifference();
    }

    const handleResponsavel = (value: any) => {
        values.responsavel = value
        setValues({...values})
    }
    const handleTempoEstimado = (value: any) => {
        values.horasEstimadas = value;
        setValues({...values})
    }
    const handleFormReady = (formApi: FormApi) => {
        setFormApi(formApi);
    }

    const AbrirFecharAgendamento = (isFechar: boolean) => {
        const api = getApi();
        setLoaded(false)
        const fechar: any = { fechar: isFechar };
        const params = new URLSearchParams(fechar).toString();
        api.request({
            url: `checklist-agenda/abrirFechar/${values.id}?${params}`,
            method: "get",
        }).then((response) => {
            if (response.data.success) {
                showAlert({
                    msg: response.data.msg,
                    toaster: true,
                    type: 'success',
                });
            }
            handleClose();
        })
    }

    return (
        <CModal
            show={modalShow}
            onClose={handleClose}
            backdrop={true}
            color="info"
            size={'lg'}
        >
            <CModalHeader closeButton>
                <CModalTitle>Agendar Checklist: {itemChecklist.nome}</CModalTitle>
            </CModalHeader>
            <CModalBody className={!loaded ? 'modal-body-agenda-loading' : ''}   >
                {!loaded ?
                    <div className="d-flex bg-gray-100 flex-grow-1 align-content-center justify-content-center">
                        <div className={'d-flex align-items-center justify-content-center align-items-center'}>
                            <div className="spinner-border mr-2 mb-2" role="status"></div>
                        </div>
                    </div> : (<div className="p-2">
                    <div>
                        <CTabs>
                            <CTabContent>
                                <CTabPane>
                                    <CFormGroup>
                                        <CLabel className={'mt-2 text-danger'}>Selecione o Setor</CLabel>
                                        <Select
                                            options={listSetor}
                                            value={listSetor?.find((setor: any) => setor.value == values['setor']) ?? ''}
                                            onChange={getListUserSetorHandleSetor}
                                            loadingMessage={() => "Carregando os setores"}
                                            noOptionsMessage={() => "Não foi possível carregar os setores disponíveis"}
                                            isDisabled={values?.issueRedmine > 0}
                                        />
                                    </CFormGroup>
                                    <CFormGroup>
                                        <CLabel className={'mt-2'}>Selecione o Responsável</CLabel>
                                        <Select
                                            options={listResponsaveis}
                                            value={listResponsaveis.find((setor: any) => setor?.value == values['usuario']) ?? ''}
                                            onChange={handleUsuario}
                                            loadingMessage={() => "Carregando os responsáveis"}
                                            noOptionsMessage={() => "Não foi possível carregar os usuários"}
                                            isDisabled={values?.issueRedmine > 0}
                                        />
                                    </CFormGroup>
                                    <CFormGroup className={'row'}>
                                        <div className={'col text-danger'}>
                                            <CLabel htmlFor="dataHoraInicio">Data/Hora Inicio</CLabel>
                                            {//@ts-ignore
                                                <CInput onChange={(e) => handleDataHoraInicio(e.target.value)}
                                                        value={values.dataHoraInicio ?? ''}
                                                        type="datetime-local" id="dataHoraInicio"
                                                        name="dataHoraInicio"
                                                        readOnly={values?.issueRedmine > 0}
                                                />
                                            }
                                        </div>
                                        <div className={'col'}>
                                            <CLabel htmlFor="dataHoraFim">Data/Hora Fim</CLabel>
                                            {//@ts-ignore
                                                <CInput onChange={(e) => handleDataHoraFim(e.target.value)}
                                                        value={values.dataHoraFim ?? ''}
                                                        type="datetime-local" id="dataHoraFim"
                                                        name="dataHoraFim"
                                                        className={error ? 'border-danger text-danger' : ''}
                                                        readOnly={values?.issueRedmine > 0}
                                                />
                                            }
                                            {error ? (
                                                <div className={'text-danger'}>Data final não pode ser menor que a data inicial.</div>
                                            ): (<></>)}
                                        </div>
                                            <div className={'col-3'}>
                                                <CLabel>Horas Estimadas</CLabel>
                                                {
                                                    //@ts-ignore
                                                    <CInput width={'10px'} value={values.horasEstimadas ?? ''} readOnly={values?.issueRedmine > 0} onChange={(e) => handleTempoEstimado(e.target.value)}></CInput>
                                                }
                                            </div>
                                    </CFormGroup>
                                    <CFormGroup>
                                        <CLabel className={'mt-2'}>Informações do Responsável no Cliente (nome, contato)</CLabel>
                                        {//@ts-ignore
                                            <CInput onChange={(e) => handleResponsavel(e.target.value)}
                                                    value={values.responsavel ?? ''}
                                                    type="text" id="responsavel"
                                                    name="responsavel"
                                                    readOnly={values?.issueRedmine > 0}
                                            />
                                        }
                                    </CFormGroup>
                                    <CFormGroup >
                                        <CLabel  className={'mt-2'}>Observação</CLabel>
                                        &nbsp;<CIcon name="cil-speech"  title={"Negrito: **, Exemplo: **Sample Text** "} className="mr-1 text-primary"/>
                                        {//@ts-ignore
                                            <CTextarea onChange={(e) => handleObservacao(e.target.value)}
                                                       value={values.observacao ?? ''}
                                                       type="text" id="responsavel"
                                                       name="responsavel"
                                                       readOnly={values?.issueRedmine > 0}
                                            />
                                        }
                                    </CFormGroup>
                                </CTabPane>
                            </CTabContent>
                        </CTabs>
                    </div>
                </div>)}
            </CModalBody>
            {success && (
                <div className={'row d-flex justify-content-end'}>
                    {values.situacao == SituacaoAgendamento.SITUACAO_ABERTO ? (
                            <div className="d-flex justify-content-end x">
                                <CButton disabled={!loaded} onClick={() => AbrirFecharAgendamento(true)}
                                         className="btn btn-primary mr-2 mb-2">
                                    Fechar Agendamento
                                </CButton>
                            </div>
                        )
                        :
                        <div className="d-flex justify-content-end x ">
                            <CButton disabled={!loaded} onClick={() => AbrirFecharAgendamento(false)}
                                     className="btn btn-primary mr-2 mb-2">
                                Abrir Agendamento
                            </CButton>
                        </div>
                    }
                    {(!values.issueRedmine) && (
                    <div className="d-flex justify-content-end x">
                        <CButton disabled={!loaded} onClick={()=>edit(true)} className="btn btn-facebook mr-2 mb-2">
                            Enviar Redmine
                        </CButton>
                    </div>
                    )}
                    <div className={`d-flex justify-content-end ${values.issueRedmine ? 'mr-3' : ''}`}>
                        <CButton disabled={!loaded} onClick={cancelamentoAgendamento} className="btn btn-danger mr-2 mb-2">
                            Cancelar Agendamento
                        </CButton>
                    </div>
                    {(!values.issueRedmine) && (
                        <div className="d-flex justify-content-end x mr-3">
                            <CButton disabled={!loaded} onClick={()=>edit(false)} className="btn btn-primary mr-2 mb-2">
                                Salvar
                            </CButton>
                        </div>
                    )}

                </div>

            )
            }
            {!success &&
                (<div className={'row d-flex justify-content-end'}>
                    <div className="d-flex justify-content-end x">
                        <CButton disabled={!loaded} onClick={() => abreAgendamento(true)} className="btn btn-pinterest mr-2 mb-2">
                            Salvar e Enviar Redmine
                        </CButton>
                    </div>
                    <div className="d-flex justify-content-end mr-3">
                        <CButton disabled={!loaded} onClick={()=>abreAgendamento(false)} className="btn btn-primary mr-2 mb-2">
                            Salvar
                        </CButton>
                    </div>
                </div>)
            }
        </CModal>

    );
}
export default TaskModalAgenda;