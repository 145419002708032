import enumDescription from "../../services/enumDescription";
import {ContentRoute} from "../../models/Route";
import Tasklist from "./taskList";
import TaskForm from "./taskForm";
import TaskExecuteForm from "./taskExecuteForm";

export interface TaskFormData {
    descricao:string,
    cliente:object
    id?: number,
    situacao?:number,
    responsavel?:string,
    taskChecklist: Array<ChecklistFormData>,
}

export interface ChecklistFormData {
    checklist:number,
    nome:number,
    ativa:boolean,
    previsao:string,
    draggId:string
}

export enum SituacaoTypes {
    PENDENTE         = 1,
    EM_EXECUCAO      = 2,
    FINALIZADA       = 3,
    CANCELADA        = 4,
}

export const SituacaoList = new enumDescription(  [
    {   value: SituacaoTypes.PENDENTE, description: 'Pendente'    },
    {   value: SituacaoTypes.EM_EXECUCAO, description: 'Em Execução'    },
    {   value: SituacaoTypes.FINALIZADA, description: 'Finalizada'    },
    {   value: SituacaoTypes.CANCELADA, description: 'Cancelada'    },
]);

const task_content_routes: Array<ContentRoute> = [
    {
        'path':'/task',
        'description': 'Checklist',
        'exact':true,
        'component': Tasklist,
        'menu': true
    },
    {
        'path':'/task/incluir',
        'description': 'Incluir Atividade',
        'exact':true,
        'component': TaskForm,
        'menu': false
    },
    {
        'path':'/task/alterar/:id',
        'description': 'Alterar Atividade',
        'exact':true,
        'component': TaskForm,
        'menu': false
    },
    {
        'path':'/task/visualizar/:id',
        'description': 'Visualizar Atividade',
        'exact':true,
        'component': TaskForm,
        'menu': false
    },
    {
        'path':'/task/executar/:id',
        'description': 'Executar Atividade',
        'exact':true,
        'component': TaskExecuteForm,
        'menu': false
    }
]

export default task_content_routes;