import React from "react";

const Loading = () =>{
    return (
        <div className="justify-content-center loading-admin d-flex align-items-center align-content-center">
            <div className="spinner-container">
                <div className="spinner-border" role="status">
                </div>
            </div>
        </div>
    )
}

export default Loading