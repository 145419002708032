import React, { Component } from 'react';
import { Route, Switch , HashRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Login from './pages/login';
import Home from './pages/home';

class Routes extends Component  {
    render () {
        return (
            <HashRouter>
                <Switch>
                    <Route component={Login} path="/login" exact/>
                    <Route component={Home} path="/"/>
                </Switch>
            </HashRouter>
        )
    }
}

export default connect()(Routes);