export enum ActionsTypes{
    ADD_ALERT    = 'ADD_ALERT',
    REMOVE_ALERT = 'REMOVE_ALERT',
    CLEAR_ALERTS = 'CLEAR_ALERTS'
}

export interface AlertType {
    msg:string,
    title?:string,
    type?: 'danger' | 'warning' | 'success',
    toaster?:boolean,
    id?:number
    details?:string,
    fnOk?:any
}

export interface AlertsState {
    readonly alerts:Array<AlertType>
}