import UsuarioList from './usuarioList';
import {ContentRoute} from "../../../models/Route";
import UsuarioForm from "./usuarioForm";
import enumDescription from "../../../services/enumDescription";
import {TipoAnexoTypes} from "../cliente";


export enum AtivoTypes {
    NAO  = 0,
    SIM  = 1,
}

export const AtivoList = new enumDescription(  [
    {   value: AtivoTypes.SIM, description: 'Sim'    },
    {   value: AtivoTypes.NAO, description: 'Não'    },
]);

export interface UsuarioFormData {
    email:string,
    senha:string
    pessoa:Object,
    ativo:boolean
}

export const usuario_content_routes: Array<ContentRoute> = [
    {
        'path':'/usuario',
        'description': 'Usuário',
        'exact':true,
        'component': UsuarioList,
        'menu': true
    },
    {
        'path':'/usuario/incluir',
        'description': 'Incluir Usuário',
        'exact':true,
        'component': UsuarioForm,
        'menu': false
    },
    {
        'path':'/usuario/alterar/:id',
        'description': 'Alterar Usuário',
        'exact':true,
        'component': UsuarioForm,
        'menu': false
    },
    {
        'path':'/usuario/visualizar/:id',
        'description': 'Visualizar Usuário',
        'exact':true,
        'component': UsuarioForm,
        'menu': false
    }
]