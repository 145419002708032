import React, {useState, useEffect} from 'react';
import useCustomForm from "../../../services/useCustomForm";
import {
    CFormGroup,
    CInput,
    CTabs,
    CTabContent,
    CTabPane,
    CTextarea,
    CLabel, CNav, CNavItem, CNavLink, CButton, CCol, CRow, CFormText, CCardText, CCallout
} from "@coreui/react";
import Form, {FormApi} from "../../../components/form";
import CIcon from "@coreui/icons-react";
import AceEditor from "react-ace-builds";
import "react-ace-builds/webpack-resolver-min";
import {set} from "js-cookie";
import useAlert from "../../../services/useAlert";
import {DinamicTypesFisico, DinamicTypesJuridico, TypeMockup, TypeMockupEnum} from "./index";
import Select from "react-select";

const MockupForm = () => {
    const mockupData = [
        {"name": 'cliente_nome', "label": 'Nome Cliente'}
        , {"name": 'cliente_codigo', "label": 'Cliente Código'}
        , {"name": 'cliente_url', "label": 'Url Site'}
        , {"name": 'produto_checkout', "label": 'Produto Checkout'}
        , {"name": 'login_valido', "label": 'Login'}
        , {"name": 'senha_valido', "label": 'Senha'}
        , {"name": 'login_invalido', "label": 'Login Inválido'}
        , {"name": 'senha_invalido', "label": 'Senha Inválida'}

        , {"name": 'cadastro_cpf_cliente_valido', "label": 'CPF'}
        , {"name": 'cadastro_cpf_cliente_invalido', "label": 'CPF Inválido'}
        , {"name": 'cadastro_nome_cliente', "label": 'Nome Cliente'}
        , {"name": 'cadastro_nome_cliente_invalido', "label": 'Nome Cliente Inválido'}
        , {"name": 'cadastro_data_nascimento_cliente', "label": 'Data Nascimento'}
        , {"name": 'cadastro_data_nascimento_cliente_invalida', "label": 'Data Nascimento Inválido'}
        , {"name": 'cadastro_email_cliente', "label": 'E-mail'}
        , {"name": 'cadastro_email_cliente_invalido', "label": 'E-mail Inválido'}
        , {"name": 'cadastro_senha_cliente', "label": 'Cadastro Cliente - Senha'}
        , {"name": 'cadastro_senha_cliente_invalido', "label": 'Cadastro Cliente - Senha'}

        , {"name": 'compra_cadastro_cliente_cpf', "label": 'Cadastro Cliente - CPF'}
        , {"name": 'compra_cadastro_cliente_nome', "label": 'Cadastro Cliente - Nome'}
        , {"name": 'compra_cadastro_cliente_telefone', "label": 'Cadastro Cliente - Telefone'}
        , {"name": 'compra_cadastro_cliente_email', "label": 'Cadastro Cliente - E-mail'}
        , {"name": 'compra_cadastro_cliente_senha', "label": 'Cadastro Cliente - Senha'}
        , {"name": 'compra_cadastro_cliente_cep', "label": 'Cadastro Cliente - CEP'}
        , {"name": 'compra_cadastro_cliente_endereco', "label": 'Cadastro Cliente - Endereço'}
        , {"name": 'compra_cadastro_cliente_numero_endereco', "label": 'Cadastro Cliente - Numero Endereço'}
        , {"name": 'compra_cadastro_cliente_bairro', "label": 'Cadastro Cliente - Bairro'}
        , {"name": 'compra_cadastro_cliente_complemento_referencia', "label": 'Cadastro Cliente - Referência'}
    ];

    const initialValues: any = {
        id: null,
        name: '',
        mockup: []
    }


    const {
        values,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        setValues
    } = useCustomForm(
        {
            initialValues,
            route: 'mockup',
            onSubmit: values => submitValues(values.values),
            onLoadDadosCompleted: (values) => {
                //console.log(values);
            }
        }
    );
    const [formApi, setFormApi] = useState<FormApi>();
    const [jsonValue, setJsonValue] = useState('');
    const [tipo, setTipo] = useState('');
    const [showAlert] = useAlert();

    useEffect(() => {
        setJsonValue(JSON.stringify(values.mockup, null, 2));
        const itemType = TypeMockupEnum.findSelectValue(values.type);
        if (itemType) {
            handleChangeType(itemType);
        }
    }, [values.mockup, values.type]);

    const handleChangeMockup = (event: any) => {
        let val = event.target.value;
        if (val) {
            values.mockup[event.target.name] = event.target.value;
            setValues({...values, ['mockup']: values.mockup});
        }
    }


    const handleChangeMockupPreview = () => {
        try {
            let parseValue = JSON.parse(jsonValue);
            setValues({...values, mockup: parseValue});
        } catch (error) {
            showAlert({
                title: 'Erro - JSON inválido',
                // @ts-ignore
                msg: error.toString(),
                toaster: false,
            });
         }
    };
    const submitValues = (values: any,) => {
        try {
        values['mockup'] = JSON.parse(jsonValue);
        handleSubmitValues(values);
        } catch (error) {
            showAlert({
                title: 'Erro - JSON inválido',
                // @ts-ignore
                msg: error.toString(),
                toaster: false,
            });
        }
    }

    const handleChangeType = (value: any) => {
        let tipoNum = value['value']
        setTipo(value['label'])
        if (tipoNum) {
            values.type = tipoNum;
            setValues({...values, ['type']: values.type});
        }
    }

    const handleFormReady = (formApi: FormApi) => {
        setFormApi(formApi);
    }

    const setValue = (newValue: any) => {
        setJsonValue(newValue);
    }
    return (
        <Form entity={'Mockup de Teste ' + values.id} handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CFormGroup>
                <CLabel htmlFor="name">Nome Mockup</CLabel>
                <CInput type="text" id="name" name="name" maxLength={100} onChange={handleChange} value={values.name}
                        required/>
            </CFormGroup>
            <CLabel htmlFor="name">Tipo do Mockup</CLabel>
            <Select
                options={TypeMockupEnum.getSelectList()}
                placeholder={tipo}
                onChange={handleChangeType}
                closeMenuOnSelect={true}
                className={'filtro-select'}
                required
            ></Select>
            <CFormGroup>
                <CLabel htmlFor="name">Identificador do Mockup</CLabel>
                <CInput type="text" id="code" name="code" maxLength={100} onChange={handleChange} value={values.code}
                        required={values.type == TypeMockup.TYPE_MOCK_RESPONSE}/>
            </CFormGroup>
            <CRow className={'mb-2'}>
                <CCol className={'d-flex align-items-center'}>
                    <details className={'w-100'}>
                        <summary><strong className={'font-2xl'}>Tipos Dinâmicos</strong></summary>
                        <CRow>
                            <CCol>
                                <div className={'text-justify p-1'}>
                                    <CCallout color="info">
                                        <p>
                                            Quando informado uma tag dinâmica para um dos tipo CPF ou CNPJ, o sistema
                                            assume esse tipo como escopo de dados dinâmicos.<br/>
                                            Por exemplo, se a última tag dinâmica for do tipo CPF, então os dados
                                            dinâmicos disponíveis são baseados em uma pessoa física.<br/>
                                            Por outro lado, se o tipo informado for CNPJ, assume-se como dados dinâmicos
                                            uma pessoa jurídica.<br/>
                                            Caso não possua uma das tags informadas, como padrão assume-se o tipo CPF
                                            (dados de pessoa física).
                                        </p>
                                    </CCallout>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol className={'text-center'}>
                                <h4>Tabela de Tipos Dinâmicos</h4>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="6">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">Física</th>
                                        <th scope="col">Json</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {DinamicTypesFisico.map((item, index) => (
                                        <tr key={index}>
                                            <td className={'p-1'}>
                                                {item.description}
                                            </td>
                                            <td className={'p-1'}>
                                                <pre className={'mb-0'}>{' {"dinamic": true,"type": "' + item.value + '"}'}</pre>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </CCol>
                            <CCol md="6" key={Math.random()}>
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">Jurídica</th>
                                        <th scope="col">Json</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {DinamicTypesJuridico.map((item, index) => (
                                        <tr key={index}>
                                            <td className={'p-1'}>
                                                {item.description}
                                            </td>
                                            <td className={'p-1'}>
                                                <pre
                                                    className={'mb-0'}>    {' {"dinamic": true,"type": "' + item.value + '"}'}</pre>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </CCol>
                        </CRow>
                    </details>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <div className={'text-right mb-1 mr-0'}><CButton color={'success'} onClick={handleChangeMockupPreview}>Formatar
                        Json</CButton></div>
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12">
                </CCol>
                <CCol sm="12">
                    <AceEditor
                        mode="json"
                        theme="github"
                        showGutter={true}
                        showPrintMargin={true}
                        highlightActiveLine={false}
                        width={''}
                        fontSize={15}
                        value={jsonValue}
                        setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                        onChange={setValue}
                        name="UNIQUE_ID_OF_DIV"
                    />

                </CCol>
            </CRow>
        </Form>


    )
}

export default MockupForm;