import React, {useState, useEffect} from 'react';
import {
    CFormGroup,
    CLabel,
    CInput,
    CCard,
    CCardBody,
    CTabs,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CButton,
    CSelect,
    CInputCheckbox,
    CCol,
    CInvalidFeedback,
    CValidFeedback,

} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import useCustomForm from '../../../services/useCustomForm';
import Form, {FormApi} from '../../../components/form';
import CpfCnpj from "../../../components/cpfCnpj";
import AsyncSelect from 'react-select/async';
import {
    PessoaFormData,
    PessoaContatoFormData,
    TipoPessoaList,
    GeneroPessoaList,
    TipoContatoList,
    SetorList,
    TipoEnderecoList,
    GeneroPessoaTypes,
    TipoPessoaTypes,
    TipoContatoTypes,
    TipoEnderecoTypes, PessoaEnderecoFormData,
} from './index';
import {getApi} from "../../../apis/backendApis";
import useCurrentAction from "../../../services/useCurrentAction";

const PessoaForm = (props:any) => {

    const [formApi, setFormApi] = useState<FormApi>();
    const action = useCurrentAction();

    const getInitialValueContato = () =>{
        return {
            tipo:TipoContatoTypes.CELULAR,
            contato:'',
            setor: -1
        };
    }

    const getInitialValueEndereco = () =>{
        return {
            tipo:TipoEnderecoTypes.RESIDENCIAL,
            logradouro:''
        };
    }

    const initialValues:PessoaFormData = {
        tipo:TipoPessoaTypes.FISICA,
        nome:undefined,
        cpfCnpj:'',
        nomeFantasia:'',
        contribuinteICMS:false,
        ie:'',
        rg:'',
        dataNascimento:'',
        genero:GeneroPessoaTypes.MASCULINO,
        pessoaContato:[getInitialValueContato()],
        pessoaEndereco:[getInitialValueEndereco()]
    }

    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        handleChangeSuggest,
        handleClickMinus,
        handleClickPlus,
    } = useCustomForm({
            initialValues,
            route:'pessoa',
            onSubmit: values => {
                let newContatos = values.values.pessoaContato.filter((pessoaContato:PessoaContatoFormData) => {
                    return pessoaContato.contato && pessoaContato.contato !== '';
                })

                let newEnderecos = values.values.pessoaEndereco.filter((pessoaEndereco:PessoaEnderecoFormData) => {
                    return pessoaEndereco.cidade && pessoaEndereco.logradouro && pessoaEndereco.logradouro !== '';
                })
                handleSubmitValues({...values.values, pessoaEndereco:newEnderecos, pessoaContato:newContatos})
            },
            onLoadDadosCompleted: data=>onLoadPessoaDadosCompleted(data),
        },
    );

    const onLoadPessoaDadosCompleted = (data:PessoaFormData) =>{
        let pessoaEnderecoNew = data.pessoaEndereco.map((pessoaEndereco:any)=>{
            return {...pessoaEndereco, cidade: {value: pessoaEndereco.cidade.id , label: pessoaEndereco.cidade.nome }}
        });
        if(pessoaEnderecoNew.length == 0){
            pessoaEnderecoNew = [getInitialValueEndereco()];
        }

        let pessoaContatoNew = data.pessoaContato;
        if(pessoaContatoNew.length == 0){
            pessoaContatoNew = [getInitialValueContato()];
        }
        setValues({...data, pessoaEndereco: pessoaEnderecoNew, pessoaContato:pessoaContatoNew});
    }

    const promiseOptions = (inputValue:string):Promise<any> => {
        let api = getApi();
        return api.get(`/cidade/suggest/${inputValue}`).then(response=>response.data);
    }

    const isView = () => {
        return action === 'view';
    };

    const handleFormReady = (formApi:FormApi)=>{
        setFormApi(formApi);
    }
    return (
        <Form handleSubmit={handleSubmit} entity="Pessoa" onFormReady={handleFormReady}>
            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>
                            Geral
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>
                            Contatos
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>
                            Endereços
                        </CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <CCard>
                            <CCardBody>
                                <CFormGroup>
                                    <CLabel htmlFor="tipo">Tipo</CLabel>
                                    <CSelect readOnly={isView()} disabled={isView()} name="tipo" id="tipo" onChange={handleChange} value={values.tipo}>
                                        {TipoPessoaList.getList().map(item=>(
                                            <option key={item.value} value={item.value}>{item.description}</option>
                                        ))}
                                    </CSelect>
                                </CFormGroup>
                                <CFormGroup>
                                    <CLabel htmlFor="nome">Nome</CLabel>
                                    <CInput readOnly={isView()} disabled={isView()} type="text" id="nome" name="nome" onChange={handleChange} value={values.nome} required/>
                                    <CInvalidFeedback className="help-block" htmlFor="nome">
                                        Informar o nome
                                    </CInvalidFeedback>
                                    <CValidFeedback className="help-block" htmlFor="nome">ok</CValidFeedback>
                                </CFormGroup>
                                <CFormGroup>
                                    <CLabel htmlFor="cpfCnpj">{values.tipo == TipoPessoaTypes.FISICA ? 'CPF' : 'CNPJ'}</CLabel>
                                    <CpfCnpj readOnly={isView()} disabled={isView()} required type="text" id="cpfCnpj" name="cpfCnpj" onChange={handleChange} value={values.cpfCnpj} />
                                </CFormGroup>
                                { values.tipo == TipoPessoaTypes.JURIDICA && (
                                    <>
                                        <CFormGroup>
                                            <CLabel htmlFor="nomeFantasia">Nome Fantasia</CLabel>
                                            <CInput readOnly={isView()} disabled={isView()} required type="text" id="nomeFantasia" name="nomeFantasia" onChange={handleChange} value={values.nomeFantasia} />
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="ie">IE</CLabel>
                                            <CInput readOnly={isView()} disabled={isView()} type="text" id="ie" name="ie" onChange={handleChange} value={values.ie} />
                                        </CFormGroup>
                                        <CFormGroup row>
                                            <CCol className="offset-1">
                                                <CInputCheckbox
                                                    id="contribuinteICMS"
                                                    name="contribuinteICMS"
                                                    checked={values.contribuinteICMS}
                                                    onChange={handleChange}
                                                    readOnly={isView()}
                                                    disabled={isView()}
                                                />
                                                <CLabel variant="checkbox" htmlFor="contribuinteICMS" >Contribuinte ICMS</CLabel>
                                            </CCol>
                                        </CFormGroup>
                                    </>
                                )}
                                { values.tipo == TipoPessoaTypes.FISICA && (
                                    <>
                                        <CFormGroup>
                                            <CLabel htmlFor="ie">RG</CLabel>
                                            <CInput readOnly={isView()} disabled={isView()} type="text" id="rg" name="rg" onChange={handleChange} value={values.rg} />
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="dataNascimento">Data Nascimento</CLabel>
                                            <CInput readOnly={isView()} disabled={isView()} type="date" id="dataNascimento" name="dataNascimento" onChange={handleChange} value={values.dataNascimento} />
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="genero">Gênero</CLabel>
                                            <CSelect readOnly={isView()} disabled={isView()} name="genero" id="genero" onChange={handleChange} value={values.genero}>
                                                {GeneroPessoaList.getList().map(item=>(
                                                    <option key={item.value} value={item.value}>{item.description}</option>
                                                ))}
                                            </CSelect>
                                        </CFormGroup>
                                    </>
                                )}
                            </CCardBody>
                        </CCard>
                    </CTabPane>
                    <CTabPane>
                        {values.pessoaContato && values.pessoaContato.map((contato:PessoaContatoFormData, index:number)=>(
                            <CCard key={index}>
                                <CCardBody>
                                    <CCard>
                                        <CCardBody>
                                            <CFormGroup row>
                                                <CCol className="col-6">
                                                    <CLabel htmlFor="tipo">Tipo</CLabel>
                                                    <CSelect readOnly={isView()} disabled={isView()} name="tipo" id="tipo"  onChange={event=>handleChange(event, index, 'pessoaContato')} value={values.pessoaContato[index].tipo}>
                                                        {TipoContatoList.getList().map(item=>(
                                                            <option key={item.value} value={item.value}>{item.description}</option>
                                                        ))}
                                                    </CSelect>
                                                </CCol>
                                                <CCol className="col-6">
                                                    <CLabel htmlFor="setor">Setor</CLabel>
                                                    <CSelect readOnly={isView()} disabled={isView()} name="setor" id="setor"  onChange={event=>handleChange(event, index, 'pessoaContato')} value={values.pessoaContato[index].setor}>
                                                        {SetorList.getList(true).map(item=>(
                                                            <option key={item.value} value={item.value}>{item.description}</option>
                                                        ))}
                                                    </CSelect>
                                                </CCol>
                                            </CFormGroup>
                                            <CFormGroup>
                                                <CLabel htmlFor="contato">Contato</CLabel>
                                                <CInput readOnly={isView()} disabled={isView()} type="text" id="contato" name="contato" onChange={event=>handleChange(event, index, 'pessoaContato')} value={values.pessoaContato[index].contato} />
                                            </CFormGroup>
                                            <div hidden={isView()}>
                                                <CFormGroup row className="justify-content-end">
                                                    <CButton color="light" className="mr-1 small btn-sm" ><CIcon name="cil-plus" onClick={event=>handleClickPlus(getInitialValueContato, 'pessoaContato')}/></CButton>
                                                    <CButton color="light" className="small btn-sm"><CIcon name="cil-minus" onClick={event=>handleClickMinus(index, getInitialValueContato, 'pessoaContato')} /></CButton>
                                                </CFormGroup>
                                            </div>
                                        </CCardBody>
                                    </CCard>
                                </CCardBody>
                            </CCard>
                        ))}
                    </CTabPane>
                    <CTabPane>
                        {values.pessoaEndereco && values.pessoaEndereco.map((endereco:PessoaEnderecoFormData, index:number)=>(
                            <CCard key={index}>
                                <CCardBody>
                                    <CCard>
                                        <CCardBody>
                                            <CFormGroup>
                                                <CLabel htmlFor="tipo">Tipo</CLabel>
                                                <CSelect readOnly={isView()} disabled={isView()} name="tipo" id="tipo"  onChange={event=>handleChange(event, index, 'pessoaEndereco')} value={values.pessoaEndereco[index].tipo}>
                                                    {TipoEnderecoList.getList().map(item=>(
                                                        <option key={item.value} value={item.value}>{item.description}</option>
                                                    ))}
                                                </CSelect>
                                            </CFormGroup>
                                            <CFormGroup>
                                                <CLabel htmlFor="logradouro">Logradouro</CLabel>
                                                <CInput readOnly={isView()} disabled={isView()} type="text" id="logradouro" name="logradouro" onChange={event=>handleChange(event, index, 'pessoaEndereco')} value={values.pessoaEndereco[index].logradouro} />
                                            </CFormGroup>
                                            <CFormGroup row>
                                                <CCol className="col-6">
                                                    <CLabel htmlFor="cidade">Cidade</CLabel>
                                                    <AsyncSelect isDisabled={isView()} defaultOptions loadOptions={promiseOptions}  onChange={newValue=>handleChangeSuggest(newValue, 'cidade', index, 'pessoaEndereco')} value={values.pessoaEndereco[index].cidade}>
                                                    </AsyncSelect>
                                                </CCol>
                                                <CCol className="col-6">
                                                    <CLabel htmlFor="bairro">Bairro</CLabel>
                                                    <CInput readOnly={isView()} disabled={isView()} type="text" id="bairro" name="bairro" onChange={event=>handleChange(event, index, 'pessoaEndereco')} value={values.pessoaEndereco[index].bairro} />
                                                </CCol>
                                            </CFormGroup>
                                            <CFormGroup row>
                                                <CCol className="col-6">
                                                    <CLabel htmlFor="numero">Número</CLabel>
                                                    <CInput readOnly={isView()} disabled={isView()} type="number" id="numero" name="numero" onChange={event=>handleChange(event, index, 'pessoaEndereco')} value={values.pessoaEndereco[index].numero} />
                                                </CCol>
                                                <CCol className="col-6">
                                                    <CLabel htmlFor="cep">CEP</CLabel>
                                                    <CInput readOnly={isView()} disabled={isView()} type="number" id="cep" name="cep" onChange={event=>handleChange(event, index, 'pessoaEndereco')} value={values.pessoaEndereco[index].cep} />
                                                </CCol>
                                            </CFormGroup>
                                            <CFormGroup>
                                                <CLabel htmlFor="complemento">Complemento</CLabel>
                                                <CInput readOnly={isView()} disabled={isView()} type="text" id="complemento" name="complemento" onChange={event=>handleChange(event, index, 'pessoaEndereco')} value={values.pessoaEndereco[index].complemento} />
                                            </CFormGroup>
                                            <div hidden={isView()}>
                                                <CFormGroup  row className="justify-content-end">
                                                    <CButton color="light" className="mr-1 small btn-sm" ><CIcon name="cil-plus" onClick={event=>handleClickPlus(getInitialValueEndereco,'pessoaEndereco')}/></CButton>
                                                    <CButton color="light" className="small btn-sm"><CIcon name="cil-minus" onClick={event=>handleClickMinus(index,getInitialValueEndereco, 'pessoaEndereco')} /></CButton>
                                                </CFormGroup>
                                            </div>
                                        </CCardBody>
                                    </CCard>
                                </CCardBody>
                            </CCard>
                        ))}
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </Form>
    );
};

export default PessoaForm;