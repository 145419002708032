import React, {useEffect, useState} from 'react';
import {
    CBadge,
    CButton,
    CCardBody,
    CCardHeader,
    CCol,
    CCollapse,
    CFormGroup,
    CInput,
    CLabel,
    CRow,
    CSelect,
    CSwitch,
    CTextarea
} from '@coreui/react';
import {ChecklistItemFormData, OpcoesFormData, TipoMetricaList, TipoPerguntaList, TipoPerguntaTypes} from "./index";
import CIcon from "@coreui/icons-react";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import Form, {FormApi} from "../../../components/form";
import useCurrentAction from "../../../services/useCurrentAction";
import useCustomForm from '../../../services/useCustomForm'
import {useParams} from "react-router-dom";
import {getApi} from "../../../apis/backendApis";
import useLoading from "../../../services/useLoading";
import useAlert from "../../../services/useAlert";
import Select from "react-select";

const ChecklistItemForm = ()=>{

    interface GetParam {
        id?: string
    }

    const [formApi, setFormApi] = useState<FormApi>();
    const action = useCurrentAction();
    const { id } = useParams<GetParam>();
    const [accordion, setAccordion] = useState<number | null>(0);
    const [trashHover, setTrashHover] = useState<number | null>(null);
    const [plusHover, setPlusHover] = useState<number | null>(null);
    const [editorContent, setEditorContent] = useState<string[]>(['']);
    const [itemCondicional, setItemCondicional] = useState(false);
    const [setLoading] = useLoading();
    const [showAlert] = useAlert();

    const getInitialValueOpcoes = (numero?:number)=>{
        return {
            numero: numero !== undefined ? numero : 1,
            descricao:''
        }
    }

    const getInitialValueItem = () => {
        return {
            itemCondicional: '',
            draggId: Math.random().toString(),
            tipo: TipoPerguntaTypes.OBJETIVA,
            descricao: '',
            titulo: '',
            obrigatorio:false,
            tipoMetrica: undefined,
            opcoes: [
                getInitialValueOpcoes()
            ]
        }
    }

    const initialValues:Array<ChecklistItemFormData> = [
        getInitialValueItem()
    ]

    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        handleClickMinus,
        handleClickPlus,
    } = useCustomForm(

        {
            initialValues,
            route:'checklist',
            action:'itens',
            onSubmit: values => {
                let ordem = 1;
                let erro:{type:string, msg:string, title?:string} | undefined = undefined;
                let itensOrdenados = values.values.map((item:ChecklistItemFormData, index:number)=>{
                    let isPreenchido = (s:any[])=>{
                        let preenchidos = s.filter((val)=>{
                            if(val !== undefined){
                                let newS = val.toString().trim();
                                return newS !== '';
                            }
                        })
                        return preenchidos.length === s.length;
                    }

                    let exigeOpcoes = (tipo:number) => ([TipoPerguntaTypes.MULTIPLA_ESCOLHA, TipoPerguntaTypes.OBJETIVA].includes(tipo*1));

                    let opcoesPreenchidas = item.opcoes.filter((opt)=>(isPreenchido([opt.descricao])));

                    if(!isPreenchido([item.descricao, item.titulo])){
                        erro = {type:'danger', msg: `Necessário preencher os campos obrigatórios Título e Descrição. Item ${index+1}`, title: 'Alerta'};
                    }

                    if((exigeOpcoes(item.tipo) && opcoesPreenchidas.length <= 1)){
                        erro = {type:'danger', msg: `Necessária mais de uma opção para as questões Objetivas de Múltipla Escolha.  Item ${index+1}`, title: 'Alerta'};
                    }

                    let tipoMetrica  = item.tipoMetrica && item.tipoMetrica*1;
                    tipoMetrica = tipoMetrica !== -1 ? tipoMetrica : undefined;

                    return {...item, ordem:ordem++, tipoMetrica: tipoMetrica}
                })
                itensOrdenados.map((item:ChecklistItemFormData, index:number)=>{
                    delete itensOrdenados[index].checkedCondicional
                });
                if(erro){
                    let {msg} = erro;
                    showAlert({
                        msg: msg,
                        type: 'danger',
                        toaster: true,
                    });
                    return;
                } else {
                    handleSubmitValues(itensOrdenados);
                }
            },
        }
    );

    const editorButtons = [
        "undo",
        "redo",
        "font",
        "fontSize",
        "formatBlock",
        "paragraphStyle",
        "blockquote",
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
        "removeFormat",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "lineHeight",
        "table",
        "link"
    ];

    // useEffect(()=>{
    //     let index = accordion || 0;
    //     let actualValues = [...values];
    //     let newArrayValues = {...values[index], ['descricao']:editorContent && editorContent[index]}
    //     actualValues[index] = newArrayValues;
    //     setValues(actualValues);
    // },[editorContent])

    useEffect(()=>{
        if(id){
            let api = getApi();
            setLoading(true);
            api.get(`checklist/get_itens/${id}`).then((response)=>{
                let newValues = [...response.data];
                let descricoes: string[] = [];
                newValues = newValues.map((item:ChecklistItemFormData, index) => {

                    if (newValues[index]?.itemCondicional?.id > 0){
                        newValues[index].checkedCondicional= true
                    }
                    // if(item.descricao){
                    //     descricoes.push(item.descricao) ;
                    // }
                    return {...item, draggId: Math.random().toString()}
                });
                if(newValues.length > 0){
                    setValues(newValues);
                }
                //setEditorContent(descricoes);
            }).finally(()=>{
                setLoading(false);
            });
        }

    },[id]);

    const handleFormReady = (formApi:FormApi)=>{
        setFormApi(formApi);
    }

    const handleChangeOpcoes = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>, indexItem: number, indexOpcao: number)=>{
        let actualValues:Array<ChecklistItemFormData> = [...values];
        if(actualValues[indexItem]){
            let item = actualValues[indexItem];
            let opcao = item.opcoes && {...item.opcoes[indexOpcao], descricao: event.target.value};

            if(opcao){
                actualValues[indexItem].opcoes.splice(indexOpcao,1, opcao);
            }

            setValues(actualValues);
        }
    }

    const getNextNumero= (opcoes:OpcoesFormData[])=>{
        let max = 0;
        opcoes.map(opcao=>{
            max = opcao.numero > max ? opcao.numero : max;
        })
        return max + 1;
    }

    const handleMinusOpcoes = (indexItem: number, indexOpcao: number)=>{
        let actualValues:Array<ChecklistItemFormData> = [...values];
        if(actualValues[indexItem]){
            if(actualValues[indexItem].opcoes.length > 1){
                actualValues[indexItem].opcoes.splice(indexOpcao,1);
            } else {
                actualValues[indexItem].opcoes.splice(indexOpcao,1, getInitialValueOpcoes());
            }

            setValues(actualValues);
        }
    }

    const handlePlusOpcoes = (indexItem:number)=>{
        let actualValues:Array<ChecklistItemFormData> = [...values];
        if(actualValues[indexItem]){
             let numero = getNextNumero(actualValues[indexItem].opcoes);
             actualValues[indexItem].opcoes.push(getInitialValueOpcoes(numero));

             setValues(actualValues);
        }
    }

    const isView = () => {
        return action === 'view';
    };

    const onDragEnd = (result:DropResult) =>{
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const resultList = Array.from(values);
        const [removed] = resultList.splice(result.source.index, 1);
        resultList.splice(result.destination.index, 0, removed);

        setValues([...resultList]);


        // const editorContentCopy = Array.from(editorContent);
        // const [removedContent] = editorContentCopy.splice(result.source.index, 1);
        // editorContentCopy.splice(result.destination.index, 0, removedContent);
        //
        // setEditorContent([...editorContentCopy]);
    }

    const getTituloPergunta = (titulo:string): string =>{
        if(titulo != '' && titulo.length <= 30){
            return titulo;
        } else if(titulo != ''){
            return titulo.substring(0, 30)+'...';
        }

        return 'Informe um título';
    }

    const onChangeEditor = (newValue:string, index:number)=>{
        let actualValues = [...editorContent];
        actualValues.splice(index,1, newValue);
        setEditorContent(actualValues);
    }

    const handleClickPlusItem = (index:number) =>{
        handleClickPlus(getInitialValueItem, undefined, index);
        setAccordion(accordion === index+1 ? null : index+1)
    }

    const getListTitulos = (indexSelected: number) => {
        return values
            .filter((value: any, index: number) => value.id !== values[indexSelected].id || value.draggId !== values[indexSelected].draggId)
            .map((value: any, index: number) => ({
                label: value['titulo'].toString(),
                value: value.id > 0 ? value.id : index.toString()
            }));
    };

    const getListOpcoes = (index: number) => {
        const selectedCondicionalId = values[index]?.itemCondicional;
        let selectedChecklist = values.find((value: any) => value?.id === selectedCondicionalId);
        if (selectedChecklist == null){
            selectedChecklist = values[selectedCondicionalId]
        }if (selectedChecklist && selectedChecklist.opcoes) {
            if (selectedChecklist.tipo == 4){
                let data = [];
                data.push({
                    label: 'Sim',
                    value: 1
                });
                data.push({
                    label: 'Não',
                    value: 2
                });
                return data;
            }
            return selectedChecklist.opcoes.map((opcao: any) => ({
                label: opcao.descricao.toString(),
                value: opcao.numero
            }));
        }
    };

    const condicionais ={
        numero: '',
        descricao: ''
    }

    const handleIsCondicional = (condicao: boolean, index: number) =>{
        values[index].checkedCondicional = condicao;
        setItemCondicional(condicao)
        if (!values[index].checkedCondicional){
            values[index].valorCondicional = null
            values[index].itemCondicional = null
        }
    }

    const handleItemCondicional = (selectedOption: any, index: number) => {
        const updatedValues = [...values];
        updatedValues[index].itemCondicional = selectedOption.value;
        setValues(updatedValues);
    };

    const handleCondicionalOpcao = (selectedOption: any, index: number) => {
        const updatedValues = [...values];
        updatedValues[index].valorCondicional = condicionais
        updatedValues[index].valorCondicional.numero = selectedOption.value;
        updatedValues[index].valorCondicional.descricao = selectedOption.label;
        setValues(updatedValues);
    };


    return (
        <Form entity="Itens Checklist" handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <DragDropContext onDragEnd={onDragEnd} >
                <Droppable
                    droppableId="list"
                >
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {values && values.map((item:ChecklistItemFormData, index:number)=>(
                                <Draggable
                                    draggableId={item.draggId}
                                    index={index}
                                    key={item.draggId}
                                    isDragDisabled={accordion === index}
                                >
                                    {provided => (
                                        <div
                                            key={item.draggId}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="card mb-2"
                                        >
                                            <CCardHeader className="py-0 pl-0">
                                                <CFormGroup className="row mb-0">
                                                    <div className="col-2">
                                                        <CButton
                                                            onClick={()=>handleClickMinus(index, getInitialValueItem)}
                                                            title="Remove o item da linha"
                                                            className={trashHover === index ? "border-danger" : ""}
                                                            onMouseOver={()=>{setTrashHover(index)}}
                                                            onMouseLeave={()=>{setTrashHover(null)}}
                                                        >
                                                            <CIcon name="cil-trash" className="c-icon" ></CIcon>
                                                        </CButton>
                                                        <CButton
                                                            onClick={()=>handleClickPlusItem(index)}
                                                            title="Adiciona um item"
                                                            className={plusHover === index ? "border-success" : ""}
                                                            onMouseOver={()=>{setPlusHover(index)}}
                                                            onMouseLeave={()=>{setPlusHover(null)}}
                                                        >
                                                            <CIcon name="cil-plus" className="c-icon" ></CIcon>
                                                        </CButton>
                                                    </div>
                                                    <CButton className="col-9" onClick={()=>{setAccordion(accordion === index ? null : index)}}>
                                                        <h5>
                                                            {getTituloPergunta(values[index].titulo)}
                                                        </h5>
                                                    </CButton>
                                                    <div className="col-1 row justify-content-end align-content-center pr-0">
                                                        <CIcon name="cil-apps" className="c-icon"></CIcon>
                                                        <CBadge title="Ordem" className="ml-2" shape="pill" color="secondary">{index+1}</CBadge>
                                                    </div>
                                                </CFormGroup>
                                            </CCardHeader>
                                            <CCollapse show={accordion === index}>
                                                <CCardBody>
                                                    <CFormGroup>
                                                        <CLabel htmlFor="titulo">Título</CLabel>
                                                        <CInput readOnly={isView()} disabled={isView()} type="text" id="titulo" name="titulo" onChange={event=>handleChange(event, index)}  value={values[index].titulo}/>
                                                    </CFormGroup>
                                                    <CFormGroup className="row">
                                                        <CCol className="col-4">
                                                            <CLabel htmlFor="tipo">Tipo</CLabel>
                                                            <CSelect readOnly={isView()} disabled={isView()} name="tipo" id="tipo"  onChange={event=>handleChange(event, index)} value={values[index].tipo}>
                                                                {TipoPerguntaList.getList().map(item=>(
                                                                    <option key={item.value} value={item.value}>{item.description}</option>
                                                                ))}
                                                            </CSelect>
                                                        </CCol>
                                                        <CCol className="col-4">
                                                            <CLabel htmlFor="tipoMetrica">Tipo de Métrica</CLabel>
                                                            <CSelect readOnly={isView()} disabled={isView()} name="tipoMetrica" id="tipoMetrica"  onChange={event=>handleChange(event, index)} value={values[index].tipoMetrica}>
                                                                {TipoMetricaList.getList(true).map(item=>(
                                                                    <option key={item.value} value={item.value}>{item.description}</option>
                                                                ))}
                                                            </CSelect>
                                                        </CCol>
                                                        <CCol className="col-4">
                                                            <CLabel className="offset-1 " htmlFor="obrigatorio" >Obrigatório</CLabel>
                                                            <CCol className="custom-switch">
                                                                <CSwitch color={'primary'} id="obrigatorio" name="obrigatorio"  checked={values[index].obrigatorio} onChange={event=>handleChange(event, index)}  readOnly={isView()} disabled={isView()}></CSwitch>
                                                            </CCol>
                                                        </CCol>
                                                    </CFormGroup>
                                                    <CFormGroup>
                                                        <CLabel htmlFor="descricao">Descrição</CLabel>
                                                        {/*<SunEditor*/}
                                                        {/*    lang = "pt_br"*/}
                                                        {/*    onChange={(newcontent)=>onChangeEditor(newcontent, index)}*/}
                                                        {/*    setContents={editorContent[index]}*/}
                                                        {/*    setOptions={{*/}
                                                        {/*        height: 200,*/}
                                                        {/*        buttonList:*/}
                                                        {/*        [editorButtons]*/}
                                                        {/*    }}*/}
                                                        {/*/>*/}
                                                        <CTextarea rows={5} readOnly={isView()} disabled={isView()} id="descricao" name="descricao" onChange={event=>handleChange(event, index)}  value={values[index].descricao}></CTextarea>
                                                    </CFormGroup>
                                                    {[TipoPerguntaTypes.MULTIPLA_ESCOLHA, TipoPerguntaTypes.OBJETIVA].includes(values[index].tipo*1) && (
                                                        <CFormGroup>
                                                            <CLabel>Opções</CLabel>
                                                            {values[index].opcoes && values[index].opcoes.map((opcao:OpcoesFormData, indexOP:number)=>(
                                                                <CRow key={indexOP}>
                                                                    <CCol className="col-10 d-flex align-items-center">
                                                                        <span className="pr-3">{`${opcao.numero}.`}</span>
                                                                        <CInput readOnly={isView()} disabled={isView()} type="text" id="descricao" name="descricao" onChange={event=>handleChangeOpcoes(event, index, indexOP)}  value={values[index].opcoes[indexOP].descricao}/>
                                                                    </CCol>
                                                                    <CCol className="col-2">
                                                                        <CButton onClick={()=>handlePlusOpcoes(index)}><CIcon name="cil-plus"></CIcon></CButton>
                                                                        <CButton onClick={()=>handleMinusOpcoes(index, indexOP)}><CIcon name="cil-trash"></CIcon></CButton>
                                                                    </CCol>
                                                                </CRow>
                                                            ))}
                                                        </CFormGroup>
                                                    )}
                                                    <CFormGroup className="row">
                                                        <CCol className="col">
                                                            <CLabel>Item Condicional?</CLabel>
                                                            <CCol className=" ">
                                                                <CSwitch
                                                                    color={'primary'}
                                                                    checked={values[index].checkedCondicional}
                                                                    onClick={() => {handleIsCondicional(!itemCondicional, index)}}
                                                                    readOnly={isView()}
                                                                    disabled={isView()}
                                                                ></CSwitch>
                                                            </CCol>
                                                        </CCol>
                                                        {values[index].checkedCondicional && (
                                                        <CCol className="col-10">
                                                            <CLabel>Condicional do Item: </CLabel>
                                                            <Select
                                                                options={getListTitulos(index)}
                                                                onChange={(selectedOption) => handleItemCondicional(selectedOption, index)}
                                                                value={values[index]?.itemCondicional ?
                                                                    getListTitulos(index).find((val: any) => val.value === values[index].itemCondicional.id) :
                                                                    undefined
                                                                }
                                                                noOptionsMessage={() => "Sem itens disponíveis"}
                                                            />
                                                        </CCol>
                                                        )}
                                                    </CFormGroup>
                                                    {values[index]?.itemCondicional != null && values[index].checkedCondicional &&
                                                        <CFormGroup>
                                                            <CLabel>Qual opção atende esse condicional: </CLabel>
                                                            <Select
                                                                options={getListOpcoes(index)}
                                                                onChange={(selectedOption)=>{handleCondicionalOpcao(selectedOption, index)}}
                                                                noOptionsMessage={() => "Sem opções disponíveis"}
                                                                value={values[index]?.valorCondicional ? {
                                                                       label: values[index]?.valorCondicional?.descricao,
                                                                       value: values[index]?.valorCondicional?.numero
                                                                }: undefined}
                                                            />
                                                        </CFormGroup>
                                                    }
                                                </CCardBody>
                                            </CCollapse>
                                        </div>
                                    )}
                                </Draggable>
                            ))
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Form>
    )
}

export default ChecklistItemForm;