import React, {useState, useEffect} from 'react';
import useCustomForm from "../../../services/useCustomForm";
import {
    CFormGroup,
    CInput,
    CLabel, CInputCheckbox, CSelect, CButton, CRow, CCol, CCard
} from "@coreui/react";
import Form, {FormApi} from "../../../components/form";
import {getApi} from "../../../apis/backendApis";
import {TypeExecuteTask, TypeExecuteTaskList} from "./index";
import AsyncSelect from "react-select/async";
import useCurrentAction from "../../../services/useCurrentAction";
import CIcon from "@coreui/icons-react";
import mockupList from "../mockup/mockupList";
import {data} from "jquery";

const ExecuteTaskForm = ()=> {

    const [mockupSelecionado, setMockupSelecionado] = useState<any>({});
    const [TestCasesSelecinado, setTestCasesSelecionado] = useState([]);


    const action = useCurrentAction();

    const initialValues:any = {
        id: '',
        name: '',
        type: TypeExecuteTask.TYPE_TEST_USER,
        testCase: [],
        mockup: []
    }
    const [formApi, setFormApi] = useState<FormApi>();
    const {
        values,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        setValues
    } = useCustomForm(
        {
            initialValues,
            route:'execute-task',
            onSubmit: values => submitValues(values.values),
            onLoadDadosCompleted: (values) => {
                values.mockup = Object.keys(values.mockup).map(function(nameIndex){
                    let mockup = values.mockup[nameIndex];
                    return mockup;
                });
                values.testCase = Object.keys(values.testCase).map(function(nameIndex){
                    let testCase = values.testCase[nameIndex];
                    return testCase;
                });
                setValues(values);
            }
        }
    );

    useEffect(()=>{

        /*
        async function loadLists(){
            let api = getApi();
            api.get(`execute-task/lists-form`).then((response)=>{
                let data = response.data;
                setListMockups(data.mockups);
                setListTestCases(data.testCases);
            }).finally(()=>{
                //console.log('finally');
            });
        }
        loadLists();*/
    },[]);

    const submitValues = (values : any) => {
        handleSubmitValues(values);
    }

    const handleFormReady = (formApi:FormApi)=>{
        setFormApi(formApi);
    }

    const handleChangeMockup = (mockup : any) => {
        if(!values['mockup'][mockup.id]){
            values['mockup'][mockup.id] = mockup;
        } else {
            values['mockup'][mockup.id] = null;
        }
        setValues({ ...values, ['mockup']: values['mockup']});
    }

    const handleChangeTestCase = (testeCase : any) => {
        let pode = true;
        for(let item of values['testCase']){
            if(testeCase.value == item.id){
                pode = false;
                break;
            }
        }
        if(pode){
            values['testCase'].push({"id": testeCase.value,"name":testeCase.label,"codigo": testeCase['codigo']});
            setValues({ ...values, ['testCase']: values['testCase']});
        }
        /*if(!values['testCase'][testeCase.id]){
            values['testCase'][testeCase.id] = testeCase;
        } else {
            values['testCase'][testeCase.id] = null;
        }*/
    }

    const handleChangeMockups = (mockups : any) => {
        let pode = true;
        for(let item of values['mockup']){
            if(mockups.value == item.id){
                pode = false;
                break;
            }
        }
        if(pode){
            values['mockup'].push({"id": mockups.value,"name":mockups.label});
            setValues({ ...values, ['mockup']: values['mockup']});
        }
        /*if(!values['testCase'][testeCase.id]){
            values['testCase'][testeCase.id] = testeCase;
        } else {
            values['testCase'][testeCase.id] = null;
        }*/
    }

    const promiseOptionsTestCase = (inputValue:string):Promise<any> => {
        if(!inputValue){
            inputValue = ' '
        }
        let api = getApi();
        return api.get(`/test-case/suggest/${inputValue}`).then(response=>response.data);
    }

    const promiseOptionsMockups = (inputValue:string):Promise<any> => {
        if(!inputValue){
            inputValue = ' '
        }
        let api = getApi();
        return api.get(`/mockup/suggest/${inputValue}`).then(response=>response.data);
    }

    const dropItemMockup = (mockupId: any) => {
        // @ts-ignore
        const updatedMockupsList = values['mockup'].filter(mockup => mockup.id!== mockupId);
        setValues({ ...values, ['mockup']: updatedMockupsList });
    };

    const dropItemTestCase = (testCaseID: any) => {
        // @ts-ignore
        const updatedTestCaseList = values['testCase'].filter(testCase => testCase.id!== testCaseID);
        setValues({ ...values, ['testCase']: updatedTestCaseList });
    };


    return (<Form entity={'Plano de Execução ' + values.id} handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CFormGroup>
                <CLabel className={'text-danger'} htmlFor="name">Nome</CLabel>
                <CInput type="text" id="name" name="name" maxLength={100} onChange={handleChange} value={values.name} required/>
            </CFormGroup>
            <CFormGroup>
                <CLabel className={'text-danger'} htmlFor="type">Tipo</CLabel>
                <CSelect type="text" id="type" name="type" onChange={handleChange} value={values.type} required >
                    {TypeExecuteTaskList.getList().map(item=>(
                        <option key={item.value} value={item.value}>{item.description}</option>
                    ))}
                </CSelect>
            </CFormGroup>

            <div className="row">
                <div className="col-md-6">
                    <h4 className="text-center border-bottom"> Casos de Testes para Execução</h4>
                    <div className="row execute-task-items pl-3">
                        <AsyncSelect
                            defaultOptions
                            value={TestCasesSelecinado}
                            loadOptions={promiseOptionsTestCase}
                            onChange={(data : any) => {
                                handleChangeTestCase(data);
                            }}
                        >
                        </AsyncSelect>
                    </div>

                    <div className="row execute-task-items p-3">
                        <CLabel hidden={values?.testCase?.length == 0}>Casos de Teste Selecionados:</CLabel>
                        {values?.testCase && values?.testCase?.map((testCase: any) => {
                            return <div key={'tc-' + testCase.id}>
                                <CRow>
                                    <CCol className="col-md-9">
                                        <div></div>
                                        <h6><div className={'card p-2'}>{testCase.name}</div></h6>
                                    </CCol>
                                    <CCol className="col-3" >
                                        <CButton onClick={() => dropItemTestCase(testCase.id)} style={{background: "red"}}>
                                            <CIcon className={'trash'} name={'cil-trash'} style={{color:"white",}}></CIcon>
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </div>
                        })}
                    </div>
                </div>
                <div className="col-md-6">
                    <h4 className="text-center border-bottom">Mockups de Dados para Execução</h4>
                    <div className="row execute-task-items pl-3">
                        <AsyncSelect
                            defaultOptions
                            value={mockupSelecionado}
                            loadOptions={promiseOptionsMockups}
                            onChange={(data: any) => {
                                handleChangeMockups(data);
                            }}
                        >
                        </AsyncSelect>
                    </div>
                    <div className="row execute-task-items p-3">
                        <CLabel hidden={values?.mockup?.length == 0}>Mockups Selecionados:</CLabel>
                        {values?.mockup && values?.mockup?.map((mockups: any) => {
                            return <div key={'tc-' + mockups.id }>
                                <CRow>
                                    <CCol className="col-10">
                                        <h6><div className={'card p-2'}>{mockups.name}</div></h6>
                                    </CCol>
                                    <CCol className="col-1">
                                        <CButton onClick={() => dropItemMockup(mockups.id)}
                                                 style={{background: "red"}}>
                                            <CIcon name={'cil-trash'} style={{color: "white"}}></CIcon>
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </div>
                        })}
                    </div>
                </div>
            </div>
    </Form>)
}
export default ExecuteTaskForm;

