

import {mockup_content_routes} from './mockup';
import {operation_content_routes} from './operation';
import {test_case_content_routes} from './testCase';
import {ContentRoute} from "../../models/Route";
import {execute_task_content_routes} from "./executeTask";
import {group_teste_content_routes} from "./groupTeste";
const routes: Array<ContentRoute> =
    mockup_content_routes
    .concat(operation_content_routes)
    .concat(test_case_content_routes)
    .concat(execute_task_content_routes)
    .concat(group_teste_content_routes)

export default routes;