import {OptionTypeBase} from "react-select";

interface enumItem {
    value:number | undefined,
    description:string
}

class enumDescription {
    private list:Array<enumItem> = [];

    constructor(list:Array<enumItem>) {
        this.list = list;
    }

    getDescription (value:number):string{
        let enumItem = this.list.find((enumItem:enumItem)=>{
            return enumItem.value == value;
        })

        return enumItem ? enumItem.description :'';
    }

    getList (addFirst?:boolean): Array<enumItem>{
        if(addFirst === true && this.list.findIndex(enumItem=>enumItem.value === undefined) === -1){
            this.list.splice(0,0,{
                value: undefined,
                description:'Nenhum'
            });
        }
        return this.list;
    }

    find(value: number): enumItem | undefined {
        return this.list.find((enumItem: enumItem) => enumItem.value === value);
    }

    /**
     * Retorna a lista no formato esperado pelo atributo options do componente Select
     */
    getSelectList(): Array<OptionTypeBase> {
        return this.list.map(item => ({
            label: item.description,
            value: item.value
        }));
    }

    /**
     * Retorna o item no formato esperado pelo atributo options do componente Select
     */
    findSelectValue(value: number): OptionTypeBase | undefined {
        return this.getSelectList().find(item => item.value === value);
    }

}

export default enumDescription;