import useCustomForm from "../../../../services/useCustomForm";
import {ClienteAnexoForm, ClienteProdutoForm} from "../../../cadastros/cliente";
import React, {useEffect, useState} from "react";
import Form, {FormApi} from "../../../../components/form";
import useCurrentAction from "../../../../services/useCurrentAction";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader, CForm,
    CFormGroup,
    CInput,
    CLabel,
    CModal,
    CModalBody, CModalHeader,
    CModalTitle
} from "@coreui/react";
import {NumericFormat} from "react-number-format";
import {getApi} from "../../../../apis/backendApis";
import financeiro, {SituacaoFaturaList, SituacaoFaturaTypes} from "../../index";
import {useLocation} from "react-router-dom";
import {formatter} from "../../../../components/numberBRLFormatForGrid";
import Select from "react-select";
import useAlert from "../../../../services/useAlert";
import CIcon from "@coreui/icons-react";


const FaturaForm = () => {

    const [formApi, setFormApi] = useState<FormApi>();
    const action = useCurrentAction();
    const [financeiros, setFinanceiros] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [listObjetosContratos, setListObjetosContratos] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [disable, setDisable] = useState(false);
    const [isFault, setIsFault] = useState(false);
    const [showAlert] = useAlert();
    let location = useLocation();
    const isView = () => {
        return action === 'view';
    };
    const initialValues = {
        id: '',
        ano: '',
        mes: '',
        cliente: [],
        situacao: '',
        valor: '',
        observacao: '',
        dataHoraGeracao: '',
        dataHoraVencimento: '',
        valorPago: ''
    }

    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleSubmitValues,
    } = useCustomForm(
        {
            initialValues,
            route: 'fatura',
            onSubmit: values => {
                let newValues = {...values.values};
                handleSubmitValues(newValues, values.filesUpload)
            }
        }
    );

    useEffect(() => {
        let idLocation = location.pathname.split('/');
        let idFatura = idLocation[idLocation.length - 1];
        let api = getApi()
        api.get(`fatura/financeiros/` + idFatura).then((response) => {
            setFinanceiros(response.data);
            setRefresh(false)
        })
    }, [refresh]);

    const getList = (list: any) => {
        let data: any = [];
        (Object.keys(list) as (keyof typeof list)[]).forEach((key, index) => {
            let item: any = {};
            item['label'] = list[key];
            item['value'] = key;
            data.push(item);
        });
        return data;
    }

    const handleFormReady = (formApi: FormApi) => {
        setFormApi(formApi);
    }

    const moduloId = (index: number) => {
        return index % 2 === 0 ? '' : 'bg-light';
    }

    useEffect(() => {
        let api = getApi();
        api.get(`cliente-objeto-contrato/suggest`).then((response) => {
            let data = response.data;
            setListObjetosContratos(getList(data['objetosContratos']));
        });
    }, []);

    const excluirFinanceiro = (financeiro: any) =>{
        console.log(financeiro);
        showAlert({
            title: 'Exluir',
            msg: 'Tem certeza que deseja excluir o item financeiro?',
            fnOk: () => {
                let api = getApi();
                api.delete('financeiro/delete/'+financeiro.fin_id).then((response) => {
                    showAlert({
                        msg: response.data.msg,
                        toaster:true,
                        type: 'success'
                    });
                    values.valor = parseFloat(response.data.valorFatura);
                    setValues({...values})
                    setRefresh(true)
                })
            },
        });
    }

    const abreModal = () =>{
        let api = getApi();
        let objContratoSelecionado :number = 0;
        let valorCobranca :any = null;
        let observacao :any = null;
        const handleNovoItem = (option: any )=>{
            objContratoSelecionado = parseInt(option.value);
        }
        const handleValorCobranca = (valor: any )=>{
            const valorConvertido = parseFloat(valor.replace(/\./g, '').replace(',', '.'));
            if (!isNaN(valorConvertido)) {
                valorCobranca = valorConvertido;
            }
        }
        const handleObservacao = (obs: any )=>{
            observacao = obs;
        }

        const enviarNovoItemFinanceiro = ()=>{
            if(objContratoSelecionado > 0 && valorCobranca > 0) {
                setIsFault(false);
                setDisable(true);
                api.post('/financeiro/novoItem/'+values.id, {idObjetoContrato: objContratoSelecionado , valorCobranca: valorCobranca, observacao: observacao}).then((response) => {
                    if (response.data.success){
                        showAlert({
                            msg: response.data.msg,
                            toaster: true,
                        });
                        values.valor = parseFloat(response.data.valorFatura);
                        setValues({...values})
                        setRefresh(true);
                        setDisable(false);
                        setShowModal(false);
                        objContratoSelecionado = 0;
                        valorCobranca = 0;
                    }else{
                        showAlert({
                            msg: response.data.msg,
                            toaster: true,
                            type: 'danger'
                        });
                    }
                })
            }else{
                setIsFault(true);
            }

        }

        document.addEventListener('keypress', function(e: KeyboardEvent) {
            if (e.key === 'Enter' || e.keyCode === 13) {
                enviarNovoItemFinanceiro();
            }
        }, false);

        return (
            <CModal
                show={showModal}
                onClose={()=>{setShowModal(false)}}
                backdrop={true}
                color="info"
                size={'lg'}
            >
                <CModalHeader closeButton>Adicionar Novo Item Financeiro</CModalHeader>
                <CModalBody>
                    <CFormGroup>
                        <CLabel className={'text-danger'}>Selecione um objeto de contrato</CLabel>
                        <Select
                            required
                            isDisabled={isView()}
                            onChange={handleNovoItem}
                            options={listObjetosContratos}
                            placeholder={'Objetos de Contrato'}
                            closeMenuOnSelect={true}
                            className={isFault ? 'border-danger' : 'filtro-select'}
                            readOnly={isView()}
                            disabled={isView()}
                        ></Select>
                    </CFormGroup>
                    <CFormGroup>
                        <div>
                            <CLabel className={'text-danger'} htmlFor="ValorMínimo">Valor de Cobrança</CLabel>
                            <NumericFormat
                                required
                                name={"valorCobranca"}
                                id={'valorCobranca'}
                                className={isFault ? 'border-danger' : ''}
                                placeholder={"R$"}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={false}
                                customInput={CInput}
                                onBlur={(event) =>{handleValorCobranca(event.target.value)}}
                                value={valorCobranca ?? ''}
                                readOnly={isView()}
                                disabled={isView()}
                            />
                        </div>
                    </CFormGroup>
                    <CFormGroup>
                        <div>
                            <CLabel>Observação</CLabel>
                            <CInput
                                name={'observacao'}
                                value={observacao}
                                onBlur={(event) =>{handleObservacao(event.target.value)}}
                                className={'observacao'}
                                readOnly={isView()}
                                disabled={isView()}
                                maxLength={200}
                            />
                        </div>
                    </CFormGroup>
                    <CButton className={'m-1'} disabled={disable} color={'success'} onClick={enviarNovoItemFinanceiro}>Confirmar</CButton>
                    <CButton className={'m-1'} color={'danger'} onClick={()=>{setShowModal(false)}}>Cancelar</CButton>
                </CModalBody>
            </CModal>
        )
    }


    return (
        <Form entity="Fatura" handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CFormGroup>
                <CLabel> Cliente </CLabel>
                <CInput
                    type="text"
                    id="cliente"
                    readOnly={true}
                    disabled={true}
                    value={values?.cliente?.pessoa?.nome || ''}
                />
            </CFormGroup>

            <CFormGroup>
                <CLabel> Situação </CLabel>
                <CInput
                    name="situacao"
                    value={SituacaoFaturaList.getDescription(values.situacao)}
                    onChange={handleChange}
                    readOnly={true}
                    disabled={true}
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel> Data/Hora da Geração </CLabel>
                <CInput
                    readOnly={true}
                    disabled={true}
                    type="datetime-local"
                    id="dataHoraGeracao"
                    name="dataHoraInicio"
                    value={values.dataHoraGeracao}/>
            </CFormGroup>
            <CFormGroup>
                <CLabel> Valor Total da Fatura </CLabel>
                <NumericFormat
                    prefix={isView() ? 'R$ ' : ''}
                    name="valor"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={false}
                    customInput={CInput}
                    value={values.valor}
                    readOnly={true}
                    disabled={true}
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel> Valor Total Pago </CLabel>
                <NumericFormat
                    prefix={isView() ? 'R$ ' : ''}
                    name="valor"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={false}
                    customInput={CInput}
                    value={values.valorPago}
                    readOnly={true}
                    disabled={true}
                />
            </CFormGroup>
            <CFormGroup className={'font-lg p-3 text-wrap'}>
                {!isView() &&
                    <div className={'row justify-content-end m-2 mr-0'}>
                        <CButton className={'float-right'} color={'info'} onClick={() => {setShowModal(true)}}>Incluir Novo Item</CButton>
                    </div>
                }
                <div className={'row border-bottom border-top header font-weight-bold'}>
                    <div className={'col-3 border-right border-left p-1'}>
                        Objeto de Contrato
                    </div>
                    <div className={'col-6 border-right p-1'}>
                        Observação
                    </div>
                    <div className={'col text-wrap border-right p-1'}>
                        Valor de Cobrança
                    </div>
                </div>
                {financeiros.map((financeiro: any, index: number) => (
                    <div key={index}>
                        <div className={`row ${moduloId(index) || ''}`}>
                            <div className={'col-3 border-bottom border-right border-left'}>
                                {financeiro.objcon_nome}
                            </div>
                            <div className={'col-6 border-bottom border-right'}>
                                {financeiro.fin_observacao}
                            </div>
                            <div className={'col border-bottom border-right'}>
                                <div className={'row'}>
                                    <div className={'col-11'}>
                                        {formatter.format(financeiro.fin_valor_cobranca).toString()}
                                    </div>
                                    <div className={'col cursor-pointer'}>
                                        {financeiro.fin_origem && financeiro.fin_origem == 2 && (
                                            <CIcon onClick={()=>{excluirFinanceiro(financeiro)}} className={'trash'} name={'cil-trash'}
                                                   style={{color: "red"}}></CIcon>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </CFormGroup>
            {showModal && abreModal()}
        </Form>
    );
}
export default FaturaForm;