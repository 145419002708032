import cadastrosRoutes from "./pages/cadastros";
import notificacaoRoutes from "./pages/notificacao";
import clientStatusRoutes from "./pages/clients";
import tasksRoutes from "./pages/task";
import logRoutes from "./pages/log";
import changeLog from "./pages/changelog";
import testerRouter from "./pages/tester";
import contratosRouter from "./pages/contratos";
import programacaoAgendaRouter from "./pages/programacao";



var menus = [
    {
        'name': 'tasks',
        'description': 'Projetos',
        'icon': 'cil-task',
        'children': tasksRoutes,
        'only_root': true,
        'to': '/task'
    },
    {
        'name': 'Programação',
        'description': 'Programação ',
        'icon': 'cil-calendar',
        'children': programacaoAgendaRouter,
        'only_root': true,
        'to': '/programacao'
    },
    {
        'name': 'cadastros',
        'description': 'Cadastros',
        'icon': 'cil-puzzle',
        'children': cadastrosRoutes
    },
    {
        'name': 'notificacao',
        'description': 'Notificações',
        'icon': 'cil-speech',
        'children': notificacaoRoutes,
        'only_root': true,
        'to': '/notificacao'
    },
    {
        'name': 'client_status',
        'description': 'Status de Clientes',
        'icon': 'cil-info',
        'children': clientStatusRoutes,
        'only_root': true,
        'to': '/client-status'
    },
    {
        'name': 'logs',
        'description': 'Logs de Clientes',
        'icon': 'cil-code',
        'children': logRoutes,
        'only_root': true,
        'to': '/log'
    },
    {
        'name': 'changelog',
        'description': 'Change Log',
        'icon': 'cil-calendar',
        'children': changeLog,
        'only_root': true,
        'to': '/changelog'
    },
    {
        'name': 'panoverseQa',
        'description': 'Panoverse - QA',
        'icon': 'cil-code',
        'children': testerRouter,
        'to': '/panoverseQa'
    },
    {
        'name': 'Comercial',
        'description': 'Comercial ',
        'icon': 'cilPeople',
        'children': contratosRouter,
        'to': '/contratos'
    }
];
export const contentRoutes = menus;
