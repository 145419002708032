import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Redirect } from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import {User} from "../../models/Auth";
import {ApplicationState} from "../../store";
import {StatusTypes} from "../../store/ducks/login/types";
import {loadAuthDataFromToken} from "../../services/authStoreLoad";
import {loggingOff} from "../../store/ducks/login/actions";
import Header from './header';
import Sidebar from "./sidebar";
import Content from "./content";

interface StateProps {
    user ?: User,
    status : string
}

interface  DispatchProps {
    loadAuthDataFromToken() : void
    loggingOff() : void
}

type Props = StateProps & DispatchProps

class Home extends Component<Props> {

    constructor(props:Props) {
        super(props);
    }

    componentWillMount() {
        const {loadAuthDataFromToken} = this.props;
        loadAuthDataFromToken();
    }

    render (){
        return (
            <>
                {this.props.status === StatusTypes.RETRIEVING && (<h1>Loading...</h1>)}
                {this.props.status === StatusTypes.UNLOGGED && (<Redirect to= "/login"/>)}
                {this.props.status === StatusTypes.LOGGED && (

                    <div className="c-app c-default-layout">
                        <Sidebar/>
                        <div className="c-wrapper" >
                            <Header/>
                            <div className="c-body">
                                <Content/>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        user:state.login.data?.user,
        status:state.login.status
    };
}

const mapDispatchToProps = (dispatch:Dispatch) => bindActionCreators({
    loadAuthDataFromToken,
    loggingOff
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(Home);