import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';

const SetorUsuarioList = (props:any) => {


    return (
        <Grid rota="setor/usuario/list">
            <AgGridColumn width={120} field="setor/id" sort="desc" headerName="#Setor" checkboxSelection  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn flex={1}   field="setor/nome"  headerName="Nome do Setor" sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn width={120} field="usuario/id" headerName="#Usuario"   sortable={ true } ></AgGridColumn>
            <AgGridColumn flex={1}   field="usuario/nome" headerName="Nome Usuário"   sortable={ true } filter={ true }></AgGridColumn>
        </Grid>
    );
}

export default SetorUsuarioList;