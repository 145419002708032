import React, {useState, useEffect} from 'react';
import {
    CBadge,
    CButton, CCardHeader,
    CFormGroup,
    CInput,
    CLabel, CModal, CModalBody, CModalHeader, CModalTitle, CNav, CNavItem, CNavLink, CSelect, CTextarea
} from "@coreui/react";
import Form, {FormApi} from "../../../components/form";
import Select from "react-select";
import {getApi} from "../../../apis/backendApis";
import {TypeOperation, TypeOperationList, TypeTermList} from "../operation";
import CIcon from "@coreui/icons-react";
import {useHistory} from "react-router-dom";
import {DragDropContext, Droppable, Draggable, DropResult} from "react-beautiful-dnd";
import useCustomForm from '../../../services/useCustomForm'
import AsyncSelect from "react-select/async";



const TestCaseForm = () => {

    const [listOperacoes, setListOperacoes] = useState([]);
    const [listOperacoesBase, setListOperacoesBase] = useState([]);
    const [listGroupsTest, setListGroupsTest] = useState([]);

    const [showModalOperation, setShowModalOperation] = useState(false);

    const initialCurrentOperations: any = {
        index: '',
        model: {
            "name": "",
            "params": {
                "value": ""
            }
        }
    }
    const [currentOperation, setCurrentOperation] = useState(initialCurrentOperations);
    let history = useHistory();

    const initialValues: any = {
        id: '',
        name: '',
        codigo: '',
        operacao: '',
        operations: [],
        groupTeste: {}
    }
    const [formApi, setFormApi] = useState<FormApi>();
    let pathName = history.location.pathname;
    let actionPost: string = '';
    const isDuplicar: boolean = pathName.toString().includes('duplicar');
    if (isDuplicar) {
        actionPost = 'alterar/duplicar'
    }

    const {
        values,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        handleChangeSuggest,
        setValues
    } = useCustomForm(
        {
            initialValues,
            route: 'test-case',
            action: actionPost,
            onSubmit: values => submitValues(values.values),
            onLoadDadosCompleted: (values) => {
                if (isDuplicar) {
                    values.name = 'CÓPIA DE ' + values.name;
                    values.codigo = '';
                }
                setValues(values);
                if(values.groupTeste){
                let newData = {...values, groupTeste: {value: values.groupTeste.id , label: values.groupTeste.name}}
                setValues(newData);
                }
            }
        }
    );

    useEffect(() => {
        async function loadLists() {
            let api = getApi();
            api.get(`test-case/operations`).then((response) => {
                let data = response.data;
                setListOperacoesBase(data);
                setListOperacoes(getList(data));
            }).finally(() => {
                //console.log('finally');
            });
            api.get(`group-teste/suggest/TestCase`).then((response) => {
                let data = response.data;
                setListGroupsTest(getListGroupTest(data['groupsTeste']));
            }).finally(() => {
                //console.log('finally');
            });
        }

        loadLists();
    }, []);

    const submitValues = (values: any) => {
        handleSubmitValues(values);
    }

    const handleFormReady = (formApi: FormApi) => {
        setFormApi(formApi);
    }

    const handleChangeOperacao = (option: any) => {
        let key = parseInt(option.value);
        let valOperacoes = values['operations'];
        valOperacoes.push(listOperacoesBase[key]);
        setValues({...values, ['operations']: valOperacoes, ['operacao']: ''});
    }

    const onClickExcluirOperation = (index: any) => {
        if (window.confirm("Deseja realmente excluir a linha " + (parseInt(index) + 1) + "?")) {
            let valOperacoes = values['operations'];
            valOperacoes.splice(index, 1);
            setValues({...values, ['operations']: valOperacoes});
        }
    }

    const onClickOrderOperation = (currentIndex: any, previous: boolean) => {
        let valOperacoes = values['operations'];
        let currentOperation = valOperacoes[currentIndex];
        valOperacoes.splice(currentIndex, 1);
        if (previous) {
            valOperacoes.splice(currentIndex - 1, 0, currentOperation);
        } else {
            valOperacoes.splice(currentIndex + 1, 0, currentOperation);
        }
        setValues({...values, ['operations']: valOperacoes});
    }

    const onClickCustomOperation = (operation: any, index: any) => {
        setCurrentOperation({"model": operation, "index": index});
        setShowModalOperation(true);
    }

    const onCloseModal = () => {
        setShowModalOperation(false);
        setCurrentOperation(initialCurrentOperations);
    }

    const onConfirmeOperation = () => {
        let index = currentOperation['index'];
        let operations = values['operations'];
        operations[index] = currentOperation["model"];
        setValues({...values, ['operations']: operations});
        onCloseModal();
    }

    const handleChangeCurrentOperation = (event: any) => {
        let val = event.target.value;
        let model = currentOperation["model"];
        model["params"][event.target.name] = val;
        setCurrentOperation({...currentOperation, ['model']: model});
    }

    const getList = (list: any) => {
        let data: any = [];
        (Object.keys(list) as (keyof typeof list)[]).forEach((key, index) => {
            let item: any = {};
            item['label'] = list[key]['name'];
            item['value'] = key;
            data.push(item);
        });
        return data;
    }
    const getListGroupTest = (list: any) => {
        let data: any = [];
        (Object.keys(list) as (keyof typeof list)[]).forEach((key, index) => {
            let item: any = {};
            item['label'] = list[key];
            item['value'] = key;
            data.push(item);
        });
        return data;
    }

    const onDragEnd = (result: DropResult) => {

        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        const resultList = Array.from(values['operations']);
        const [removed] = resultList.splice(result.source.index, 1);
        resultList.splice(result.destination.index, 0, removed);
        setValues({...values, ['operations']: resultList});
    }

    const promiseOptionsGroupTest = (inputValue: string): any => {
        if (!inputValue) {
            inputValue = ' '
        }
        let api = getApi();
        return api.get(`/group-teste/suggest/TestCase/${inputValue}`).then(response => response.data );

    }
    const handleGroupTest = (option: any) => {
        values.groupTeste = option.value
        setValues({...values})
    }

    return <Form entity={'Caso de Teste ' + values.id} handleSubmit={handleSubmit} onFormReady={handleFormReady}>
        {isDuplicar && <div className={'alert alert-info'}>
            Estamos duplicando o registro original selecionado.
        </div>}

        <CFormGroup>
            <CLabel className={'text-danger'} htmlFor="codigo">Código</CLabel>
            <CInput type="text" id="codigo" name="codigo" maxLength={100} onChange={handleChange} value={values.codigo}
                    required/>
        </CFormGroup>
        <CFormGroup>
            <CLabel className={'text-danger'} htmlFor="name">Nome</CLabel>
            <CInput type="text" id="name" name="name" maxLength={100} onChange={handleChange} value={values.name}
                    required/>
        </CFormGroup>
        <div className="p-3">
            <Select
                options={listGroupsTest}
                onChange={handleGroupTest}
                placeholder={'Groupos de Teste'}
                closeMenuOnSelect={true}
                className={'filtro-select'}
                value={values.groupTeste && listGroupsTest.find((groupTeste: any) => groupTeste.value == values['groupTeste']['value'])}
            ></Select>
        </div>
        <div className={"p-3"}>
            <CLabel htmlFor="operacao">Adicionar Operação</CLabel>
            {listOperacoes && <Select
                name={'operacao'}
                options={listOperacoes}
                onChange={handleChangeOperacao}
                placeholder={'Adicione uma nova operação para o caso de teste'}
                closeMenuOnSelect={true}
                value={values.operacao}
            ></Select>}
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"list"}>

                {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {values && values['operations'] && values['operations'].map((operacao: any, index: number) => (
                            <Draggable
                                draggableId={index.toString()}
                                index={index}
                                key={index.toString()}
                            >
                                {provided => (
                                    <div
                                        key={index.toString()}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <div key={index} className={'execute-task-items col-md-12'}>
                                            <div className={"card"}>
                                                <div className="card-body p-0">
                                                    <div className={'row'}>
                                                        <div className={'order-operation col-1'}>
                                                            <div className="text-muted text-center">
                                                                {index + 1}
                                                            </div>
                                                        </div>
                                                        <div className="col-9 p-3">
                                                            <h5 className="card-title">{operacao['name']}</h5>
                                                            <h6 className="card-subtitle mb-2 text-muted">
                                                                {TypeOperationList.filter((item: any) => {
                                                                    return operacao.params && item.value == operacao.params['type']
                                                                }).map(item => <span
                                                                    key={item.value}>{item.description}</span>)}
                                                                &nbsp;Valor: {operacao.params['data'].toString().length > 0 ? operacao.params['data'] : operacao.params['value']}
                                                            </h6>
                                                        </div>
                                                        <div
                                                            className="col-1 text-right d-flex align-items-center flex-row">
                                                            <div className={'mr-1'}>
                                                                <CButton className={'btn-sm'} color="primary"
                                                                         onClick={() => {
                                                                             onClickCustomOperation(operacao, index)
                                                                         }}>
                                                                    <CIcon name="cil-settings"
                                                                           className="cil-settings"/>
                                                                </CButton>
                                                            </div>
                                                            <div>
                                                                <CButton className={'btn-sm'} color="danger"
                                                                         onClick={() => {
                                                                             onClickExcluirOperation(index)
                                                                         }}>
                                                                    <CIcon name="cil-trash" className="cil-trash"/>
                                                                </CButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>


        {/*{values['operations'].map((operacao: any, index: number) => {
                                    return <div key={index} className={'col-md-12'}>
                                        <div className={'card'}>
                                            <div className="card-body">
                                                <div className={'row'}>
                                    <div className={'order-operation col-1'}>
                                        <div className="text-muted text-center">{index + 1}</div>
                                    </div>
                                    <div className="col-9 p-3">
                                        <h5 className="card-title">{operacao['name']}</h5>
                                        <h6 className="card-subtitle mb-2 text-muted">
                                            {TypeOperationList.filter((item: any) => {
                                                return operacao.params && item.value == operacao.params['type']
                                            }).map(item => <span key={item.value}>{item.description}</span>)}
                                            &nbsp;Valor: {operacao.params['data'].toString().length > 0 ? operacao.params['data'] : operacao.params['value']}

                                        </h6>
                                    </div>
                                    <div className="col-2 text-right">
                                        <CButton className={'btn-sm'} color="primary" onClick={() => {
                                            onClickCustomOperation(operacao, index)
                                        }}>
                                            <CIcon name="cil-settings" className="cil-settings"/>
                                        </CButton>
                                        &nbsp;
                                        <CButton className={'btn-sm'} color="danger" onClick={() => {
                                            onClickExcluirOperation(index)
                                        }}>
                                            <CIcon name="cil-trash" className="cil-trash"/>
                                        </CButton>
                                        <br/>
                                        <br/>
                                        {index > 0 && <CButton className={'btn-sm'} color="secondary" onClick={() => {
                                                onClickOrderOperation(index, true)
                                            }}>
                                                <CIcon name="cil-arrow-top" className="cil-arrow-top"/>
                                            </CButton>}
                                        &nbsp;
                                        {index + 1 < values['operations'].length && <CButton className={'btn-sm'} color="secondary" onClick={() => {
                                                onClickOrderOperation(index, false)
                                            }}>
                                                <CIcon name="cil-arrow-bottom" className="cil-arrow-bottom"/>
                                            </CButton>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                })}*/}

        {values.operations.length > 2 && <div className={"ag-row-level-12"}>
            <CLabel className={"col-12"} htmlFor="operacao">Adicionar Operação</CLabel>
            {listOperacoes && (
                <Select
                    name={'operacao'}
                    options={listOperacoes}
                    onChange={handleChangeOperacao}
                    placeholder={'Adicione uma nova operação para o caso de teste'}
                    closeMenuOnSelect={true}
                    value={values.operacao}
                ></Select>
            )}
        </div>}

        <CModal
            show={showModalOperation}
            onClosed={onCloseModal}
            color="info"
            size={'lg'}
        >
            <CModalHeader>
                <CModalTitle>Configurar Operação [{currentOperation["model"]['name']}]</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div className="p-2">
                    {currentOperation["model"]["params"].type != TypeOperation.TYPE_CALL_URL
                        && currentOperation["model"]["params"].type != TypeOperation.TYPE_WAIT
                        && currentOperation["model"]["params"].type != TypeOperation.TYPE_EXECUTE_SCRIPT && <>
                            <CFormGroup>
                                <CLabel htmlFor="type_term">Tipo de Busca</CLabel>
                                <CSelect name="type_term" id="type_term" onChange={handleChangeCurrentOperation}
                                         value={currentOperation["model"]['params'].type_term}>
                                    <option value=''>Selecionar...</option>
                                    {TypeTermList.map(item => (
                                        <option key={item.value} value={item.value}>{item.description}</option>
                                    ))}
                                </CSelect>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel htmlFor="value">Termo de Busca</CLabel>
                                <CInput type="text" id="value" name="value" maxLength={100}
                                        onChange={handleChangeCurrentOperation}
                                        value={currentOperation["model"]['params'].value}/>
                            </CFormGroup>
                        </>}
                    <CFormGroup>
                        <CLabel htmlFor="data">Parâmetro/Valor para Operação</CLabel>
                        <CInput type="text" id="data" name="data" maxLength={100}
                                onChange={handleChangeCurrentOperation}
                                value={currentOperation["model"]['params'].data}/>
                    </CFormGroup>
                    <CFormGroup>
                        <CButton color="primary" onClick={onConfirmeOperation}>Confirma Configuração</CButton>
                    </CFormGroup>


                </div>
            </CModalBody>
        </CModal>
    </Form>
}
export default TestCaseForm;