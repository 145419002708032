import {Line, Pie} from "react-chartjs-2";
import {
    dataPie,
    optionsClienteFaturas,
    options,
    ResponseDataFaturamento,
    ResponseDataResume,
} from "./index";
import React, {useEffect, useState} from "react";
import {getApi} from "../../../apis/backendApis";
import {CButton, CCol, CForm, CRow} from "@coreui/react";
import Select from "react-select";
import "./style.css";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {numberDecimalFormatForm} from "../../../components/numberBRLFormatForForm";

ChartJS.register(
    Title,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    ArcElement
);

const ResumoDashboard = () => {

    const generateYearOptions = (): { label: string; value: number }[] => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 0; i < 10; i++) {
            const year = currentYear - i;
            years.push({label: year.toString(), value: year});
        }
        return years;
    };


    const currentYear = new Date().getFullYear();
    const [anosData, setAnosData] = useState<any[]>([]);
    const getInitialYears = () => {
        return [{
            value: currentYear.toString(),
            label: currentYear
        }];
    };
    const getMonths = () => {
        const months = [];
        for (let i = 0; i < 12; i++) {
            const date = new Date(0, i);
            const monthName = date.toLocaleString('default', {month: 'long'});
            months.push({
                value: i + 1,
                label: monthName.charAt(0).toUpperCase() + monthName.slice(1)
            });
        }
        return months;
    };

    type PieColor = {
        label: string;
        data: any[];
        backgroundColor: string[];
        borderColor: string[];
        borderWidth: number;
        percentual: any[];
    };

    const initialVisibleSlices = (data: any = null) => {

        if (data?.length > 0) {
            const numCharts = data.length;
            const numSlices = 10;
            const chartsPreRendered: any = {};

            for (let i = 0; i < numCharts; i++) {
                chartsPreRendered[i] = {};
                for (let j = 0; j < numSlices; j++) {
                    chartsPreRendered[i][j] = true;
                }
            }
            setVisibleSlices(chartsPreRendered)
        }
    };


    const yearsInitial = getInitialYears();
    const monthOptions: any = getMonths();
    const [selectedYear, setSelectedYear] = useState(yearsInitial);
    const [selectedYearClienteFatura, setSelectedYearClienteFatura] = useState(yearsInitial);
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataHistorico, setDataHistorico] = useState<any>(null);
    const [dataClienteFaturamento, setDataClienteFaturamento] = useState<any>(null);
    const [dataFaturas, setDataFaturas] = useState<any>(null);
    const [notDataClienteFaturas, setNotDataClienteFaturas] = useState(false);
    const [pieColors, setPieColors] = useState<PieColor[]>([]);
    const [visibleSlices, setVisibleSlices] = useState<{ [chartIndex: number]: { [sliceIndex: number]: boolean } }>([]);
    const [chartsClientShow, setChartsClientShow ] = useState<boolean>(false);
    const [getClienteSelected, setClientSelected ] = useState([]);
    const [listClientes, setListCLientes] = useState([]);

    useEffect(() => {
        let api = getApi();
        api.get('cliente/suggest').then((response) => {
            setListCLientes(getList(response.data['clientes']));
        })
    }, []);

    const getList = (list: any) => {
        let data: any = [];
        Object.keys(list).forEach((key) => {
            const cliente = list[key];
            data.push({
                label: Object.values(cliente)[0],
                value: Object.keys(cliente)[0]
            });
        });
        return data;
    }

    useEffect(() => {
        const years = generateYearOptions();
        setAnosData(years);
        getData();
    }, []);

    useEffect(() => {
        getData();
        if (getClienteSelected?.length > 0){
            getDataCliente();
        }
    }, [selectedYear, selectedMonth]);

    const getData = () => {
        handleYearClienteFaturaChange(selectedYearClienteFatura)
        setLoading(true)
        const params = [{
            filters: {
                //@ts-ignore
                mes: selectedMonth,
                ano: selectedYear
            },
        }];
        let api = getApi();
        api.get('financeiro/resumo', {params}).then((response) => {
            setLoading(false)
            setDataHistorico(ResponseDataResume(response.data));
        });
    }

    useEffect(() => {
        if (getClienteSelected?.length > 0){
            getDataCliente();
        }
    }, [getClienteSelected]);

    const getDataCliente = () =>{
        let params: any = {};
        params['filters'] = {
            clientes: getClienteSelected,
            ano: selectedYear,
            mes: selectedMonth
        };
        let api = getApi();
        api.get("fatura/clientes", {params}).then((response) => {
            setDataFaturas(ResponseDataFaturamento(response.data));
        })
    }


    const handleYearChange = (selectedOption: any) => {
        setSelectedYear(selectedOption);
        setSelectedYearClienteFatura(selectedOption);
    };

    const handleYearClienteFaturaChange = (selectedOption: any) => {
        setSelectedYearClienteFatura(selectedOption);
        let params: any = {};
        params['filters'] = {
            'mes': selectedMonth,
            'ano': selectedYear
        };
        let api = getApi();
        api.get("fatura/fatura-cliente", {params}).then((response) => {
            if (response?.data?.['erro']) {
                setNotDataClienteFaturas(true);
                setDataClienteFaturamento(null)
            } else {
                setNotDataClienteFaturas(false);
                setDataClienteFaturamento(response.data);
                initialVisibleSlices(response.data)
            }
        })
    };
    const handleMonthChange = (selectedOption: any) => {
        setSelectedMonth(selectedOption);
    };

    const handleClientChange = (selectedOption: any) => {
        if(selectedOption?.length == 0) {
            setChartsClientShow(false)
        }else{
            setChartsClientShow(true)
        }
        setClientSelected(selectedOption);
    };

    useEffect(() => {
        if (Array.isArray(dataClienteFaturamento)) {
            dataClienteFaturamento.forEach((value) => {
                setColors(value);
            });
        }
    }, [dataClienteFaturamento]);

    const setColors = (data: any) => {
        setPieColors(dataPie(data).datasets);
    }

    const handleClick = (chartIndex: number, sliceIndex: number) => {
        setVisibleSlices(prevState => {
            const chartSlices = prevState[chartIndex] || {};
            const isVisible = !chartSlices[sliceIndex];
            return {
                ...prevState,
                [chartIndex]: {
                    ...chartSlices,
                    [sliceIndex]: isVisible
                }
            };
        });
    };

    const getFilteredDataPie = (chartIndex: number, value: any) => {
        const originalData = dataPie(value);
        return {
            ...originalData,
            datasets: originalData.datasets.map(dataset => ({
                ...dataset,
                data: dataset.data.map((data, index) => (visibleSlices[chartIndex]?.[index] === false || null ? 0 : data)),
                backgroundColor: dataset.backgroundColor.map((color, index) => (visibleSlices[chartIndex]?.[index] === false ? 'transparent' : color))
            }))
        };

    };

    const formatarCompetencia = (competencia: string) => {
        const meses = [
            'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
        ];

        const [ano, mes] = competencia.split('/');
        const mesFormatado = meses[parseInt(mes, 10) - 1];
        return `${mesFormatado}/${ano}`;
    };

    const handleButtonClick = (params: any) => {
        const url = `${window.location.origin}/#/financeiro/fatura?competencia=${params.competencia}`;
        window.open(url, '_blank');
    };

    return (
        <div className={'container-fluid'}>
            <div className="filter-section ">
                <CForm>
                    <CRow>
                        <div className={`col-12 col-md-3`}>
                            <div className="form-group select-container">
                                <Select
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    options={anosData}
                                    onChange={handleYearChange}
                                    placeholder="Ano"
                                    name="ano"
                                    value={selectedYear}
                                    className="filter-select-ano"
                                />
                            </div>
                        </div>
                        <div className={`col-12 col-md-3`}>
                            <div className="form-group select-container">
                                <div className="form-group select-container">
                                    <Select
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        placeholder="Mes"
                                        name="mes"
                                        options={monthOptions}
                                        value={selectedMonth}
                                        onChange={handleMonthChange}
                                        className="filter-select-mes"
                                        styles={{menu: base => ({...base, zIndex: 9997})}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-md-4 mb-5 mb-md-0 justify-content-end`}>
                            <div className={'d-md-flex'} style={{maxHeight: '100%'}}>
                            {!loading ? (
                                <>
                                    <CButton
                                        onClick={() => getData()}
                                        color="primary"
                                        className="clear-filters-btn mr-2 col"
                                    >
                                        Atualizar
                                    </CButton>
                                    <CButton
                                        onClick={() => {
                                            setSelectedMonth([]);
                                            setSelectedYear(yearsInitial);
                                        }}
                                        color="info"
                                        className="apply-filters-btn text-nowrap mt-3 mt-md-0 col"
                                    >
                                        Limpar Filtros
                                    </CButton>
                                </>
                            ) : (
                                <div className="spinner-border applied-filters text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}
                            </div>
                        </div>
                    </CRow>
                </CForm>
            </div>
            <div className="mt-3 bg-white">
                <CRow>
                    <div className={'content-center container mt-2 font-lg font-weight-bold'}> Faturas Pagas X Pendentes
                    </div>
                    <div className={'col-12 container'}>
                        <div className={'p-3'}>
                            {dataHistorico?.labels && (
                                <Line width="1200" height="450" id={'line-chart'}
                                      options={{...options, responsive: true}} data={dataHistorico}/>
                            )}
                        </div>
                    </div>
                </CRow>
                <CRow className={'mt-3 mb-3'}>
                    <div className={'content-center container mt-2 font-lg font-weight-bold'}> Faturamento de Clientes</div>
                    <div className={`col col-md-8`}>
                    </div>
                    <div className="form-group col-12 col-md">
                        <div className={'container-fluid'}>
                            <Select
                                isMulti={true}
                                closeMenuOnSelect={false}
                                placeholder="Cliente"
                                name="cliente"
                                options={listClientes}
                                value={getClienteSelected}
                                onChange={handleClientChange}
                                className="filter-select-mes"
                                styles={{menu: base => ({...base, zIndex: 9997})}}
                            />
                        </div>
                    </div>
                    {chartsClientShow ? (
                            <div className={'col-12'}>
                                <div className={'p-3'}>
                                    {dataFaturas && (
                                        <Line width="1200" height="450" id={'line-chart'}
                                              options={{...options, responsive: true}} data={dataFaturas}/>
                                    )}
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className={'col-12'}>
                                <div className="container-fluid pt-0">
                                    <div className="alert alert-info text-center" role="alert">
                                        Selecione pelo menos um cliente para visualizar o gráfico de faturamento.
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </CRow>

                <div className={'container-fluid bg-white'}>
                    {!notDataClienteFaturas ?
                        <CRow>
                            {Array.isArray(dataClienteFaturamento) && dataClienteFaturamento?.map((value: any, index: number) => (
                                <div className={'col-xl-6 container-graficos-financeiro col-md-12 col-12  mt-2 mb-4'}
                                     key={index}>
                                    <div className={'grafico-inside container-fluid border'}>
                                        <div className={'text-center font-2xl competencia'}>
                                            {formatarCompetencia(value?.competencia)}
                                        </div>
                                        <CRow className="align-items-center">
                                            <div className={'col-md-5 col-12'}>
                                                <Pie
                                                    id={`line-chart-${index}`}
                                                    options={{
                                                        ...optionsClienteFaturas,
                                                        responsive: true,
                                                    }}
                                                    data={getFilteredDataPie(index, value)}
                                                />
                                            </div>
                                            <div className={'col-md-7  mt-3 mt-md-0 col-12'}>
                                                {value?.clientes?.map((cliente: any, clienteIndex: number) => (
                                                    <CRow key={clienteIndex}>
                                                        <div
                                                            className={'col-1 ml-2 ml-md-0'}
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                backgroundColor: pieColors?.[0]?.borderColor[clienteIndex] || '#ccc',
                                                                cursor: 'pointer',
                                                                position: 'relative'
                                                            }}
                                                            onClick={() => handleClick(index, clienteIndex)}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    background: !visibleSlices[index]?.[clienteIndex]
                                                                        ? '#bfbfc0'
                                                                        : '',
                                                                }}
                                                            />
                                                        </div>
                                                        <div title={cliente.cliente}
                                                             className={'col-4 col-md-6 overflow-hidden text-nowrap'}
                                                             style={{
                                                                 textDecoration: !visibleSlices[index]?.[clienteIndex]
                                                                     ? 'line-through'
                                                                     : ''
                                                             }}>
                                                            {cliente.cliente}
                                                        </div>
                                                        <div className={'col-md-3 col-4'}
                                                             style={{textDecoration: !visibleSlices[index]?.[clienteIndex] ? 'line-through' : ''}}>{numberDecimalFormatForm(cliente.valorTotal)}</div>
                                                        <div className={'col-2'}
                                                             style={{textDecoration: !visibleSlices[index]?.[clienteIndex] ? 'line-through' : ''}}>{cliente.percentual}%
                                                        </div>
                                                    </CRow>
                                                ))}
                                            </div>
                                        </CRow>
                                        <div className={'header border-top text-md-left mt-3'}>
                                            <div
                                                className={'row justify-content-center font-weight-bold font-lg'}>Resumo
                                                Faturas
                                            </div>
                                            <CRow
                                                className="p-0 mt-2 text-center justify-content-sm-center justify-content-lg-start justify-content-md-start">
                                                <div className="col-md-9 col-12 row">
                                                    <div className={'col-md-4 col-6 text-nowrap text-right'}>
                                                        <div className={''}
                                                             style={{fontSize: '0.85rem', color: '#2c3e50'}}>
                                                            <span
                                                                style={{color: '#27ae60'}}>●</span> Pago:
                                                        </div>
                                                        <div
                                                            style={{fontSize: '0.85rem', color: '#2c3e50'}}>
                                                           <span
                                                               style={{color: '#e74c3c'}}>●</span> Pendente:
                                                        </div>
                                                        <div className="font-weight-bold"
                                                             style={{fontSize: '0.85rem', color: '#2c3e50'}}>
                                                            <span
                                                                style={{color: '#3498db'}}>●</span> Total:
                                                        </div>
                                                    </div>
                                                    <div className={'col-md-7 text-left col-6'}>
                                                        <div style={{fontSize: '0.85rem', color: '#2c3e50'}}>
                                                            {value?.valorPago ? numberDecimalFormatForm(value?.valorPago) : "R$ 0,00"}
                                                        </div>
                                                        <div style={{fontSize: '0.85rem', color: '#2c3e50'}}>
                                                            {value?.valorPendente? numberDecimalFormatForm(value?.valorPendente) : "R$ 0,00"}
                                                        </div>
                                                        <div className="font-weight-bold" style={{fontSize: '0.85rem', color: '#2c3e50'}}>
                                                            {numberDecimalFormatForm(value?.valorTotalTodos)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-md-3 col-12 justify-content-end align-content-end mt-4 text-right">
                                                    <CButton onClick={() => {
                                                        handleButtonClick(value)
                                                    }} type="button" color="info">
                                                        Ver Detalhes
                                                    </CButton>
                                                </div>
                                            </CRow>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </CRow>
                        :
                        <div className="no-records-container">
                            <CRow>
                                <CCol className="text-center">
                                    <h4>Nenhum registro encontrado</h4>
                                    <p>Por favor, tente novamente.</p>
                                </CCol>
                            </CRow>
                        </div>
                    }
                </div>
            </div>
            <div className={'m-2 footer-filter-client-status'}>
                <div><strong>Total de Registros: {dataClienteFaturamento?.length}</strong></div>
            </div>
        </div>
    );
};

export default ResumoDashboard;

