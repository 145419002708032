import enumDescription from "../../services/enumDescription";
import {ContentRoute} from "../../models/Route";
import NotificacaoList from "./notificacaoList";
import NotificacaoForm from "./notificacaoForm";
import NotificacaoLogList from "./notificacaoLogList";


export enum NotificacaoSituacao {
    SITUACAO_ABERTO  = 1,
    SITUACAO_ENVIAR  = 2,
    SITUACAO_ENVIADO = 3,
    SITUACAO_ERRO    = 4
}

export enum NotificacaoLogSituacao {
    SITUACAO_ENVIADO = 1,
    SITUACAO_ERRO    = 2,
    SITUACAO_LIDA    = 3
}

export enum NotificacaoDestinatario {
    DESTINATARIO_TODOS              = 1,
    DESTINATARIO_GATEWAY_PAGAMENTO  = 2,
    DESTINATARIO_MARKETPLACE        = 3,
    DESTINATARIO_SELECIONAR_CLIENTE = 4,
    DESTINATARIO_REGIME_TRIBUTACAO  = 5
}

export enum NotificacaoTipo {
    TIPO_NOTIFICACAO_AVISOS = 1,
    TIPO_NOTIFICACAO_COBRANCAS  = 2,
    TIPO_NOTIFICACAO_GATEWAY_PAGAMENTO  = 3,
    TIPO_NOTIFICACAO_MARKETPLACE = 4,
    TIPO_NOTIFICACAO_MARKETING = 5
}

export interface NotificacaoDataFormData {
    id:number
    titulo:string
    descricao:string
    situacao:number
}

export const NotificacaoSituacaoList = new enumDescription(  [
    {value: NotificacaoSituacao.SITUACAO_ABERTO, description: 'Em Aberto'},
    {value: NotificacaoSituacao.SITUACAO_ENVIAR, description: 'Liberado para Envio'},
    {value: NotificacaoSituacao.SITUACAO_ENVIADO, description: 'Enviado'},
    {value: NotificacaoSituacao.SITUACAO_ERRO, description: 'Erro'}
]);

export const NotificacaoLogSituacaoList = new enumDescription(  [
    {value: NotificacaoLogSituacao.SITUACAO_ENVIADO, description: 'Enviado'},
    {value: NotificacaoLogSituacao.SITUACAO_ERRO, description: 'Erro'},
    {value: NotificacaoLogSituacao.SITUACAO_LIDA, description: 'Lida'}
]);

export const NotificacaodestinatarioList = new enumDescription(  [
    {value: NotificacaoDestinatario.DESTINATARIO_TODOS, description: 'Todos Clientes (Ativo/Homologados)'},
    {value: NotificacaoDestinatario.DESTINATARIO_GATEWAY_PAGAMENTO, description: 'Filtro por Gateway/Forma de Pagamento'},
    {value: NotificacaoDestinatario.DESTINATARIO_MARKETPLACE, description: 'Filtro por Marketplace'},
    {value: NotificacaoDestinatario.DESTINATARIO_REGIME_TRIBUTACAO, description: 'Filtro por Regime de Tributação'},
    {value: NotificacaoDestinatario.DESTINATARIO_SELECIONAR_CLIENTE, description: 'Filtro de Cliente(s)'}
]);

export const NotificacaoTipoList = new enumDescription(  [
    {value: NotificacaoTipo.TIPO_NOTIFICACAO_AVISOS, description: 'Avisos Gerais'},
    {value: NotificacaoTipo.TIPO_NOTIFICACAO_COBRANCAS, description: 'Financeiro - Aviso sobre Cobranças/Faturas'},
    {value: NotificacaoTipo.TIPO_NOTIFICACAO_GATEWAY_PAGAMENTO, description: 'Integrações/Gateways de Pagamento'},
    {value: NotificacaoTipo.TIPO_NOTIFICACAO_MARKETPLACE, description: 'Integrações de Marketplaces'},
    {value: NotificacaoTipo.TIPO_NOTIFICACAO_MARKETING, description: 'Integrações de Marketing'}
]);

const notificacao_content_routes: Array<ContentRoute> = [
    {
        'path':'/notificacao',
        'description': 'Loglist',
        'exact':true,
        'component': NotificacaoList,
        'menu': true
    },
    {
        'path':'/notificacao/add',
        'description': 'Notificação',
        'exact':true,
        'component': NotificacaoForm,
        'menu': false
    },
    {
        'path':'/notificacao/alterar/:id',
        'description': 'Notificação',
        'exact':true,
        'component': NotificacaoForm,
        'menu': false
    },
    {
        'path':'/notificacao/visualizar/:id',
        'description': 'Notificação',
        'exact':true,
        'component': NotificacaoForm,
        'menu': false
    },
    {
        'path':'/notificacao/liberar/:id',
        'description': 'Liberar para Envio',
        'exact':true,
        'component': NotificacaoForm,
        'menu': false
    },
    {
        'path':'/notificacao/log/:id',
        'description': 'Log de Envio',
        'exact':true,
        'component': NotificacaoLogList,
        'menu': false
    }
]

export default notificacao_content_routes;