import enumDescription from "../../../services/enumDescription";
import {ContentRoute} from "../../../models/Route";
import PessoaList from './pessoaList';
import PessoaForm from "./pessoaForm";
import {SituacaoTypes} from "../cliente";

export interface PessoaFormData {
    tipo: number;
    nome: string | undefined;
    cpfCnpj: string;
    nomeFantasia: string;
    contribuinteICMS: boolean;
    ie: string;
    rg: string;
    dataNascimento: string;
    genero: number;
    pessoaContato: Array<PessoaContatoFormData>
    pessoaEndereco: Array<PessoaEnderecoFormData>
}

export interface PessoaContatoFormData {
    tipo:number
    contato:string
    setor:number | undefined
}

export interface PessoaEnderecoFormData {
    tipo:number
    cidade?:object
    logradouro:string
    numero?:number
    bairro?:string
    complemento?:string
    cep?:number

}

export enum TipoPessoaTypes {
    FISICA   = 1,
    JURIDICA = 2,
}

export enum GeneroPessoaTypes {
    MASCULINO = 1,
    FEMININO  = 2,
}

export enum TipoContatoTypes {
    EMAIL       = 1,
    CELULAR     = 2,
    COMERCIAL   = 3,
    RESIDENCIAL = 4,
    SKYPE       = 5,
}

export enum SetorTypes {
    SETOR_DIRETOR     = 1,
    SETOR_COMERCIAL   = 2,
    SETOR_FINANCEIRO  = 3,
    SETOR_FATURAMENTO = 4,
    SETOR_EXPEDICAO   = 5,
    SETOR_CADASTRO    = 6,
    SETOR_OUTROS      = 7,
}

export enum TipoEnderecoTypes {
    RESIDENCIAL = 1,
    COMERCIAL  = 2,
}


export const TipoEnderecoList = new enumDescription(  [
    {   value: TipoEnderecoTypes.RESIDENCIAL, description: 'Residencial'    },
    {   value: TipoEnderecoTypes.COMERCIAL, description: 'Comercial'    }
]);

export const TipoPessoaList = new enumDescription(  [
    {   value: TipoPessoaTypes.FISICA, description: 'Física'    },
    {   value: TipoPessoaTypes.JURIDICA, description: 'Jurídica'    }
]);

export const GeneroPessoaList = new enumDescription(  [
    {   value: GeneroPessoaTypes.MASCULINO, description: 'Masculino'    },
    {   value: GeneroPessoaTypes.FEMININO, description: 'Feminino'    }
]);

export const TipoContatoList = new enumDescription(  [
    {   value: TipoContatoTypes.EMAIL, description: 'E-mail'    },
    {   value: TipoContatoTypes.CELULAR, description: 'Celular'    },
    {   value: TipoContatoTypes.COMERCIAL, description: 'Comercial'    },
    {   value: TipoContatoTypes.RESIDENCIAL, description: 'Residencial'    },
    {   value: TipoContatoTypes.SKYPE, description: 'Skype'    },
]);

export const SetorList = new enumDescription(  [
    {   value: SetorTypes.SETOR_DIRETOR, description: 'Diretor'    },
    {   value: SetorTypes.SETOR_COMERCIAL, description: 'Comercial'    },
    {   value: SetorTypes.SETOR_FINANCEIRO, description: 'Financeiro'    },
    {   value: SetorTypes.SETOR_FATURAMENTO, description: 'Faturamento'    },
    {   value: SetorTypes.SETOR_EXPEDICAO, description: 'Expedicao'    },
    {   value: SetorTypes.SETOR_CADASTRO, description: 'Cadastro'    },
    {   value: SetorTypes.SETOR_OUTROS, description: 'Outros'    },
]);

export const pessoa_content_routes: Array<ContentRoute> = [
    {
        'path':'/pessoa',
        'description': 'Pessoa',
        'exact':true,
        'component': PessoaList,
        'menu': true
    },
    {
        'path':'/pessoa/incluir',
        'description': 'Incluir Pessoa',
        'exact':true,
        'component': PessoaForm,
        'menu': false
    },
    {
        'path':'/pessoa/alterar/:id',
        'description': 'Alterar Pessoa',
        'exact':true,
        'component': PessoaForm,
        'menu': false
    },
    {
        'path':'/pessoa/visualizar/:id',
        'description': 'Visualizar Pessoa',
        'exact':true,
        'component': PessoaForm,
        'menu': false
    }
]