import {ContentRoute} from "../../../models/Route";
import SetorList from "./SetorList";
import SetorForm from "./setorForm";
import SetorUsuarioList from "./setorUsuarioList";


export const setor_content_routes: Array<ContentRoute> = [
    {
        'path':'/setor',
        'description': 'Setor',
        'exact':true,
        'component': SetorList,
        'menu': true
    },
    {
        'path':'/setor/incluir',
        'description': 'Incluir Setor',
        'exact':true,
        'component': SetorForm,
        'menu': false
    },
    {
        'path':'/setor/alterar/:id',
        'description': 'Alterar Setor',
        'exact':true,
        'component': SetorForm,
        'menu': false
    },
    {
        'path':'/setor/visualizar/:id',
        'description': 'Visualizar Setor',
        'exact':true,
        'component': SetorForm,
        'menu': false
    },
    {
        'path':'/setor/usuario',
        'description': 'Visualizar Setor e Usuario',
        'exact':true,
        'component': SetorUsuarioList,
        'menu': false
    },
    ]