import React, {useEffect, useState} from 'react'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'

import CIcon from '@coreui/icons-react'

// sidebar nav config
import processDataAsycn from './_nav'

const TheSidebar = () => {
  const show = true;
  const [dataMenu,setDataMenu] = useState([]);

  useEffect(() => {
    if(dataMenu.length == 0){
      console.log('queremos nosso menu...');
      processDataAsycn.then((menus : any) => {
        console.log('navigation loaded');
        setDataMenu(menus);
      });
    }
  },[]);



  return (
    <CSidebar
      show={show}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <CIcon name="logoHeader" height={35}   />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={dataMenu}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer/>
    </CSidebar>
  )
}

export default React.memo(TheSidebar)