import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';
import {TypeMockupEnum} from "./index";
import enumDescription from "../../../services/enumDescription";

const MockupList = (props:any) => {

    const TypeListFormater = (params: any) => {
        return TypeMockupEnum.getDescription(params.value);
    }

    const actions: Array<ActionProps> = [
        {
            route:'mockup/incluir',
            color:'success',
            type:'normal',
            title:'Incluir'
        },
        {
            route:'mockup/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'mockup/delete',
            color:'danger',
            type:'delete',
            title:'Excluir'
        }
    ];

    return (
        <Grid actions={actions} rota="mockup/list">
            <AgGridColumn sort="desc" width={100} headerName="#" checkboxSelection field="id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn flex={1} minWidth={200} field="name"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn minWidth={200} headerName="Tipo" field="type" sortable={ true } filter={ FiltroTipo } valueFormatter={TypeListFormater}></AgGridColumn>
        </Grid>
    );
};

class FiltroTipo extends ListaMultiplaFilter {
    getList(): enumDescription {
        return TypeMockupEnum;
    }
}

export default MockupList;