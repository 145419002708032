import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';
import enumDescription from "../../../services/enumDescription";
import {TipoProdutoList} from "./index";
import {CellClickedEvent} from "ag-grid-community";
import {useLocation} from "react-router-dom";

const ClienteProdutoList = (props:any) => {
    const actions: Array<ActionProps> = [];

    let location = useLocation();
    let idLocation = location.pathname.split('/');
    const tipoProdutoListFormater  = (params:any) =>{
        return TipoProdutoList.getDescription(params.value);
    }

    const handleCellClick = (event:CellClickedEvent)=>{
        if(event.colDef.field == 'url'){
            window.open(event.data.url, '_blank');
        }
    }

    return (
        <Grid actions={actions} rota={idLocation[idLocation.length - 1].length != 0 ? "cliente/produto/list/"+idLocation[idLocation.length - 1] : "cliente/produto/list"} onCellClicked={handleCellClick}>
            <AgGridColumn headerName="#Cliente" checkboxSelection field="cliente/id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn headerName="Cliente"  flex={1} field="pessoa/nome"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn headerName="Tipo" field={"tipo"}  sortable={ true } filter={ FiltroTipoProduto } valueFormatter={tipoProdutoListFormater}></AgGridColumn>
            <AgGridColumn cellClass={"produto-url-cell"} headerName="Link" flex={1} field={"url"} sortable={ true } filter={ true } ></AgGridColumn>
        </Grid>
    );
};

class FiltroTipoProduto extends ListaMultiplaFilter{
    getList(): enumDescription {
        return TipoProdutoList;
    }
}

export default ClienteProdutoList;