import {useDispatch} from "react-redux";
import {AlertType} from "../store/ducks/alert/types";
import {addAlert} from "../store/ducks/alert/actions";

const useAlert = ()=>{
    const dispatch = useDispatch();

    const showAlert = (alert:AlertType)=>dispatch(addAlert(alert))

    return [showAlert];
}

export default useAlert;