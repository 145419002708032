import React, {useEffect, useState} from 'react'
import {
  Route,
  Switch
} from 'react-router-dom';
import {
    CButton, CContainer, CModal,
    CModalBody, CModalFooter, CModalHeader,
    CModalTitle, CTextarea, CToast,
    CToastBody, CToaster, CToastHeader
} from '@coreui/react';
import {useDispatch, useSelector} from "react-redux";
import {contentRoutes} from "../../contentRoutes";
import {ContentRoute} from "../../models/Route";
import Loading from "../../components/loader";
import {States} from "../../store/ducks/rootReducer";
import {LoadingStatusTypes} from "../../store/ducks/loading/types";
import {removeAlert, clearAlerts} from "../../store/ducks/alert/actions";
import {createSelector} from "reselect";

interface ErrorModalProps{
    msg:string,
    title?:string,
    details?:string
    id?:number
    type?:'danger' | 'warning' | 'success',
    fnOk?: any
}

interface ToastyProps extends ErrorModalProps{}

const ErrorModal = (props:ErrorModalProps) =>{
    const [showDetail, setShowDetail]  = useState(false);
    const [showModal, setShowModel]  = useState(true);

    const dispatch = useDispatch();

    const handleOnCloseModal = ()=>{
        if(props.id){
            dispatch(removeAlert(props.id));
        }
    }

    const handleOnClickOk = ()=>{
        setShowModel(false);
        if(props.fnOk){
            props.fnOk();
        }
    }

    return (
        <CModal
            show={showModal}
            onClosed={handleOnCloseModal}
            color="danger"
        >
            <CModalHeader closeButton>
                <CModalTitle>{props.title}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <p className="pb-2">
                    {props.msg}
                </p>
                {showDetail && <CTextarea rows={8} readOnly={true} value={props.details}></CTextarea> }
            </CModalBody>
            <CModalFooter>
                <CButton color="success" onClick={handleOnClickOk}>
                    Ok
                </CButton>
                {props.details && (
                    <CButton color="secondary" onClick={()=>setShowDetail(!showDetail)}>
                        Detalhes
                    </CButton>
                )}
            </CModalFooter>
        </CModal>
    )
}

const Toasty = (props:ToastyProps)=>{
    return (
        <CToast
            key={'toast' + props.id}
            show={true}
            autohide={3000}
            fade={true}
            color={props.type ? props.type : "success"}
        >
            {props.title && (
                <CToastHeader closeButton={true}>
                    {props.title}
                </CToastHeader>
            )}
            <CToastBody>
                {props.msg}
            </CToastBody>
        </CToast>
    )
}

const TheContent = () => {
  var allRoutes: Array<ContentRoute> = [];
  const loadingState = useSelector((state:States)=> state.loading);
  const dispatch = useDispatch();

  const modalErrorSelect = createSelector((state:States)=>state.alert, alert=>alert.alerts.filter(alert=>!alert.toaster));
  const toastSelect      = createSelector((state:States)=>state.alert, alert=>alert.alerts.filter(alert=>alert.toaster));

  const modalsErrors = useSelector(modalErrorSelect);
  const toasts       = useSelector(toastSelect);
  const alerts       = useSelector((state:States)=>state.alert.alerts);

  contentRoutes.map((current)=>{
      allRoutes = allRoutes.concat(current.children);
  });

  useEffect(()=>{
      if(alerts.length >= 10){
          dispatch(clearAlerts());
      }
  }, [alerts]);

  return (
    <main>
        {loadingState.state === LoadingStatusTypes.DISPLAY_ON && (<Loading/>)}
      <CContainer fluid className={'render-container'}>
          {modalsErrors.map(alert=>(
              <ErrorModal key={alert.id} {...alert} />
          ))}

          <CToaster
              position="bottom-right"
          >
              {toasts.map(toast => (
                  <Toasty key={toast.id} {...toast}/>
              ))}
          </CToaster>
          <Switch>
            {allRoutes && allRoutes.map(route=>(
                 <Route key={route?.path} {...route}/>
            ))}
          </Switch>
      </CContainer>
    </main>
  )
}

export default TheContent