import { Reducer } from 'redux';
import {ActionsTypes, LoadingState, LoadingStatusTypes} from './types';

export const INITIAL_STATE: LoadingState = {
    state: LoadingStatusTypes.DISPLAY_OFF
}

const reducer: Reducer<LoadingState> = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case ActionsTypes.HIDE:
            return {state:LoadingStatusTypes.DISPLAY_OFF}
        case ActionsTypes.SHOW:
            return {state:LoadingStatusTypes.DISPLAY_ON}
        default:
            return state
    }
}

export default reducer