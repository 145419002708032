import {ContentRoute} from "../../../models/Route";
import MockupList from "./mockupList";
import MockupForm from "./mockupForm";
import OperationList from "../operation/operationList";
import enumDescription from "../../../services/enumDescription";
import {AtivoTypes} from "../../cadastros/checklist";

export const DinamicTypesFisico = [
    { value: 'nome', description: 'Nome' },
    { value: 'idade', description: 'Idade' },
    { value: 'cpf', description: 'CPF' },
    { value: 'rg', description: 'RG' },
    { value: 'data_nasc', description: 'Data de Nascimento' },
    { value: 'sexo', description: 'Sexo' },
    { value: 'signo', description: 'Signo' },
    { value: 'mae', description: 'Nome da Mãe' },
    { value: 'pai', description: 'Nome do Pai' },
    { value: 'email', description: 'E-mail' },
    { value: 'senha', description: 'Senha' },
    { value: 'cep', description: 'CEP' },
    { value: 'endereco', description: 'Endereço' },
    { value: 'numero', description: 'Número' },
    { value: 'bairro', description: 'Bairro' },
    { value: 'cidade', description: 'Cidade' },
    { value: 'estado', description: 'Estado' },
    { value: 'telefone_fixo', description: 'Telefone Fixo' },
    { value: 'celular', description: 'Celular' },
    { value: 'altura', description: 'Altura' },
    { value: 'peso', description: 'Peso' },
    { value: 'tipo_sanguineo', description: 'Tipo Sanguíneo' },
    { value: 'cor', description: 'Cor' }
];


export const DinamicTypesJuridico = [
    { value: 'nome', description: 'Nome' },
    { value: 'cnpj', description: 'CNPJ' },
    { value: 'ie', description: 'IE' },
    { value: 'data_abertura', description: 'Data de Abertura' },
    { value: 'site', description: 'Site' },
    { value: 'email', description: 'E-mail' },
    { value: 'cep', description: 'CEP' },
    { value: 'endereco', description: 'Endereço' },
    { value: 'numero', description: 'Número' },
    { value: 'bairro', description: 'Bairro' },
    { value: 'cidade', description: 'Cidade' },
    { value: 'estado', description: 'Estado' },
    { value: 'telefone_fixo', description: 'Telefone Fixo' },
    { value: 'celular', description: 'Celular' },
];

export enum TypeMockup {
    TYPE_MOCK_TEST = 1,
    TYPE_MOCK_RESPONSE = 2
}

export const TypeMockupEnum = new enumDescription([
    {description: 'Dados para execução', value: TypeMockup.TYPE_MOCK_TEST},
    {description: 'Response API de Teste', value: TypeMockup.TYPE_MOCK_RESPONSE},
]);

export const TypeMockupList = () => {
    return Object.keys(TypeMockupEnum)
    .filter(key => isNaN(Number(key)))
    .map(key => ({ label: key, value: TypeMockupEnum[key as keyof typeof TypeMockupEnum] }));
}

export const mockup_content_routes: Array<ContentRoute> = [
    {
        'path':'/mockup',
        'description': 'Mockups',
        'exact':true,
        'component': MockupList,
        'menu': true
    },
    {
        'path':'/mockup/incluir',
        'description': 'Novo Mockup',
        'exact':true,
        'component': MockupForm,
        'menu': false
    },
    {
        'path':'/mockup/alterar/:id',
        'description': 'Alterar Mockup',
        'exact':true,
        'component': MockupForm,
        'menu': false
    }
]