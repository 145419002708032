import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';
import {SetorList} from './index'
import enumDescription from "../../../services/enumDescription";
import {AtivoList} from "../usuario";

const ChecklistList = (props:any) => {
    const  AtivoListFormater = (params:any) =>{
        return AtivoList.getDescription(params.value);
    }
    const setorListFormater  = (params:any) =>{
        return SetorList.getDescription(params.value);
    }

    const actions: Array<ActionProps> = [
        {
            route:'checklist/incluir',
            color:'success',
            type:'normal',
            title:'Incluir'
        },
        {
            route:'checklist/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'checklist/visualizar',
            color:'info',
            type:'grid',
            title:'Visualizar',
        },
        {
            route:'checklist/toggle_status',
            color:'dark',
            type:'post',
            title:'Ativar/Desativar',
        },
        {
            route:'checklist/itens',
            color:'success',
            type:'grid',
            title:'Itens'
        },
        // {
        //     route:'checklist/copiar',
        //     color:'primary',
        //     type:'custom',
        //     onClickFunction: rows => {
        //         alert('clicou');
        //         console.log(rows);
        //     },
        //     title:'Copiar',
        // }
    ];

    return (
        <Grid actions={actions} rota="checklist/list">
            <AgGridColumn sort="desc" width={100} headerName="#" checkboxSelection field="id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn flex={1} minWidth={200} field="nome"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn width={200} field="setor"  sortable={ true } filter={ FiltroSetor } valueFormatter={setorListFormater}></AgGridColumn>
            <AgGridColumn width={100} field="ativo"  sortable={ true } filter={ FiltroAtivo } valueFormatter={AtivoListFormater}></AgGridColumn>
            <AgGridColumn width={100} field="ordem"  sortable={ true } filter={ true } ></AgGridColumn>
            <AgGridColumn width={100} headerName="Versão" field="versao"  sortable={ true } filter={ true }></AgGridColumn>
        </Grid>
    );
};

class FiltroSetor extends ListaMultiplaFilter{
    getList(): enumDescription {
        return SetorList;
    }
}

class FiltroAtivo extends ListaMultiplaFilter{
    getList(): enumDescription {
        return AtivoList;
    }
}

export default ChecklistList;