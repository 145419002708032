import {combineReducers} from 'redux';
import login from './login';
import loading from './loading';
import alert from './alert';
import {LoadingState} from "./loading/types";
import {LoginState} from "./login/types";
import {AlertsState} from "./alert/types";

export default combineReducers({
    login,
    loading,
    alert,
});

export interface States {
  login: LoginState,
  loading: LoadingState,
  alert: AlertsState,
}