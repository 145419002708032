import enumDescription from "../../services/enumDescription";
import {ContentRoute} from "../../models/Route";
import LogList from "./logList";
import TaskForm from "../task/taskForm";
import LogForm from "./logForm";

export enum SituacaoTypes {
    PENDENTE         = 1,
    EM_EXECUCAO      = 2,
    FINALIZADA       = 3,
    CANCELADA        = 4,
}


export enum TiposErrosTypes {
    TIPO_API                            = 1,
    TIPO_SITE                           = 2,
    TIPO_NAO_MAPEADO                    = 3,
    TIPO_FATURAMENTO                    = 4,
    TIPO_SITE_CHECKOUT                  = 5,
    TIPO_INTEGRACAO_PEDIDO              = 6,
    TIPO_GRAVA_PEDIDO                   = 7,
    TIPO_GERACAO_NOTIFICACAO            = 8,
    TIPO_SAC_API                        = 9,
    TIPO_SINCRONIZACAO_PRODUTO          = 10,
    TIPO_PROPAGAR_PROMOCAO              = 11,
    TIPO_GERACAO_ORDEM_COMPRA           = 12,
    TIPO_CONSULTA_FRETE                 = 13,
    TIPO_ADMIN                          = 14,
    TIPO_MENSAGENS                      = 15,
    TIPO_ELASTICSEARCH                  = 16,
    TIPO_DIALOGFLOW                     = 17,
    TIPO_FINANCEIRO                     = 18,
    TIPO_SITE_FRONTEND                  = 19,
    TIPO_NOTIFICACAO_MARKETPLACE        = 20,
    TIPO_FORMA_PAGAMENTO_MARKETPLACE    = 21,
    TIPO_JOB                            = 22,
    TIPO_INFRA                          = 23,
    TIPO_ATUALIZA_PEDIDO_MARKETPLACE    = 24,
    TIPO_ABERTURA_PROCESSO_TEXTO_PADRAO = 25,
    TIPO_ENVIO_LOTE_DE_GNRE             = 26,
    TIPO_API_WHATSAPP                   = 27,
    TIPO_GERACAO_FEED                   = 28,
    TIPO_CONTROLE_LOGISTICA             = 29,
    TIPO_IMPORTACAO_CTE                 = 30,
    TIPO_INTRAGRACAO_FINANCEIRA         = 31,
    TIPO_SOLICITACAO_COLETA             = 32,
    TIPO_CAMPANHA_PUSH                  = 33,
    TIPO_LEITURA_IMAGEM                 = 34,

}

export const SituacaoList = new enumDescription(  [
    {   value: SituacaoTypes.PENDENTE, description: 'Pendente'    },
    {   value: SituacaoTypes.EM_EXECUCAO, description: 'Em Execução'    },
    {   value: SituacaoTypes.FINALIZADA, description: 'Finalizada'    },
    {   value: SituacaoTypes.CANCELADA, description: 'Cancelada'    },
]);

export const TiposErrosList = new enumDescription([
    { value: TiposErrosTypes.TIPO_API, description: 'API' },
    { value: TiposErrosTypes.TIPO_SITE, description: 'Site - Server' },
    { value: TiposErrosTypes.TIPO_NAO_MAPEADO, description: 'Não Mapeado' },
    { value: TiposErrosTypes.TIPO_FATURAMENTO, description: 'Faturamento' },
    { value: TiposErrosTypes.TIPO_SITE_CHECKOUT, description: 'Checkout' },
    { value: TiposErrosTypes.TIPO_INTEGRACAO_PEDIDO, description: 'Integração de Pedido' },
    { value: TiposErrosTypes.TIPO_GRAVA_PEDIDO, description: 'Gravação de Pedido' },
    { value: TiposErrosTypes.TIPO_GERACAO_NOTIFICACAO, description: 'Geração de Notificação' },
    { value: TiposErrosTypes.TIPO_SAC_API, description: 'API do SAC' },
    { value: TiposErrosTypes.TIPO_SINCRONIZACAO_PRODUTO, description: 'Sincronização Produtos Hub' },
    { value: TiposErrosTypes.TIPO_PROPAGAR_PROMOCAO, description: 'Propagação de Promoção' },
    { value: TiposErrosTypes.TIPO_GERACAO_ORDEM_COMPRA, description: 'Geração OC - Venda Ordem' },
    { value: TiposErrosTypes.TIPO_CONSULTA_FRETE, description: 'Cotação de Frete' },
    { value: TiposErrosTypes.TIPO_ADMIN, description: 'Admin' },
    { value: TiposErrosTypes.TIPO_MENSAGENS, description: 'Mensagens' },
    { value: TiposErrosTypes.TIPO_ELASTICSEARCH, description: 'ElasticSearch' },
    { value: TiposErrosTypes.TIPO_DIALOGFLOW, description: 'PanBot IA' },
    { value: TiposErrosTypes.TIPO_FINANCEIRO, description: 'Financeiro' },
    { value: TiposErrosTypes.TIPO_SITE_FRONTEND, description: 'Site - Front' },
    { value: TiposErrosTypes.TIPO_NOTIFICACAO_MARKETPLACE, description: 'Notificação Marketplace' },
    { value: TiposErrosTypes.TIPO_FORMA_PAGAMENTO_MARKETPLACE, description: 'Formas Pagamento Marketplace' },
    { value: TiposErrosTypes.TIPO_JOB, description: 'Job' },
    { value: TiposErrosTypes.TIPO_INFRA, description: 'Infra' },
    { value: TiposErrosTypes.TIPO_ATUALIZA_PEDIDO_MARKETPLACE, description: 'Atualização Pedido Marketplace' },
    { value: TiposErrosTypes.TIPO_ABERTURA_PROCESSO_TEXTO_PADRAO, description: 'Abertura de Processo por Seleção de Texto Padrão (SAC)' },
    { value: TiposErrosTypes.TIPO_ENVIO_LOTE_DE_GNRE, description: 'Envio Lote GNRE' },
    { value: TiposErrosTypes.TIPO_API_WHATSAPP, description: 'API WhatsApp' },
    { value: TiposErrosTypes.TIPO_GERACAO_FEED, description: 'Geração Feed' },
    { value: TiposErrosTypes.TIPO_CONTROLE_LOGISTICA, description: 'Integração Financeira' },
    { value: TiposErrosTypes.TIPO_IMPORTACAO_CTE, description: 'Solicitação Coleta' },
    { value: TiposErrosTypes.TIPO_INTRAGRACAO_FINANCEIRA, description: 'Campanha Push' },
    { value: TiposErrosTypes.TIPO_SOLICITACAO_COLETA, description: 'Leitura de Imagem' },
    { value: TiposErrosTypes.TIPO_CAMPANHA_PUSH, description: 'Campanha Push' },
    { value: TiposErrosTypes.TIPO_LEITURA_IMAGEM, description: 'Leitura de Imagem' },
]);

const log_content_routes: Array<ContentRoute> = [
    {
        'path':'/log',
        'description': 'Loglist',
        'exact':true,
        'component': LogList,
        'menu': true
    },
    {
        'path':'/log/visualizar/:id',
        'description': 'Destalhes do Log',
        'exact':true,
        'component': LogForm,
        'menu': false
    },
]

export default log_content_routes;