
const FormatDate = (startDateString: string, endDateString: string) => {
    const optionsDate: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit'
    };

    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    const startDateFormatted = startDate.toLocaleDateString('pt-BR', optionsDate);
    const endDateFormatted = endDate.toLocaleDateString('pt-BR', optionsDate);
    const startTimeFormatted = startDate.toLocaleTimeString('pt-BR', optionsTime);
    const endTimeFormatted = endDate.toLocaleTimeString('pt-BR', optionsTime);

    if (endDateFormatted === 'Invalid Date'){
         return `${startDateFormatted} ${startTimeFormatted}`;
    }
    if (startDateFormatted === endDateFormatted) {
        return `${startDateFormatted} ${startTimeFormatted} às ${endTimeFormatted}`;
    } else {
        return `${startDateFormatted} ${startTimeFormatted} até ${endDateFormatted} ${endTimeFormatted}`;
    }
};

export default FormatDate;
