import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';

const SetorList = (props:any) => {
    const actions: Array<ActionProps> = [
        {
            route:'setor/incluir',
            color:'success',
            type:'normal',
            title:'Incluir'
        },
        {
            route:'setor/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'setor/visualizar',
            color:'info',
            type:'grid',
            title:'Visualizar',
        },
        {
            route:'setor/delete',
            color:'danger',
            type:'delete',
            title:'Excluir',
        },
        {
            route:'setor/usuario',
            color:'info',
            type:'normal',
            title:'Setor X Usuário',
        }
    ];

    return (
        <Grid actions={actions} rota="setor/list">
            <AgGridColumn  width={150} sort="desc" headerName="#Setor" checkboxSelection field="id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn flex={1} field="nome"  headerName="Nome do Setor" sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn headerName="Código do Setor" field="codigo"  sortable={ true } ></AgGridColumn>
            <AgGridColumn flex={1} headerName="Setor Pai" field="setorPai/nome"  sortable={ true } filter={ true }></AgGridColumn>
        </Grid>
    );
}

export default SetorList;