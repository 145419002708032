import React, {useState, useEffect} from 'react';
import {
    CFormGroup,
    CLabel,
    CInput,
} from '@coreui/react';
import useCustomForm from '../../../services/useCustomForm';
import Form, {FormApi} from '../../../components/form';
import useCurrentAction from "../../../services/useCurrentAction";
import {getApi} from "../../../apis/backendApis";
import Select from "react-select";


const SetorForm = (props: any) => {

    const [formApi, setFormApi] = useState<FormApi>();
    const action = useCurrentAction();
    const [listSetores, setListSetores] = useState([]);
    const [selectedSetor, setSelectedSetor] = useState([])
    let [initialUsuario, setInitialUsuario] = useState({})
    const [listUsuarios, setListUsuarios] = useState([]);
    const [effect, setEffect] = useState(false);
    const getList = (list: any) => {
        let data: any = [];
        Object.keys(list).forEach((key) => {
            const cliente = list[key];
            data.push({
                label: Object.values(cliente)[0],
                value: Object.keys(cliente)[0]
            });
        });
        return data;
    }

    const getInitialValueUsuario = () => {
        return {
            id: '',
        };
    }


    const initialValues: any = {
        nome: '',
        codigo: '',
        setorUsuarios: []
    }
    const isView = () => {
        return action === 'view';
    };

    const isEdit = () => {
        return action === 'edit';
    };

    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        handleChangeSuggest,
    } = useCustomForm(
        {
            initialValues,
            route: 'setor',
            onSubmit: (values) => {
                let newValues = {...values.values};
                handleSubmitValues(newValues)
            },
            onLoadDadosCompleted: (data) => {
                setValues(data);
            }
        }
    );

    const [conutValues, setCountValues] = useState(values.setorUsuarios.length)

    useEffect(() => {
            let api = getApi();
            api.get("/setor/suggest").then((response)=> {
                if (response.data['setores']) {
                    setListSetores(getList(response.data['setores']));
                }
            });
            api.get("/usuarios/suggest").then((response)=>{
                let usuarios = [];
                if (response.data['usuarios']) {
                    usuarios = getList(response.data['usuarios']);
                }
                setListUsuarios(usuarios);
            })
    }, []);

    useEffect(() => {
        const fetchData = async () => {
        updateListUsuarios(listUsuarios);
        };
        fetchData();
    }, [values.id]);

    const updateListUsuarios = (usuarios: any[]) => {
        const existingUserIds = values.setorUsuarios.map((setorUsuario: any) => setorUsuario.usuario.id);
        const filteredUsuarios: any = usuarios.filter((usuario: any) => !existingUserIds.includes(parseInt(usuario.value)));
        setListUsuarios(filteredUsuarios);
        setInitialUsuario(getInitialValueUsuario());
        if (values.id > 0)
            setEffect(true);
    }

    const handleSetor = (selectedOption: any) => {
        setSelectedSetor(selectedOption);
        values.setorPai = parseInt(selectedOption.value)
        setValues({...values})
    };

    const handleUsuario = (selectedOption: any) => {
        setInitialUsuario(getInitialValueUsuario());
        values.setorUsuarios = selectedOption.map((option: any) => ({
            id: option.value,
            usuario: {
                id: option.value,
                nome: option.label,
            }
        }));
        setValues({...values});
        getUsers();
    };
    const getUsers =()=>{
        let api = getApi();
        api.get("/usuarios/suggest").then((response)=>{
            let usuarios = [];
            if (response.data['usuarios']) {
                usuarios = getList(response.data['usuarios']);
            }
            setListUsuarios(usuarios);
            updateUsers(usuarios)
        })
    }
    const updateUsers =(usuarios: any) =>{
        updateListUsuarios(usuarios);
    };

    const handleFormReady = (formApi: FormApi) => {
        setFormApi(formApi);
    }
    return (
        <Form entity="Usuario" handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CFormGroup>
                <CLabel htmlFor="pessoa">Nome do Setor</CLabel>
                <CInput
                    readOnly={isView()}
                    disabled={isView()}
                    type="text"
                    name="nome"
                    onChange={handleChange}
                    value={values.nome}
                    required/>
            </CFormGroup>
            <CFormGroup>
                <CLabel htmlFor="email">Código Setor</CLabel>
                <CInput
                    readOnly={isView()}
                    disabled={isView()}
                    type="text"
                    name="codigo"
                    onChange={handleChange}
                    value={values.codigo}
                    required/>
            </CFormGroup>
            <CFormGroup>
                <CLabel htmlFor="senha">Setor Pai</CLabel>
                <Select
                    options={listSetores}
                    isDisabled={isView()}
                    type="password"
                    name="setorPai"
                    onChange={handleSetor}
                    value={listSetores.find((setor: any) => setor.value == values['setorPai']?.['id'])}
                />
            </CFormGroup>
            <CFormGroup>

                <CLabel htmlFor="usuarios">Usuários</CLabel>
                {isEdit() &&
                    effect && (
                        <Select
                            options={listUsuarios}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            isDisabled={isView()}
                            name="usuarios"
                            onChange={handleUsuario}
                            value={values.setorUsuarios.map((setorUsuario: any) => ({
                                value: setorUsuario.usuario.id,
                                setor: setorUsuario.setor,
                                label: setorUsuario.usuario.nome
                            }))}
                        />
                    )}
                {isEdit() &&
                    !effect && (
                        <div className={"row"}>
                            <div className="spinner-border" role="status">
                                <span className="ml-4 sr-only">Loading...</span>
                            </div>
                        </div>
                    )}
                {!isEdit() &&
                    <Select
                        options={listUsuarios}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        isDisabled={isView()}
                        name="usuarios"
                        onChange={handleUsuario}
                        value={values.setorUsuarios?.map((setorUsuario: any) => ({
                            value: setorUsuario.usuario.id,
                            setor: setorUsuario.setor,
                            label: setorUsuario.usuario.nome
                        }))}
                    />
                }
            </CFormGroup>
        </Form>
    );
}

export default SetorForm;