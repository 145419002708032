import {contentRoutes} from "../../contentRoutes";
import {retrieveToken} from "../../services/tokenVerify";
import api from "../../apis/backendApis";
import {Auth} from "../../models/Auth";
import financeiroRouters from "../financeiro";
import dashboardRouter from "../dashboard";
import {sprints_content_routes} from "../dashboard/sprints";


const isFinanceiro = () => new Promise((resolve, reject) => {
    let token =  retrieveToken();
    api.get<Auth>(`/auth/retrieve/${token}`).then(response =>{
        let data = response.data;
        if(data && data.user.tipo === 2){
            resolve(true);
        } else {
            resolve(false);
        }
    }).catch(reason =>{
        reject('erro loading menus');
    });
});

const isManagerDeveloper = () => new Promise((resolve, reject) => {
    let token =  retrieveToken();
    api.get<Auth>(`/auth/retrieve/${token}`).then(response =>{
        let data = response.data;
        if(data && data.user.isManagerTeam){
            resolve(true);
        } else {
            resolve(false);
        }
    }).catch(reason =>{
        reject('erro loading menus');
    });
});

const loadUserData = new Promise((resolve, reject) => {
    let token =  retrieveToken();
    if(token.toString().length > 0){
        api.get<Auth>(`/auth/retrieve/${token}`).then(response =>{
            let data = response.data;
            if(data && data.user){
                resolve(data.user);
            } else {
                resolve(false);
            }
        }).catch((reason : any) =>{
            console.log('erro retrieve',reason);
            reject(false);
        });
    } else {
        console.log('sem token para loading');
        resolve(false);
    }
});


const processDataAsycn = new Promise((resolve, reject) => {
    let menus : any = contentRoutes;
    loadUserData.then((dataUser : any) => {
        let isUserEnable : boolean = (dataUser['tipo'] === 2);
        let isManagerDev : boolean = (dataUser['isManagerTeam']);
        if(isUserEnable) {
            let add = true;
            for(let row in menus){
                if(menus['name'] == 'financeiro'){
                    add = false;
                    break;
                }
            }
            if(add){
                menus.push({
                    'name': 'financeiro',
                    'description': 'Financeiro',
                    'icon': 'cil-dollar',
                    'children': financeiroRouters
                });
            }
        }
        if(isManagerDev) {
            let add = true;
            for(let row in menus){
                if(menus['name'] == 'dashboard_developers'){
                    add = false;
                    break;
                }
            }
            if(add){
                menus.push({
                    'name': 'dashboard_developers',
                    'description': 'Manager Development',
                    'icon': 'cilSpeedometer',
                    'children': sprints_content_routes,
                    'to': '/dashboard'
                },);
            }
        }


        let _nav = menus.map((item : any)=> {
            return {
                _tag: item.only_root ? 'CSidebarNavItem': 'CSidebarNavDropdown',
                name: item.description,
                icon: item.icon,
                to: item.to,
                _children: !item.only_root && item.children.filter((child : any)=>{
                    return child.menu === true;
                }).map((child : any)=>{
                    return {
                        _tag: 'CSidebarNavItem',
                        name: child.description,
                        to: child.path,
                    }
                })
            }
        });
        resolve(_nav);
    });

});
export default processDataAsycn;
